import * as React from "react";
import { parseJsonToHtml } from "@ocrtoy/visualizer";
import {
    FileExt,
    IdentifyType,
    URL_PARAM_NAME_IDENTIFY_INDEX,
    URL_PARAM_NAME_IDENTIFY_TYPE,
    URL_PARAM_NAME_OCR_RESULT_URL,
} from "../DataContract";
import { NotFound } from "./NotFound";
import { vdiRequest } from "../Utils/fetch";

interface IProps {}
interface IState {
    illegalParams: boolean;
    winHeight: number;
    frameUrl?: string;
    frameDoc?: string;
}

const NON_IMAGE_EXT = ["docx", "pptx", "xlsx", "html", "pdf"];

export class OcrToy extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = { illegalParams: false, winHeight: 0 };
    }

    public render() {
        const { frameDoc, frameUrl, illegalParams, winHeight } = this.state;

        return (
            <>
                {illegalParams && <NotFound />}
                {!illegalParams && (
                    <iframe
                        title={"dynamicFrame"}
                        style={{
                            width: "100%",
                            border: "none",
                            margin: 0,
                            padding: 0,
                        }}
                        height={winHeight}
                        srcDoc={frameDoc}
                        src={frameUrl}
                    ></iframe>
                )}
            </>
        );
    }

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        const ocrResultEncodedUrl = urlParams.get(
            URL_PARAM_NAME_OCR_RESULT_URL
        );
        if (ocrResultEncodedUrl) {
            const identifyTypeStr = urlParams.get(URL_PARAM_NAME_IDENTIFY_TYPE);
            const identifyIndexStr = urlParams.get(
                URL_PARAM_NAME_IDENTIFY_INDEX
            );
            if (identifyTypeStr !== null && identifyIndexStr !== null) {
                const identifyType = identifyTypeStr as IdentifyType;
                const urlStr = sessionStorage.getItem(identifyType);
                if (urlStr) {
                    const urlArr = JSON.parse(urlStr) as string[];
                    const identifyIndex = parseInt(identifyIndexStr);

                    sessionStorage.setItem(
                        "_nextUrl_",
                        identifyIndex + 1 < urlArr.length
                            ? urlArr[identifyIndex + 1]
                            : ""
                    );

                    sessionStorage.setItem(
                        "_prevUrl_",
                        0 <= identifyIndex - 1 ? urlArr[identifyIndex - 1] : ""
                    );
                }
            }

            const urlStrArr = ocrResultEncodedUrl.split(".");
            if (urlStrArr && urlStrArr.length > 0) {
                const ext = urlStrArr.pop()!;
                if (ext.toLowerCase() === FileExt.Json) {
                    const ocrResultUrl =
                        decodeURIComponent(ocrResultEncodedUrl);

                    const paramArr = ocrResultEncodedUrl.split("/");
                    if (paramArr.length > 0) {
                        const fileJsonName = paramArr.pop()!;
                        const fileName = fileJsonName.substring(
                            0,
                            fileJsonName.length - 1 - FileExt.Json.length
                        );

                        const strArr = fileName.split(".");
                        const isImage =
                            strArr.length > 0 &&
                            NON_IMAGE_EXT.includes(strArr.pop()!)
                                ? false
                                : true;

                        vdiRequest(ocrResultUrl, { method: "get" })
                            .then(async (reponse) => {
                                if (reponse.ok) {
                                    const resultJosn = await reponse.json();
                                    if (isImage) {
                                        parseJsonToHtml(
                                            `/api/eval/blobs/releasetest_data/CoreEngine/image/${fileName}`,
                                            resultJosn
                                        ).then((html) => {
                                            this.setState({
                                                winHeight: window.innerHeight,
                                                frameDoc: html,
                                            });
                                        });
                                    } else {
                                        fetch(
                                            "https://data-format-srv.azurewebsites.net/api/parseOcrJson",
                                            {
                                                method: "POST",
                                                headers: {
                                                    "Content-Type":
                                                        "application/json",
                                                },
                                                body: JSON.stringify(
                                                    resultJosn
                                                ),
                                            }
                                        ).then(async (resp) => {
                                            const html = await resp.text();
                                            this.setState({
                                                winHeight: window.innerHeight,
                                                frameDoc: html,
                                            });
                                        });
                                    }
                                }
                            })
                            .catch((reason) => {
                                console.error(JSON.parse(reason));
                                this.setState({ illegalParams: true });
                            });
                    }
                } else {
                    this.setState({
                        winHeight: window.innerHeight,
                        frameUrl: ocrResultEncodedUrl,
                    });
                }

                window.addEventListener("resize", (e) =>
                    this.setState({
                        winHeight: window.innerHeight,
                    })
                );

                return;
            }
        }

        this.setState({ illegalParams: true });
    }

    public componentWillUnmount() {
        window.removeEventListener("resize", (e) =>
            this.setState({
                winHeight: window.innerHeight,
            })
        );

        this.setState = () => false;
    }
}
