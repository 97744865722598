import React from "react";
import "../Common/MetricStyle.scss";
import {
    Stack,
    Label,
    FontIcon,
    mergeStyles,
    DetailsList,
    ConstrainMode,
    DetailsListLayoutMode,
    SelectionMode,
    IDetailsRowProps,
    DetailsRow,
    IDetailsRowStyles,
    IColumn,
    getTheme,
    DocumentCardDetails,
    Dropdown,
    DocumentCardTitle,
    DocumentCard,
    DocumentCardType,
    IDropdownOption,
    IDropdownStyles,
} from "@fluentui/react";

import { RecordDetail, Typename, Workspaces } from "../../DataContract";
import {
    CommonView,
    ICommonProps,
    ICommonState,
} from "../Common/CommonMetrics";

import {
    calculateGap,
    addSessionStorage,
    formatNumberDisplay,
    getColorByIndex,
} from "../../Utils";

import { TableColumn, ColumnValueType, VerticalBarChart } from "../../Controls";
import { Highlight, HighlightKey } from "./Highlight";
import { OcrRecord } from "../../DataContract/OcrRecord";
import { exportHighlightMetricsData } from "../../Utils/ExportFile";
import _ from "lodash";
import { store } from "../../../store";
import { updateStateAction } from "../../../store/reducers/setting";
import { FullScreen } from "../Common/FullScreen";
import { NoDataTip } from "../../Controls/NoDataTip";
import {
    checkHighlightLoadingError,
    hideLoading,
    hidePrepareRenderDataLoading,
    resetHighlightLoadingError,
    showPrepareRenderDataLoading,
} from "../../Utils/LoadingUtil";
import {
    updateDisableExportingExcelAction,
    updateDisableSettingAction,
} from "../../../store/reducers";

export interface OcrHighlightResult {
    print: number;
    Highlight?: OcrHighlightResult[];
    Handwritten: number;
    script: string;
    childrenStat?: string;
    id?: string;
    count: string;
    EER?: number;
    IgnoreSpaceEER?: number;
}

interface IDataItem {
    recordIndex: number;
    metrics: any;
}

interface IState extends ICommonState<OcrHighlightResult> {
    dataItems: IDataItem[];
    childExpandStat: Map<string, boolean>;
    linkData?: any;
    selectPrinted: string;
    selectedHandwritten: string;
    selectedVertical: string;
    selectedGeneral: string;
    selectedLatencyCategory: string;
    selectedLatency: string;
    isLoadSuccessful: boolean;
    diffLangs?: string[];
    renderChartData?: any;
    renderTableData?: any;
}

export interface IWerUnion {
    name: string;
    highlight: string[];
}

// prettier-ignore
const LANGUAGE_COLUMNS: TableColumn[] = [
   { key: "script",          name: "Script",           fieldName: "script",     valueType: ColumnValueType.String,  minWidth: 100,   maxWidth: 400, isResizable: true,  distinctStr: true },
   { key: "Coverage",        name: "Coverage",         fieldName: "Coverage",   valueType: ColumnValueType.Number,  minWidth: 100,   maxWidth: 400, isResizable: true, },
   { key: "Unofficial",      name: "Unofficial",       fieldName: "Unofficial", valueType: ColumnValueType.Number,  minWidth: 100,   maxWidth: 400, isResizable: true, },
];

// prettier-ignore
const WER_COLUMNS: IColumn[] = [
    { key: "childrenStat",              name: "",                        fieldName: "childrenStat",           minWidth: 1,    maxWidth: 1,   isResizable: true,  isIconOnly: true },
    { key: "script",                    name: "Script",                  fieldName: "script",                 minWidth: 50,   maxWidth: 250,  isResizable: true, },
    { key: "PrintedDocument",           name: "PrintedDocument",         fieldName: "PrintedDocument",        minWidth: 80,   maxWidth: 250, isResizable: true, },
    { key: "PrintedNon-Document",       name: "PrintedNon-Document",     fieldName: "PrintedNon-Document",    minWidth: 100,  maxWidth: 250, isResizable: true, },
    { key: "PrintedOverall",            name: "PrintedOverall",          fieldName: "PrintedOverall",         minWidth: 80,   maxWidth: 250, isResizable: true, },
    { key: "HandwrittenForm",           name: "HandwrittenForm",         fieldName: "HandwrittenForm",        minWidth: 80,   maxWidth: 250, isResizable: true, },
    { key: "HandwrittenNote",           name: "HandwrittenNote",         fieldName: "HandwrittenNote",        minWidth: 80,   maxWidth: 250, isResizable: true, },
    { key: "HandwrittenOverall",        name: "HandwrittenOverall",      fieldName: "HandwrittenOverall",     minWidth: 80,   maxWidth: 250, isResizable: true, },
    
];
// prettier-ignore
const EER_COMMON_COLUMNS: IColumn[] = [
    { key: "EER",               name: "EER",                fieldName: "EER",                 minWidth: 80,   maxWidth: 200, isResizable: true, },
    { key: "EEC",               name: "EEC",                fieldName: "EEC",                 minWidth: 80,   maxWidth: 200, isResizable: true, },
    { key: "IgnoreSpaceEER",    name: "IgnoreSpaceEER",     fieldName: "IgnoreSpaceEER",      minWidth: 80,   maxWidth: 200, isResizable: true, },
    { key: "IgnoreSpaceEEC",    name: "IgnoreSpaceEEC",     fieldName: "IgnoreSpaceEEC",      minWidth: 80,   maxWidth: 200, isResizable: true, },
];

// prettier-ignore
const EER_COLUMNS: IColumn[] = [
    { key: "script",         name: "VerticalEntity",   fieldName: "script",    minWidth: 80,    maxWidth: 200,    isResizable: true, },
    ...EER_COMMON_COLUMNS
];

// prettier-ignore
const EER2_COLUMNS: IColumn[] = [
    { key: "script",         name: "GeneralEntity",    fieldName: "script",    minWidth: 80,    maxWidth: 200,    isResizable: true, },
    ...EER_COMMON_COLUMNS
];

// prettier-ignore
const LATENCY_COLUMNS: TableColumn[] = [
    { key: "script",             name: "Script",              fieldName: "script",               valueType: ColumnValueType.Number,  minWidth: 50,  maxWidth: 80,   isResizable: true, distinctStr: true },
    { key: "P50OcrMS",           name: "P50OCRMS",            fieldName: "P50OcrMS",             valueType: ColumnValueType.Number,  minWidth: 100, maxWidth: 180,  isResizable: true, },
    { key: "P50DetectorMS",      name: "P50DetectorMS",       fieldName: "P50DetectorMS",        valueType: ColumnValueType.Number,  minWidth: 100, maxWidth: 180,  isResizable: true, },
    { key: "P50RecognizerMS",    name: "P50RecognizerMS",     fieldName: "P50RecognizerMS",      valueType: ColumnValueType.Number,  minWidth: 100, maxWidth: 180,  isResizable: true, },
    { key: "P90OcrMS",           name: "P90OCRMS",            fieldName: "P90OcrMS",             valueType: ColumnValueType.Number,  minWidth: 100, maxWidth: 180,  isResizable: true, },
    { key: "P90DetectorMS",      name: "P90DetectorMS",       fieldName: "P90DetectorMS",        valueType: ColumnValueType.Number,  minWidth: 100, maxWidth: 180,  isResizable: true, },
    { key: "P90RecognizerMS",    name: "P90RecognizerMS",     fieldName: "P90RecognizerMS",      valueType: ColumnValueType.Number,  minWidth: 100, maxWidth: 180,  isResizable: true, },
    { key: "P99OcrMS",           name: "P99OCRMS",            fieldName: "P99OcrMS",             valueType: ColumnValueType.Number,  minWidth: 100, maxWidth: 180,  isResizable: true, },
    { key: "P99DetectorMS",      name: "P99DetectorMS",       fieldName: "P99DetectorMS",        valueType: ColumnValueType.Number,  minWidth: 100, maxWidth: 180,  isResizable: true, },
    { key: "P99RecognizerMS",    name: "P99RecognizerMS",     fieldName: "P99RecognizerMS",      valueType: ColumnValueType.Number,  minWidth: 100, maxWidth: 180,  isResizable: true, },
    { key: "P100OcrMS",          name: "P100OCRMS",           fieldName: "P100OcrMS",            valueType: ColumnValueType.Number,  minWidth: 100, maxWidth: 180,  isResizable: true, },
    { key: "P100DetectorMS",     name: "P100DetectorMS",      fieldName: "P100DetectorMS",       valueType: ColumnValueType.Number,  minWidth: 100, maxWidth: 180,  isResizable: true, },
    { key: "P100RecognizerMS",   name: "P100RecognizerMS",    fieldName: "P100RecognizerMS",     valueType: ColumnValueType.Number,  minWidth: 100, maxWidth: 180,  isResizable: true, },
]

const DROPDOWNSTYLES: Partial<IDropdownStyles> = {
    dropdown: { width: 145 },
    root: { width: 145 },
    title: { border: "none" },
};

const iconClass = mergeStyles({
    fontSize: 20,
    margin: "5px",
});

const theme = getTheme();
export class HighlightMetrics extends CommonView<
    ICommonProps,
    IState,
    OcrHighlightResult
> {
    private exportWerData: any;
    private loadCount: number;
    constructor(props: ICommonProps) {
        super(props);
        this._onRenderRow = this._onRenderRow.bind(this);
        this._renderItemColumn = this._renderItemColumn.bind(this);
        this._constructChildrenListModel =
            this._constructChildrenListModel.bind(this);
        this._renderRowByHighlight = this._renderRowByHighlight.bind(this);
        this._onRenderRowForHLChild = this._onRenderRowForHLChild.bind(this);
        this._onRowItemInvoked = this._onRowItemInvoked.bind(this);

        this.loadCount = 0;

        this.state = {
            dataItems: [],
            matchDatasetVersion: true,
            childExpandStat: new Map<string, boolean>(),
            selectPrinted: "Document",
            selectedHandwritten: "Form",
            selectedVertical: "EER",
            selectedGeneral: "EER",
            selectedLatency: "OcrMS",
            selectedLatencyCategory: "Vertical",
            isLoadSuccessful: true,
            viewType: store.getState().globalReducer.viewType,
        };
    }

    public render() {
        const { diffLangs, isLoadSuccessful, viewType } = this.state;
        return (
            <FullScreen>
                {isLoadSuccessful === false && (
                    <h3>
                        Much data download incompletely, unable to generate
                        comparison information.
                    </h3>
                )}

                {isLoadSuccessful === true &&
                    diffLangs &&
                    diffLangs.length > 0 && (
                        <div
                            style={{
                                width: "100%",
                                height: "100%",
                                overflow: "auto",
                            }}
                        >
                            <h3>There's difference in below datasets:</h3>
                            {diffLangs.map((lang) => (
                                <p style={{ marginLeft: "20px" }}>{lang}</p>
                            ))}
                        </div>
                    )}

                {isLoadSuccessful === true &&
                    (diffLangs === undefined || diffLangs.length === 0) && (
                        <>
                            {viewType === "Table" && (
                                <div
                                    style={{
                                        overflow: "hidden auto",
                                        height: "100%",
                                    }}
                                >
                                    {this._renderAsTable()}
                                </div>
                            )}

                            {viewType === "Chart" && (
                                <div
                                    style={{
                                        overflow: "hidden auto",
                                        height: "100%",
                                    }}
                                >
                                    {this._renderAsChart()}
                                </div>
                            )}
                        </>
                    )}
            </FullScreen>
        );
    }

    componentDidMount() {
        super.componentDidMount();
        store.dispatch(
            updateStateAction({
                columns: LATENCY_COLUMNS,
                saveKey: `${Workspaces.Ocr}_${Typename.HighlightMetrics}`,
            })
        );
    }

    componentWillUnmount() {
        store.dispatch(updateDisableExportingExcelAction(false));
        store.dispatch(updateDisableSettingAction(false));
    }

    public componentDidUpdate(
        prevProps: ICommonProps,
        prevState: ICommonState<OcrHighlightResult>
    ): void {
        super.componentDidUpdate(prevProps, prevState);
        if (
            !_.isEqual(prevState.dataItems, this.state.dataItems) ||
            !_.isEqual(prevState.viewType, this.state.viewType)
        ) {
            if (this.state.viewType === "Chart") {
                this.getChartData();
            }
            if (this.state.viewType === "Table") {
                this.getTableData();
            }
        }
    }

    getChartData = () => {
        const data = this._prepareRenderChartData(); //[string, [string, [string, any[]][]][]][]
        const isAvailable = this._verifyAvailableData(data);
        this.setState(
            { renderChartData: isAvailable ? data : undefined },
            () => {
                hidePrepareRenderDataLoading();
            }
        );
    };
    getTableData = () => {
        const data = this._prepareRenderData();
        this.setState({ renderTableData: data }, () => {
            hidePrepareRenderDataLoading();
        });
    };

    exportAction = () => {
        const werData = this._preparWerData(this.exportWerData);
        this.exportData.splice(1, 1, ["WER", werData]);
        exportHighlightMetricsData(
            this.exportData,
            [
                LANGUAGE_COLUMNS,
                WER_COLUMNS.filter((col) => col.key !== "childrenStat"),
                EER_COLUMNS,
                LATENCY_COLUMNS,
            ],
            `HighlightMetrics`
        );
    };

    queryMetricsResult() {
        const COMPARISON_NUMBER = 4;
        if (this.props.records.length <= COMPARISON_NUMBER) {
            this._queryMetricsResult();
        } else if (
            window.confirm(
                `The records count involved in the comparison is greater than ${COMPARISON_NUMBER}. A lot of data to be loaded and possible to fail. It's recommended to reduce the number of record comparisons.\r\r    Click the "OK" to stop the comparison.\r    Click the "Cancel" to ignore recommendation and continue the comparison. `
            ) === false
        ) {
            this._queryMetricsResult();
        }
    }

    public async _queryMetricsResult() {
        const { matchDatasetVersion } = this.state;

        const [intersectLangs, diffLangs] = this._getIntersectAndDiffLangList(
            matchDatasetVersion!
        );

        if (diffLangs && diffLangs.length > 0) {
            store.dispatch(updateDisableExportingExcelAction(true));
            store.dispatch(updateDisableSettingAction(true));
            this.setState({ diffLangs: diffLangs });
        } else if (intersectLangs.length > 0) {
            showPrepareRenderDataLoading();
            resetHighlightLoadingError();
            const detailsList = this.props.records.map((r) => {
                return r.getDetails().filter((detail) => {
                    if (detail === undefined) {
                        return false;
                    } else {
                        return matchDatasetVersion
                            ? intersectLangs.includes(
                                  detail.dataset.displayFullName
                              )
                            : intersectLangs.includes(
                                  detail.dataset.displayName
                              );
                    }
                }) as RecordDetail[];
            });

            let isLoadFailed = false;

            const RETRY_TIMES = 3;
            const dataItems: {
                recordIndex: number;
                metrics: OcrHighlightResult;
            }[] = [];

            for (let index = 0; index < detailsList.length; index++) {
                try {
                    const metrics = await this._fetchHighlight(index);
                    if (metrics) {
                        dataItems.push({
                            recordIndex: index,
                            metrics: metrics,
                        });
                    } else {
                        console.log("Empty result");
                        isLoadFailed = true;
                    }
                } catch (_error) {
                    console.log(_error);
                    isLoadFailed = true;
                }
            }

            const isLoadingError = checkHighlightLoadingError();
            if (isLoadFailed || isLoadingError) {
                if (this.loadCount < RETRY_TIMES) {
                    this.loadCount += 1;
                    console.log(
                        `Found download errors, and start retrying ${this.loadCount} times.`
                    );
                    setTimeout(() => {
                        this._queryMetricsResult();
                    }, 2000);
                } else {
                    console.log(
                        `File download failed, tried ${this.loadCount} times`
                    );
                    this.setState({ isLoadSuccessful: false }, () => {
                        store.dispatch(updateDisableExportingExcelAction(true));
                        store.dispatch(updateDisableSettingAction(true));
                        hideLoading();
                        hidePrepareRenderDataLoading();
                        resetHighlightLoadingError();
                    });
                }
            } else {
                console.log("All files downloaded successfully.");
                this.setState({ dataItems: dataItems });
            }
        }
    }

    // private async _fetchHighlight(index: number) {
    //     let ocrRecord = this.props.records[index] as OcrRecord;
    //     const highlightStorageKey = `${ocrRecord.id}_highlight`;
    //     // We will retrieve highlight info only if it's not in sessionstorage or expired.
    //     const highlightItem = sessionStorage.getItem(highlightStorageKey);
    //     let hd = highlightItem ? JSON.parse(highlightItem).timestamp : null;
    //     let ld = Date.parse(ocrRecord.dateTime);

    //     if (!hd || hd < ld - 600 * 1000) {
    //         let storageMap = new Map<string, string>();
    //         const details = ocrRecord.getDetails();
    //         for (let detail of details) {
    //             let language = detail.getRawProp<string>("language");
    //             let storageRoot = detail.getRawProp<string>("storageRoot");
    //             storageMap.set(language, storageRoot);
    //         }

    //         let highlight = new Highlight(storageMap);
    //         let highlightMetric = await highlight.getHighligtInfo();

    //         addSessionStorage(
    //             highlightStorageKey,
    //             JSON.stringify(highlightMetric)
    //         );
    //     }
    //     return JSON.parse(
    //         JSON.parse(sessionStorage.getItem(highlightStorageKey)!).result
    //     ) as OcrHighlightResult;
    // }

    private _getIntersectAndDiffLangList(
        matchDatasetVersion: boolean
    ): string[][] {
        const { records } = this.props;
        if (records.length === 0) {
            return [];
        }

        let allLanguageList: Array<string> = [];
        let intersectedLangList = records[0]
            .getDetails()
            .map((detail) =>
                matchDatasetVersion
                    ? detail.dataset.displayFullName
                    : detail.dataset.displayName
            );
        records.forEach((recordItem) => {
            const compareLangList = recordItem
                .getDetails()
                .map((detail) =>
                    matchDatasetVersion
                        ? detail.dataset.displayFullName
                        : detail.dataset.displayName
                );

            intersectedLangList = intersectedLangList.filter(
                (v) => compareLangList.indexOf(v) > -1
            );
            allLanguageList.push(...compareLangList);
        });

        allLanguageList = Array.from(new Set(allLanguageList)).sort();
        intersectedLangList = Array.from(new Set(intersectedLangList)).sort();

        const isElementMatched = [...allLanguageList].every((lang) =>
            intersectedLangList.includes(lang)
        );

        if (isElementMatched) {
            return [intersectedLangList, []];
        } else {
            const diffLangList = allLanguageList.filter(
                (lang) => !intersectedLangList.includes(lang)
            );
            return [[], diffLangList];
        }
    }

    private async _fetchLanguage(highlight: Highlight) {
        return highlight.getHighligtInfo(HighlightKey.Language);
    }
    private async _fetchWer(highlight: Highlight) {
        return highlight.getHighligtInfo(HighlightKey.WER);
    }
    private async _fetchEer(highlight: Highlight) {
        return highlight.getHighligtInfo(HighlightKey.EER);
    }
    private async _fetchLatency(highlight: Highlight) {
        return highlight.getHighligtInfo(HighlightKey.Latency);
    }

    private async _fetchHighlight(index: number) {
        let ocrRecord = this.props.records[index] as OcrRecord;
        const highlightStorageKey = `${ocrRecord.id}_highlight`;
        // We will retrieve highlight info only if it's not in sessionstorage or expired.
        const highlightItem = sessionStorage.getItem(highlightStorageKey);
        let hd = highlightItem ? JSON.parse(highlightItem).timestamp : null;
        let ld = Date.parse(ocrRecord.dateTime);
        if (!hd || hd < ld - 600 * 1000) {
            let storageMap = new Map<string, string>();
            const details = ocrRecord.getDetails();
            for (let detail of details) {
                let language = detail.getRawProp<string>("language");
                let storageRoot = detail.getRawProp<string>("storageRoot");
                storageMap.set(language, storageRoot);
            }

            let highlight = new Highlight(storageMap);
            let LanguageMetric = await this._fetchLanguage(highlight);
            let WerMetric = await this._fetchWer(highlight);
            let EerMetric = await this._fetchEer(highlight);
            let LatencyMetric = await this._fetchLatency(highlight);
            let highlightMetric = {
                ...LanguageMetric,
                ...WerMetric,
                ...EerMetric,
                ...LatencyMetric,
            };

            const isLoadingError = checkHighlightLoadingError();
            if (!!!isLoadingError) {
                addSessionStorage(
                    highlightStorageKey,
                    JSON.stringify(highlightMetric)
                );
            }
        }

        const highlightMetricStr = sessionStorage.getItem(highlightStorageKey);
        if (highlightMetricStr) {
            return JSON.parse(
                JSON.parse(highlightMetricStr).result
            ) as OcrHighlightResult;
        } else {
            return null;
        }
    }

    private _prepareRenderData() {
        let objArray: any[] = [];
        let indexes = new Set<number>();
        const items: (IDataItem | undefined)[] = this.props.records.map(
            (_, recordIndex) => {
                if (this.state.dataItems.length > 0) {
                    indexes.add(recordIndex);
                }
                const item: IDataItem | undefined = this.state.dataItems.find(
                    (item) => item.recordIndex === recordIndex
                );
                return item;
            }
        );

        const sampleItem = items.find((i) => i?.metrics);
        if (sampleItem) {
            const titles = Object.keys(sampleItem.metrics).filter(
                (k) => k !== "property"
            );

            objArray = titles.map((title) => {
                let scriptItemsArray: any[][] = [],
                    tableContent: any[][] = [];

                items.forEach((item) => {
                    let scriptItems: any[] = [];
                    if (item !== undefined) {
                        if (title === "Language") {
                            scriptItems = Object.entries(
                                item.metrics[title]
                            ).map(([key, val]: any) => {
                                let obj: any = {};
                                val &&
                                    Object.entries(val).forEach(
                                        ([k, v]: any) => {
                                            obj[k] = v;
                                        }
                                    );
                                return Object.assign({ script: key }, obj);
                            });
                        } else if (title !== "EER") {
                            scriptItems = Object.entries(
                                item.metrics[title]
                            ).map(([key, val]: any) => {
                                let obj: any = {};
                                Object.entries(val).forEach(
                                    ([werType, item]: any) => {
                                        if (werType !== "Highlight" && item) {
                                            Object.entries(item).forEach(
                                                ([k, v]: any) => {
                                                    const fieldName: string =
                                                        werType + k.toString();
                                                    obj[fieldName] = v ?? "";
                                                }
                                            );
                                        } else {
                                            obj[werType] = item;
                                        }
                                    }
                                );

                                return Object.assign({ script: key }, obj);
                            });
                        } else {
                            scriptItems = Object.entries(
                                item.metrics[title]
                            ).flatMap(([key, val]: any) => {
                                return Object.entries(val).map(
                                    ([eerType, item]: any) => {
                                        return Object.assign(
                                            {
                                                script: eerType,
                                                type: key,
                                            },
                                            item
                                        );
                                    }
                                );
                            });
                        }
                    }

                    scriptItemsArray.push(scriptItems);
                });

                const sampleScriptItems = scriptItemsArray.find(
                    (arr) => arr && arr.length > 0
                );
                if (sampleScriptItems && sampleScriptItems.length > 0) {
                    const scripts = sampleScriptItems.map(
                        (scriptItem) => scriptItem.script
                    );

                    tableContent = scripts.map((script) => {
                        return scriptItemsArray.map((scriptItems, index) => {
                            let scriptItem = scriptItems.find(
                                (res) => res.script === script
                            );
                            if (scriptItem === undefined) {
                                scriptItem = {};
                            }
                            if (!scriptItem.id) {
                                scriptItem.id = index + script;
                            }

                            return scriptItem;
                        });
                    });
                }

                return {
                    title: title,
                    scriptItems: tableContent,
                };
            });
        }
        return objArray.length > 0 ? objArray : null;
    }

    private _onRenderRow(rowProps: IDetailsRowProps | undefined): JSX.Element {
        if (rowProps !== undefined) {
            const [customStyles, isExpanded, hasChildren, highlight2dArr] =
                this._renderRowByHighlight(rowProps);

            return (
                <>
                    <DetailsRow {...rowProps} styles={customStyles} />

                    {isExpanded &&
                        hasChildren &&
                        highlight2dArr.length > 0 &&
                        highlight2dArr[0].map((arr, index) => {
                            const customStyles: Partial<IDetailsRowStyles> = {};
                            if ((rowProps.itemIndex + index) % 2 !== 0) {
                                // Every other row renders with a different background color
                                customStyles.root = {
                                    backgroundColor: this.props.isDarkTheme
                                        ? theme.palette.neutralDark
                                        : theme.palette.neutralLighterAlt,
                                    width: "100%",
                                };
                            } else {
                                customStyles.root = {
                                    backgroundColor: this.props.isDarkTheme
                                        ? theme.palette.neutralPrimary
                                        : theme.palette.white,
                                    width: "100%",
                                };
                            }
                            rowProps.item = arr;
                            return (
                                <DetailsRow
                                    {...rowProps}
                                    styles={customStyles}
                                />
                            );
                        })}
                </>
            );
        } else {
            return <></>;
        }
    }
    private _renderRowByHighlight(
        rowProps: IDetailsRowProps
    ): [Partial<IDetailsRowStyles>, boolean, boolean, OcrHighlightResult[][]] {
        const rowItems = rowProps?.item as Array<OcrHighlightResult>;

        const highlight2dArr = this._constructChildrenListModel(rowItems);

        const [rowId] = this._getRowItemsMetaInfo(rowItems);
        const isExpanded = this.state.childExpandStat.get(rowId) === true;

        const hasChildren = highlight2dArr?.length > 0;

        if (hasChildren) {
            rowItems[0].childrenStat = isExpanded ? "▼" : "▶";
        }
        const customStyles: Partial<IDetailsRowStyles> = {};
        if (rowProps.itemIndex % 2 === 0) {
            // Every other row renders with a different background color
            customStyles.root = {
                backgroundColor: this.props.isDarkTheme
                    ? theme.palette.neutralDark
                    : theme.palette.neutralLighterAlt,
                width: "100%",
            };
        } else {
            customStyles.root = {
                backgroundColor: this.props.isDarkTheme
                    ? theme.palette.neutralPrimary
                    : theme.palette.white,
                width: "100%",
            };
        }

        return [customStyles, isExpanded, hasChildren, highlight2dArr];
    }

    private _onRenderRowForHLChild(
        rowProps: IDetailsRowProps | undefined
    ): JSX.Element {
        if (rowProps !== undefined) {
            const [customStyles, isExpanded, hasChildren, highlight2dArr] =
                this._renderRowByHighlight(rowProps);

            return (
                <>
                    <DetailsRow {...rowProps} styles={customStyles} />
                    {isExpanded && hasChildren && highlight2dArr.length > 0 && (
                        <DetailsList
                            items={highlight2dArr[0]}
                            columns={WER_COLUMNS}
                            constrainMode={ConstrainMode.unconstrained}
                            selectionMode={SelectionMode.none}
                            layoutMode={DetailsListLayoutMode.justified}
                            onRenderItemColumn={(
                                item: OcrHighlightResult[],
                                index?: number,
                                column?: IColumn
                            ) => this._renderItemColumn(item, column)}
                        />
                    )}
                </>
            );
        } else {
            return <></>;
        }
    }

    private _renderItemColumn(
        item: OcrHighlightResult[],
        column?: IColumn,
        flag = true
    ) {
        const key: any = column!.fieldName;
        if (key === "script") {
            if (item && item.length > 0) {
                const avlOcrEntity = item.find((result) => {
                    if (result) {
                        const scriptVal =
                            result[key as keyof OcrHighlightResult];
                        return scriptVal && scriptVal.toString() !== "NaN";
                    }

                    return false;
                });

                if (avlOcrEntity) {
                    return (
                        <span className="table__item">
                            {avlOcrEntity[key as keyof OcrHighlightResult]}
                        </span>
                    );
                }
            }
            return <></>;
        } else if (key === "childrenStat") {
            const childrenStat = item[0]?.childrenStat;
            if (childrenStat && childrenStat !== "") {
                return (
                    <span
                        className="table__item"
                        key={`${key}_stat`}
                        style={{ cursor: "pointer" }}
                        onClick={(
                            event: React.MouseEvent<
                                Element,
                                globalThis.MouseEvent
                            >
                        ) => {
                            this._onRowItemInvoked(item);
                        }}
                    >
                        {childrenStat}
                    </span>
                );
            } else {
                return <></>;
            }
        } else {
            const values = item.map((v) => {
                return v[key as keyof OcrHighlightResult] as number;
            });
            const base = values[values.length - 1];
            const classN = this._cloumnClassName(flag);
            const displayText = values.map((val, idx) => {
                if (!val || isNaN(val)) {
                    return (
                        <div className={classN} key={`${key}_${idx}`}>
                            NaN
                        </div>
                    );
                }

                if (!isNaN(val) && !isNaN(base) && val !== base) {
                    return (
                        <div className={classN} key={`${key}_${idx}`}>
                            {val}
                            <span
                                style={{
                                    color: val > base ? "red" : "green",
                                    fontSize: "10px",
                                }}
                            >
                                <b>&nbsp;({calculateGap(val, base, 2)})</b>
                            </span>
                        </div>
                    );
                }

                return (
                    <span className="table__item" key={`${key}_${idx}`}>
                        {formatNumberDisplay(val)}
                    </span>
                );
            });
            return (
                <div
                    style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "row",
                    }}
                >
                    {displayText}
                </div>
            );
        }
    }

    private _cloumnClassName(flag: boolean) {
        return flag ? "table__item" : "table__item__flex";
    }

    private _constructChildrenListModel(
        rowItems: Array<OcrHighlightResult>
    ): Array<OcrHighlightResult[]> {
        let highlight2dArr: Array<OcrHighlightResult[]> = [];
        let scriptFa = "";
        if (rowItems?.length > 0) {
            const dictMap = new Map<string, OcrHighlightResult[]>();
            let ocrWerArrs: Array<Array<OcrHighlightResult> | undefined> = [];
            rowItems.forEach((rowItem) => {
                const childrenItems = rowItem.Highlight;

                scriptFa = rowItem.script;
                let ocrWerArr: Array<OcrHighlightResult> | undefined = [];
                if (childrenItems) {
                    ocrWerArr = Object.entries(childrenItems).map(
                        ([script, items]) => {
                            let obj: any = {};
                            Object.entries(items).forEach(
                                ([werType, item]: any) => {
                                    if (
                                        werType === "EER" ||
                                        werType === "IgnoreSpaceEER"
                                    ) {
                                        obj[werType] = item;
                                    } else {
                                        return Object.entries(item).map(
                                            ([k, v]: any) => {
                                                const abc: string =
                                                    werType + k.toString();
                                                obj[abc] = v;

                                                return obj;
                                            }
                                        );
                                    }
                                }
                            );
                            return Object.assign({ script: script }, obj);
                        }
                    );
                    let scripts = ocrWerArr.map((werScript) => {
                        return werScript.script;
                    });
                    ocrWerArrs.push(ocrWerArr);
                    let wers: any = scripts.map((script, index) => {
                        const werItems = rowItems.map((rowItem, index) => {
                            let wer: OcrHighlightResult | undefined;
                            if (rowItem !== undefined) {
                                wer = ocrWerArrs[index]?.find(
                                    (wer: any) => wer.script === script
                                );
                            }
                            if (wer === undefined) {
                                wer = {
                                    Highlight: [],
                                    print: NaN,
                                    Handwritten: NaN,
                                    script: script,
                                    EER: NaN,
                                    IgnoreSpaceEER: NaN,
                                    count: "",
                                };
                            } else {
                                if (!wer.id) {
                                    wer.id = script + index;
                                }
                            }
                            return wer;
                        });

                        return werItems;
                    });
                    dictMap.set(scriptFa, wers);
                }
            });

            highlight2dArr = Array.from(dictMap, ([key, ocrWerResultArr]) => {
                return ocrWerResultArr;
            });
        }

        return highlight2dArr.filter((group) => group.length !== 0);
    }
    private _onRowItemInvoked(item?: any) {
        const items = item as Array<OcrHighlightResult>;

        if (items?.length > 0) {
            const [rowId] = this._getRowItemsMetaInfo(items);

            let expandStat = this.state.childExpandStat;
            if (expandStat.has(rowId)) {
                const stat = expandStat.get(rowId);
                expandStat.set(rowId, !stat);
            } else {
                expandStat.set(rowId, true);
            }

            this.setState({
                childExpandStat: expandStat,
            });
        }
    }
    private _getRowItemsMetaInfo(items: Array<OcrHighlightResult>): [string] {
        let rowId: string = "";

        if (items?.length > 0) {
            items.some((item) => {
                if (item.id) {
                    rowId = item.id;
                }

                return rowId !== null;
            });
        }

        return [rowId];
    }

    private _renderAsTable() {
        const { selectedColumns, renderTableData } = this.state;

        const columns: TableColumn[] = LATENCY_COLUMNS.filter(
            (value) =>
                selectedColumns?.findIndex((col) => col === value.key) !== -1
        );

        let werItem: any[] = [];
        let eerGItem: any[] = [];
        let eerVItem: any[] = [];
        let latencyItem: any[] = [];
        let languageItem: any[] = [];

        this.exportData = [];
        const exportDataMap = new Map<string, any>();

        if (renderTableData) {
            renderTableData.forEach((item: any) => {
                exportDataMap.set(item["title"], item["scriptItems"]);
                for (let v in item) {
                    if (item[v] === "WER") {
                        werItem = item.scriptItems;
                    } else if (item[v] === "Latency") {
                        latencyItem = item.scriptItems;
                    } else if (item[v] === "EER") {
                        eerGItem = item.scriptItems.filter(
                            (val: any) => val[0].type === "General Entity"
                        );
                        eerVItem = item.scriptItems.filter(
                            (val: any) => val[0].type === "Vertical Entity"
                        );
                    } else if (item[v] === "Language") {
                        languageItem = item.scriptItems;
                    }
                }
            });

            this.exportWerData = werItem;

            ["Language", "WER", "EER", "Latency"].forEach((key) =>
                this.exportData.push([key, exportDataMap.get(key)])
            );
        } else {
            return <NoDataTip>No Highlight Metrics Generated</NoDataTip>;
        }

        return (
            <>
                {renderTableData && renderTableData !== undefined && (
                    <>
                        <Stack
                            horizontal
                            styles={{ root: { padding: "10px" } }}
                        >
                            <FontIcon
                                aria-label="Compass"
                                iconName="PageList"
                                className={iconClass}
                                style={{
                                    color: this.props.isDarkTheme
                                        ? "white"
                                        : "black",
                                }}
                            />
                            <Label>Language Coverage</Label>
                        </Stack>
                        <DetailsList
                            styles={{
                                root: { overflow: "auto" },
                            }}
                            columns={LANGUAGE_COLUMNS}
                            constrainMode={ConstrainMode.unconstrained}
                            items={languageItem}
                            layoutMode={DetailsListLayoutMode.fixedColumns}
                            selectionMode={SelectionMode.none}
                            onRenderRow={this._onRenderRow}
                            onRenderItemColumn={(
                                item: OcrHighlightResult[],
                                index?: number,
                                column?: IColumn
                            ) => this._renderItemColumn(item, column)}
                        />
                        <Stack
                            horizontal
                            styles={{ root: { padding: "10px" } }}
                        >
                            <FontIcon
                                aria-label="Compass"
                                iconName="PageList"
                                className={iconClass}
                                style={{
                                    color: this.props.isDarkTheme
                                        ? "white"
                                        : "black",
                                }}
                            />
                            <Label>WER</Label>
                        </Stack>
                        <DetailsList
                            styles={{
                                root: { overflow: "auto" },
                            }}
                            key={JSON.stringify(
                                Object.fromEntries(this.state.childExpandStat)
                            )}
                            columns={WER_COLUMNS}
                            constrainMode={ConstrainMode.unconstrained}
                            items={werItem}
                            layoutMode={DetailsListLayoutMode.fixedColumns}
                            selectionMode={SelectionMode.none}
                            onRenderRow={this._onRenderRow}
                            onRenderItemColumn={(
                                item: OcrHighlightResult[],
                                index?: number,
                                column?: IColumn
                            ) => this._renderItemColumn(item, column)}
                        />
                        <Stack
                            horizontal
                            styles={{ root: { padding: "10px" } }}
                        >
                            <FontIcon
                                aria-label="Compass"
                                iconName="PageList"
                                className={iconClass}
                                style={{
                                    color: this.props.isDarkTheme
                                        ? "white"
                                        : "black",
                                }}
                            />
                            <Label>EER</Label>
                        </Stack>
                        <Stack horizontal>
                            <DetailsList
                                styles={{
                                    root: { overflow: "auto" },
                                }}
                                columns={EER_COLUMNS}
                                constrainMode={ConstrainMode.unconstrained}
                                items={eerVItem}
                                layoutMode={DetailsListLayoutMode.fixedColumns}
                                selectionMode={SelectionMode.none}
                                onRenderRow={this._onRenderRow}
                                onRenderItemColumn={(
                                    item: OcrHighlightResult[],
                                    index?: number,
                                    column?: IColumn
                                ) => this._renderItemColumn(item, column)}
                            />
                            <DetailsList
                                styles={{
                                    root: { overflow: "auto" },
                                }}
                                columns={EER2_COLUMNS}
                                constrainMode={ConstrainMode.unconstrained}
                                items={eerGItem}
                                layoutMode={DetailsListLayoutMode.fixedColumns}
                                selectionMode={SelectionMode.none}
                                onRenderRow={this._onRenderRow}
                                onRenderItemColumn={(
                                    item: OcrHighlightResult[],
                                    index?: number,
                                    column?: IColumn
                                ) => this._renderItemColumn(item, column)}
                            />
                        </Stack>
                        <Stack
                            horizontal
                            styles={{ root: { padding: "10px" } }}
                        >
                            <FontIcon
                                aria-label="Compass"
                                iconName="PageList"
                                className={iconClass}
                                style={{
                                    color: this.props.isDarkTheme
                                        ? "white"
                                        : "black",
                                }}
                            />
                            <Label>Latency</Label>
                        </Stack>

                        <DetailsList
                            styles={{
                                headerWrapper: { whiteSpace: "pre-line" },
                                root: { overflow: "auto" },
                            }}
                            columns={columns}
                            constrainMode={ConstrainMode.unconstrained}
                            items={latencyItem}
                            layoutMode={DetailsListLayoutMode.fixedColumns}
                            selectionMode={SelectionMode.none}
                            onRenderRow={this._onRenderRow}
                            onRenderItemColumn={(
                                item: OcrHighlightResult[],
                                index?: number,
                                column?: IColumn
                            ) => this._renderItemColumn(item, column, false)}
                        />
                    </>
                )}
            </>
        );
    }
    private _prepareRenderChartData() {
        const items: (IDataItem | undefined)[] = this.props.records.map(
            (_, recordIndex) => {
                const item: IDataItem | undefined = this.state.dataItems.find(
                    (item) => item.recordIndex === recordIndex
                );

                return item;
            }
        );

        let languages: string[] = [];
        let werLang: any[] = [];
        let verticalEntity: any[] = [];
        let generalEntity: any[] = [];
        let latencyList: any[] = [];
        let latencyCategory: any[] = [];
        Array.from(
            new Set(
                items.flatMap((item) => {
                    if (item && item.metrics) {
                        Object.entries(item.metrics).forEach(
                            ([title, metricItems]: any) => {
                                if (title === "WER") {
                                    werLang = Object.keys(metricItems);
                                } else if (title === "EER") {
                                    Object.entries(metricItems).forEach(
                                        ([subTitle, items]: any) => {
                                            if (
                                                subTitle === "Vertical Entity"
                                            ) {
                                                verticalEntity =
                                                    Object.keys(items);
                                            } else {
                                                generalEntity =
                                                    Object.keys(items);
                                            }
                                        }
                                    );
                                } else if (title === "Language") {
                                    languages = Object.keys(metricItems);
                                } else if (title === "Latency") {
                                    if (JSON.stringify(metricItems) !== "{}") {
                                        latencyCategory =
                                            Object.keys(metricItems);

                                        if (metricItems[latencyCategory[0]]) {
                                            latencyList = Object.keys(
                                                metricItems[latencyCategory[0]]
                                            );
                                        }
                                    }
                                }
                            }
                        );
                    }
                })
            )
        );

        const data = ["Language", "WER", "EER", "Latency"].map((title) => {
            let werList: any[] = [];
            if (title === "WER") {
                werList = ["Printed", "Handwritten"].map((category) => {
                    const groupData = werLang.map((language) => {
                        const werItems = items.map((item) => {
                            let wer: any;
                            if (item) {
                                const { metrics } = item;

                                wer = Object.entries(metrics.WER).find(
                                    ([lang, con]) => lang === language
                                );

                                if (wer) {
                                    wer = wer[1][category];
                                    wer.language = language;
                                }
                            }
                            if (wer === undefined) {
                                wer = {
                                    language: language,
                                    Document: NaN,
                                    "Non-Document": NaN,
                                    Overall: NaN,
                                    Form: NaN,
                                    Note: NaN,
                                };
                            }

                            return {
                                data: item,
                                wer: wer,
                            } as any;
                        });

                        return [language, werItems] as [string, any[]];
                    });
                    return [category, groupData] as [string, [string, any[]][]];
                });
            } else if (title === "EER") {
                let groupData: any[] = [];
                werList = ["General Entity", "Vertical Entity"].map(
                    (category) => {
                        groupData = (
                            category === "General Entity"
                                ? generalEntity
                                : verticalEntity
                        ).map((language) => {
                            const werItems = items.map((item) => {
                                let wer: any;
                                if (item && item.metrics) {
                                    const { metrics } = item;
                                    wer = Object.entries(
                                        metrics.EER[category]
                                    ).find(([lang, con]) => lang === language);
                                    if (wer) {
                                        wer = wer[1];
                                        wer.language = language;
                                    }
                                }
                                if (wer === undefined) {
                                    wer = {
                                        language: language,
                                        EER: NaN,
                                        IgnoreSpaceEER: NaN,
                                    };
                                }
                                return {
                                    data: item,
                                    wer: wer,
                                } as any;
                            });
                            return [language, werItems] as [string, any[]];
                        });

                        return [category, groupData] as [
                            string,
                            [string, any[]][]
                        ];
                    }
                );
            } else if (title === "Language") {
                werList = ["Coverage", "Unofficial"].map((category) => {
                    const groupData = languages.map((language) => {
                        const werItems = items.map((item) => {
                            let wer: any;
                            if (item) {
                                const { metrics } = item;

                                wer = Object.entries(metrics.Language).find(
                                    ([lang, con]) => lang === language
                                );
                                if (wer) {
                                    wer = wer[1];
                                    wer.language = language;
                                }
                                if (wer === undefined) {
                                    wer = {
                                        language: language,
                                        Coverage: NaN,
                                        Unofficial: NaN,
                                    };
                                }
                                return {
                                    data: item,
                                    wer: wer,
                                } as any;
                            } else {
                                return undefined;
                            }
                        });

                        return [language, werItems] as [string, any[]];
                    });
                    return [category, groupData] as [string, [string, any[]][]];
                });
            } else if (title === "Latency") {
                werList = [this.state.selectedLatencyCategory].map(
                    (category) => {
                        const groupData = latencyList.map((language) => {
                            const werItems = items.map((item) => {
                                let wer: any;
                                if (item) {
                                    const { metrics } = item;
                                    if (
                                        metrics.Latency[
                                            this.state.selectedLatencyCategory
                                        ] &&
                                        JSON.stringify(metrics.Latency) !== "{}"
                                    ) {
                                        wer = Object.entries(
                                            metrics.Latency[
                                                this.state
                                                    .selectedLatencyCategory
                                            ]
                                        ).find(
                                            ([lang, con]) => lang === language
                                        );

                                        if (wer) {
                                            wer = wer[1];
                                            wer.language = language;
                                        }
                                        if (wer === undefined) {
                                            wer = {
                                                OcrMS: NaN,
                                                DetectorMS: NaN,
                                                RecognizerMS: NaN,
                                                language: language,
                                            };
                                        }
                                    }
                                }
                                return {
                                    data: item,
                                    wer: wer,
                                    latencyList: latencyCategory,
                                } as any;
                            });
                            return [language, werItems] as [string, any[]];
                        });
                        return [category, groupData] as [
                            string,
                            [string, any[]][]
                        ];
                    }
                );
            }

            return [title, werList] as [string, [string, [string, any[]][]][]];
        });
        return data.filter(([_, groupData]) => groupData.length > 0);
    }

    private _renderAsChart() {
        const { renderChartData } = this.state;
        let maxLanguage: number = 100;
        let maxPrinted: number = 10;
        let maxHandwritten: number = 10;
        let maxGeneral: number = 10;
        let maxVertical: number = 10;
        let maxLatency: number = 10;

        let defaultSelectedKey: String = "";
        let xAxisName: string = "";
        let YAxisMax: number = 30;

        if (renderChartData) {
            return (
                <>
                    {renderChartData.map(
                        (
                            [highlight, werList]: [string, any[]],
                            idx: number
                        ) => {
                            let chartOptions: IDropdownOption[] = [];
                            let latencyOptions: IDropdownOption[] = [];
                            return (
                                <Stack key={"hl_st_" + idx}>
                                    {werList[0][1].length !== 0 && (
                                        <Label key={"hl_lb_" + idx}>
                                            {highlight}
                                        </Label>
                                    )}
                                    <Stack horizontal>
                                        {werList.map(
                                            (
                                                [title, groupData]: any,
                                                index: number
                                            ) => {
                                                if (
                                                    highlight !== "Language" &&
                                                    groupData[0] &&
                                                    groupData[0][1] &&
                                                    groupData[0][1][0] &&
                                                    groupData[0][1][0].wer
                                                ) {
                                                    chartOptions = Object.keys(
                                                        groupData[0][1][0].wer
                                                    )
                                                        .filter(
                                                            (opt) =>
                                                                opt !==
                                                                "language"
                                                        )
                                                        .map((dropdownOpt) => {
                                                            return {
                                                                key: dropdownOpt,
                                                                text: dropdownOpt,
                                                            };
                                                        });
                                                    if (
                                                        highlight === "Latency"
                                                    ) {
                                                        latencyOptions =
                                                            groupData[0][1][0].latencyList.map(
                                                                (opt: any) => {
                                                                    return {
                                                                        key: opt,
                                                                        text: opt,
                                                                    };
                                                                }
                                                            );
                                                    }
                                                }

                                                const chartData = groupData.map(
                                                    ([
                                                        language,
                                                        werItems,
                                                    ]: any) => {
                                                        const series =
                                                            werItems.map(
                                                                (
                                                                    werItem: any,
                                                                    werIndex: any
                                                                ) => {
                                                                    let wer: any = 0;
                                                                    if (
                                                                        werItem.wer
                                                                    ) {
                                                                        if (
                                                                            title ===
                                                                                "Coverage" ||
                                                                            title ===
                                                                                "Unofficial"
                                                                        ) {
                                                                            wer =
                                                                                werItem
                                                                                    .wer[
                                                                                    title
                                                                                ] ===
                                                                                null
                                                                                    ? 0
                                                                                    : werItem
                                                                                          .wer[
                                                                                          title
                                                                                      ];
                                                                            maxLanguage =
                                                                                maxLanguage >
                                                                                wer
                                                                                    ? maxLanguage
                                                                                    : wer;
                                                                        } else if (
                                                                            title ===
                                                                            "Printed"
                                                                        ) {
                                                                            wer =
                                                                                werItem
                                                                                    .wer[
                                                                                    this
                                                                                        .state
                                                                                        .selectPrinted
                                                                                ] ===
                                                                                null
                                                                                    ? 0
                                                                                    : werItem
                                                                                          .wer[
                                                                                          this
                                                                                              .state
                                                                                              .selectPrinted
                                                                                      ];
                                                                            maxPrinted =
                                                                                maxPrinted >
                                                                                wer
                                                                                    ? maxPrinted
                                                                                    : wer;
                                                                        } else if (
                                                                            title ===
                                                                            "Handwritten"
                                                                        ) {
                                                                            wer =
                                                                                werItem
                                                                                    .wer[
                                                                                    this
                                                                                        .state
                                                                                        .selectedHandwritten
                                                                                ] ===
                                                                                null
                                                                                    ? 0
                                                                                    : werItem
                                                                                          .wer[
                                                                                          this
                                                                                              .state
                                                                                              .selectedHandwritten
                                                                                      ];
                                                                            maxHandwritten =
                                                                                maxHandwritten >
                                                                                wer
                                                                                    ? maxHandwritten
                                                                                    : wer;
                                                                        } else if (
                                                                            title ===
                                                                            "General Entity"
                                                                        ) {
                                                                            wer =
                                                                                werItem
                                                                                    .wer[
                                                                                    this
                                                                                        .state
                                                                                        .selectedGeneral
                                                                                ] ===
                                                                                null
                                                                                    ? 0
                                                                                    : werItem
                                                                                          .wer[
                                                                                          this
                                                                                              .state
                                                                                              .selectedGeneral
                                                                                      ];
                                                                            maxGeneral =
                                                                                maxGeneral >
                                                                                wer
                                                                                    ? maxGeneral
                                                                                    : wer;
                                                                        } else if (
                                                                            title ===
                                                                                "Vertical Entity" &&
                                                                            werItem.wer
                                                                        ) {
                                                                            wer =
                                                                                werItem
                                                                                    .wer[
                                                                                    this
                                                                                        .state
                                                                                        .selectedVertical
                                                                                ] ===
                                                                                null
                                                                                    ? 0
                                                                                    : werItem
                                                                                          .wer[
                                                                                          this
                                                                                              .state
                                                                                              .selectedVertical
                                                                                      ];
                                                                            maxVertical =
                                                                                maxVertical >
                                                                                wer
                                                                                    ? maxVertical
                                                                                    : wer;
                                                                        } else if (
                                                                            title ===
                                                                                this
                                                                                    .state
                                                                                    .selectedLatencyCategory &&
                                                                            werItem.wer
                                                                        ) {
                                                                            wer =
                                                                                werItem
                                                                                    .wer[
                                                                                    this
                                                                                        .state
                                                                                        .selectedLatency
                                                                                ] ===
                                                                                    null ||
                                                                                isNaN(
                                                                                    werItem
                                                                                        .wer[
                                                                                        this
                                                                                            .state
                                                                                            .selectedLatency
                                                                                    ]
                                                                                )
                                                                                    ? 0
                                                                                    : werItem
                                                                                          .wer[
                                                                                          this
                                                                                              .state
                                                                                              .selectedLatency
                                                                                      ];
                                                                            maxLatency =
                                                                                maxLatency >
                                                                                wer
                                                                                    ? maxLatency
                                                                                    : wer;
                                                                        }
                                                                    }

                                                                    return {
                                                                        key: `series_${werIndex}`,
                                                                        data: wer,
                                                                        color: getColorByIndex(
                                                                            werIndex
                                                                        ),
                                                                        legend: "",
                                                                    };
                                                                }
                                                            );

                                                        return {
                                                            name: language,
                                                            series: series,
                                                        };
                                                    }
                                                );

                                                if (
                                                    title === "Coverage" ||
                                                    title === "Unofficial"
                                                ) {
                                                    YAxisMax = maxLanguage;
                                                    xAxisName = "Script";
                                                } else if (
                                                    title === "Printed"
                                                ) {
                                                    defaultSelectedKey =
                                                        this.state
                                                            .selectPrinted;
                                                    YAxisMax = maxPrinted;
                                                    xAxisName = "Script";
                                                } else if (
                                                    title === "Handwritten"
                                                ) {
                                                    defaultSelectedKey =
                                                        this.state
                                                            .selectedHandwritten;
                                                    YAxisMax = maxHandwritten;
                                                    xAxisName = "Script";
                                                } else if (
                                                    title === "General Entity"
                                                ) {
                                                    defaultSelectedKey =
                                                        this.state
                                                            .selectedGeneral;
                                                    YAxisMax = maxGeneral;
                                                    xAxisName = "Entity";
                                                } else if (
                                                    title === "Vertical Entity"
                                                ) {
                                                    defaultSelectedKey =
                                                        this.state
                                                            .selectedVertical;
                                                    YAxisMax = maxVertical;
                                                    xAxisName = "Entity";
                                                } else {
                                                    defaultSelectedKey =
                                                        this.state
                                                            .selectedLatency;
                                                    YAxisMax = maxLatency;
                                                    xAxisName = "";
                                                }
                                                return (
                                                    <DocumentCard
                                                        key={`chart_${title}_${index}`}
                                                        className="overview__card"
                                                        styles={{
                                                            root: {
                                                                height: "100%",
                                                            },
                                                        }}
                                                        type={
                                                            DocumentCardType.compact
                                                        }
                                                    >
                                                        <DocumentCardDetails>
                                                            <Stack horizontal>
                                                                {highlight !==
                                                                    "Latency" && (
                                                                    <DocumentCardTitle
                                                                        className="overview__title"
                                                                        title={
                                                                            title
                                                                        }
                                                                    />
                                                                )}
                                                                {latencyOptions.length !==
                                                                    0 &&
                                                                    highlight ===
                                                                        "Latency" && (
                                                                        <Dropdown
                                                                            className="dropDown"
                                                                            styles={
                                                                                DROPDOWNSTYLES
                                                                            }
                                                                            options={
                                                                                latencyOptions
                                                                            }
                                                                            defaultSelectedKey={
                                                                                this
                                                                                    .state
                                                                                    .selectedLatencyCategory ??
                                                                                latencyOptions[0]
                                                                                    .key
                                                                            }
                                                                            onChange={(
                                                                                _event,
                                                                                option: any
                                                                            ) => {
                                                                                this.setState(
                                                                                    {
                                                                                        selectedLatencyCategory:
                                                                                            option.key,
                                                                                    }
                                                                                );
                                                                            }}
                                                                        ></Dropdown>
                                                                    )}
                                                                {chartOptions.length !==
                                                                    0 &&
                                                                    highlight !==
                                                                        "Language" && (
                                                                        <Dropdown
                                                                            className="dropDown"
                                                                            styles={
                                                                                DROPDOWNSTYLES
                                                                            }
                                                                            options={
                                                                                chartOptions
                                                                            }
                                                                            defaultSelectedKey={defaultSelectedKey.toString()}
                                                                            onChange={(
                                                                                _event,
                                                                                option: any
                                                                            ) => {
                                                                                if (
                                                                                    title ===
                                                                                    "Printed"
                                                                                ) {
                                                                                    this.setState(
                                                                                        {
                                                                                            selectPrinted:
                                                                                                option.key,
                                                                                        }
                                                                                    );
                                                                                } else if (
                                                                                    title ===
                                                                                    "Handwritten"
                                                                                ) {
                                                                                    this.setState(
                                                                                        {
                                                                                            selectedHandwritten:
                                                                                                option.key,
                                                                                        }
                                                                                    );
                                                                                } else if (
                                                                                    title ===
                                                                                    "General Entity"
                                                                                ) {
                                                                                    this.setState(
                                                                                        {
                                                                                            selectedGeneral:
                                                                                                option.key,
                                                                                        }
                                                                                    );
                                                                                } else if (
                                                                                    title ===
                                                                                    "Vertical Entity"
                                                                                ) {
                                                                                    this.setState(
                                                                                        {
                                                                                            selectedVertical:
                                                                                                option.key,
                                                                                        }
                                                                                    );
                                                                                } else {
                                                                                    this.setState(
                                                                                        {
                                                                                            selectedLatency:
                                                                                                option.key,
                                                                                        }
                                                                                    );
                                                                                }
                                                                            }}
                                                                        ></Dropdown>
                                                                    )}
                                                            </Stack>

                                                            {chartData.length !==
                                                                0 && (
                                                                <VerticalBarChart
                                                                    id={
                                                                        "root" +
                                                                        index.toString() +
                                                                        idx.toString()
                                                                    }
                                                                    height={600}
                                                                    width={
                                                                        highlight ===
                                                                        "Latency"
                                                                            ? 1200
                                                                            : 1000
                                                                    }
                                                                    yAxisMax={
                                                                        YAxisMax
                                                                    }
                                                                    yAxisMin={0}
                                                                    data={
                                                                        chartData
                                                                    }
                                                                    xAxisName={
                                                                        xAxisName
                                                                    }
                                                                    isDarkTheme={
                                                                        this
                                                                            .props
                                                                            .isDarkTheme
                                                                    }
                                                                ></VerticalBarChart>
                                                            )}
                                                        </DocumentCardDetails>
                                                    </DocumentCard>
                                                );
                                            }
                                        )}
                                    </Stack>
                                </Stack>
                            );
                        }
                    )}
                </>
            );
        } else {
            return <NoDataTip>No Highlight Metrics Generated</NoDataTip>;
        }
    }

    private _verifyAvailableData(
        data: [string, [string, [string, any[]][]][]][]
    ) {
        return (
            data.length > 0 &&
            data.some(
                ([_, x]) =>
                    x.length > 0 &&
                    x.some(
                        ([__, y]) =>
                            y.length > 0 &&
                            y.some(([___, z]) => z && z.length > 0)
                    )
            )
        );
    }

    private _preparWerData = (werItem: any[]) => {
        if (!werItem) {
            return [];
        }
        const werData = _.cloneDeep(werItem);
        werData.forEach((items) => {
            const highlightObjs = items.map((item: any) => item.Highlight);
            const hasHighlightObj = highlightObjs.some(
                (highlightObj: any) => highlightObj
            );
            if (hasHighlightObj) {
                items.childrens = this._formatWerData(highlightObjs);
            }
        });
        return werData;
    };

    private _formatWerData(items: []) {
        let scriptItemsArray: any[][] = [],
            tableContent: any[][] = [];
        items.forEach((item) => {
            let scriptItems: any[] = [];
            if (item) {
                scriptItems = Object.entries(item).map(([key, val]: any) => {
                    let obj: any = {};
                    Object.entries(val).forEach(([werType, item]: any) => {
                        if (werType !== "Highlight" && item) {
                            Object.entries(item).forEach(([k, v]: any) => {
                                const fieldName: string =
                                    werType + k.toString();
                                obj[fieldName] = v ?? "";
                            });
                        } else {
                            obj[werType] = item;
                        }
                    });

                    return Object.assign({ script: key }, obj);
                });
            }

            scriptItemsArray.push(scriptItems);
        });

        const sampleScriptItems = scriptItemsArray.find(
            (arr) => arr && arr.length > 0
        );
        if (sampleScriptItems && sampleScriptItems.length > 0) {
            const scripts = sampleScriptItems.map(
                (scriptItem) => scriptItem.script
            );

            tableContent = scripts.map((script) => {
                return scriptItemsArray.map((scriptItems, index) => {
                    let scriptItem = scriptItems.find(
                        (res) => res.script === script
                    );
                    if (scriptItem === undefined) {
                        scriptItem = {};
                    }
                    if (!scriptItem.id) {
                        scriptItem.id = index + script;
                    }

                    return scriptItem;
                });
            });
        }

        return tableContent;
    }
}
