import * as React from "react";
import { RouteProps } from "react-router";
import {
    IconButton,
    IIconProps,
    Modal,
    PrimaryButton,
    DefaultButton,
    Pivot,
    PivotItem,
    DocumentCard,
    DocumentCardDetails,
    DocumentCardTitle,
    DocumentCardType,
    IDocumentCardStyles,
} from "@fluentui/react";
import {
    DetailsList,
    DetailsListLayoutMode,
    IColumn,
    IDetailsHeaderProps,
    Selection,
    SelectionMode,
} from "@fluentui/react/lib/DetailsList";
import {
    Dropdown,
    IDropdownStyles,
    IDropdownOption,
} from "@fluentui/react/lib/Dropdown";
import { Label } from "@fluentui/react/lib/Label";
import { Link } from "@fluentui/react/lib/Link";
import { SearchBox } from "@fluentui/react/lib/SearchBox";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { Stack } from "@fluentui/react/lib/Stack";
import { Sticky, StickyPositionType } from "@fluentui/react/lib/Sticky";
import { IRenderFunction } from "@fluentui/react/lib/Utilities";
import { TooltipHost, DirectionalHint } from "@fluentui/react/lib/Tooltip";
import { MessageBar, MessageBarType } from "@fluentui/react";
import {
    Dialog,
    DialogType,
    DialogFooter,
    IDialogFooterStyles,
} from "@fluentui/react/lib/Dialog";
import { getId } from "@fluentui/react/lib/Utilities";
import {
    IOcrDatasetItem,
    queryDatasetInfo,
    queryDatasetItems,
    queryDatasetFiles,
    IOcrDatasetInfo,
    SQLRawImagesDatasetInfo,
    formatErrorMessage,
    CacheNotReadyError,
    IOcrDatasetDetails,
    queryDatasetDetails,
    UnauthorizedError,
} from "./Request";
import { IDatasetRenderInfo, getDatasetRenderInfo } from "./Render";
import "./Content.scss";
import { vdiRequest } from "../../Utils/fetch";
import AuthImage from "../../AuthComponent/AuthImage";
import AuthLink from "../../AuthComponent/AuthLink";

const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 200 },
};
const dialogFooterStyles: Partial<IDialogFooterStyles> = {
    actionsRight: { paddingRight: "3%", marginRight: "0px" },
    actions: { paddingBottom: "2%" },
};

const cardStyles: IDocumentCardStyles = {
    root: {
        maxWidth: "45%",
        minWidth: "45%",
        marginBottom: "2%",
        paddingBottom: "2%",
    },
};
const cardTitleStyles: IDocumentCardStyles = {
    root: {
        paddingBottom: "0",
        borderBottom: "1px solid rgb(234,234,234)",
        height: "25px",
        lineHeight: "25px",
        fontSize: "14px",
        fontWeight: "bolder",
    },
};
const cardContentTitleStyles: IDocumentCardStyles = {
    root: {
        height: "10px",
        lineHeight: "10px",
        fontSize: "12px",
        fontWeight: "bolder",
    },
};
const cardSecContentTitleStyles: IDocumentCardStyles = {
    root: {
        height: "100%",
        fontSize: "12px",
        display: "flex",
        flexWrap: "wrap",
        overflowWrap: "break-word",
        whiteSpace: "pre-line",
        userSelect: "text",
    },
};

const itemsPerPage = 100;
const itemsPerLoad = 1000;
const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (
    props,
    defaultRender
) => {
    if (!props) {
        return null;
    }
    return (
        <Sticky
            stickyPosition={StickyPositionType.Header}
            isScrollSynced={true}
        >
            {defaultRender!({
                ...props,
            })}
        </Sticky>
    );
};

interface IModalDisplayInfo {
    item: IOcrDatasetItem;
    imageUrl: string;
    navIndex: number;
    canPrev: boolean;
    canNext: boolean;
    showSpinner: boolean;
    typeNotSupport: boolean;
}

interface IDatasetContentState {
    datasetInfo: IOcrDatasetInfo;
    datasetRenderInfo: IDatasetRenderInfo;
    selectedColumns: string[];
    dropDownOptions: IDropdownOption[];
    allColumns: IColumn[];
    selectionDetails: string;
    items: IOcrDatasetItem[];
    displayItems: IOcrDatasetItem[];
    itemCount: number;
    pageCount: number;
    itemFilter: string;
    currentPageIndex: number;
    errorMessage: string;
    errorDetail: string;
    hideDialog: boolean;
    showModal: boolean;
    modalInfo: IModalDisplayInfo;
    currentFolder: string;
    datasetDetail: IOcrDatasetDetails;
}

const infoIcon: IIconProps = { iconName: "info" };

const defaultModalInfo: IModalDisplayInfo = {
    item: {},
    imageUrl: "",
    navIndex: 0,
    canPrev: false,
    canNext: false,
    showSpinner: false,
    typeNotSupport: false,
};

export class Content extends React.Component<RouteProps, IDatasetContentState> {
    private _selection: Selection;
    // Dialogue
    private _labelId: string = getId("dialogLabel");
    private _subTextId: string = getId("subTextLabel");

    constructor(props: RouteProps) {
        super(props);
        this._selection = new Selection({
            onSelectionChanged: () =>
                this.setState({
                    selectionDetails: this._getSelectionDetails(),
                }),
        });
        this._onSearch = this._onSearch.bind(this);
        this._onNavigateFolder = this._onNavigateFolder.bind(this);
        this._onItemInvoked = this._onItemInvoked.bind(this);
        this._onColumnDropDownChange = this._onColumnDropDownChange.bind(this);

        const renderInfo = getDatasetRenderInfo(SQLRawImagesDatasetInfo);
        this.state = {
            datasetInfo: SQLRawImagesDatasetInfo,
            datasetRenderInfo: renderInfo,
            items: [],
            displayItems: [],
            selectedColumns: renderInfo.initialSelected,
            dropDownOptions: renderInfo.options,
            allColumns: renderInfo.columns,
            selectionDetails: this._getSelectionDetails(),
            itemCount: 0,
            pageCount: 0,
            currentPageIndex: 0,
            itemFilter: "",
            errorMessage: "",
            errorDetail: "",
            hideDialog: true,
            showModal: false,
            modalInfo: defaultModalInfo,
            currentFolder: "",
            datasetDetail: {},
        };
    }
    public render() {
        const {
            displayItems,
            selectedColumns,
            allColumns,
            dropDownOptions,
            currentPageIndex,
            itemCount,
            errorMessage,
            errorDetail,
            hideDialog,
            showModal,
            modalInfo,
            datasetInfo,
            datasetRenderInfo,
            items,
            datasetDetail,
        } = this.state;
        let columns: IColumn[] = allColumns.filter(
            (value) =>
                selectedColumns.findIndex((col) => col === value.key) !== -1
        );
        const indexes = this._generatePageIndexes();

        //error message
        const ErrorMessage = () => (
            <MessageBar
                messageBarType={MessageBarType.error}
                isMultiline={false}
                dismissButtonAriaLabel="Close"
            >
                {errorMessage}&nbsp;
                <Link
                    styles={{ root: { color: "#75b6e7" } }}
                    onClick={this._showDialog}
                >
                    Show more details
                </Link>
            </MessageBar>
        );
        const cancelIcon: IIconProps = { iconName: "Cancel" };
        return (
            <div>
                <Modal
                    isOpen={showModal}
                    onDismiss={() =>
                        this.setState({
                            showModal: false,
                            modalInfo: { ...modalInfo, item: {} },
                        })
                    }
                    isBlocking={false}
                    containerClassName="modalContainer"
                >
                    <div className="header">
                        <Label className="modalLabel">{`${datasetInfo.name}-${datasetInfo.version}-${modalInfo.item._id}`}</Label>
                        <IconButton
                            className="iconButton"
                            iconProps={cancelIcon}
                            ariaLabel="Close popup modal"
                            onClick={() =>
                                this.setState({
                                    showModal: false,
                                    modalInfo: { ...modalInfo, item: {} },
                                })
                            }
                        />
                    </div>
                    <div className="content">
                        <div className="contentBody">
                            {!modalInfo.showSpinner &&
                                !modalInfo.typeNotSupport && (
                                    <AuthLink
                                        url={modalInfo.imageUrl}
                                        target="_blank"
                                    >
                                        <AuthImage
                                            url={modalInfo.imageUrl}
                                            className="image"
                                            onLoadingStateChange={(
                                                state: any
                                            ) => {
                                                if (state === 2) {
                                                    vdiRequest(
                                                        modalInfo.imageUrl
                                                    )
                                                        .then((response) => {
                                                            if (!response.ok) {
                                                                return formatErrorMessage(
                                                                    response
                                                                );
                                                            }
                                                        })
                                                        .then((error) => {
                                                            if (
                                                                error instanceof
                                                                CacheNotReadyError
                                                            ) {
                                                                this.setState({
                                                                    modalInfo: {
                                                                        ...modalInfo,
                                                                        showSpinner:
                                                                            true,
                                                                    },
                                                                });

                                                                setTimeout(
                                                                    () => {
                                                                        if (
                                                                            modalInfo.item ===
                                                                            this
                                                                                .state
                                                                                .modalInfo
                                                                                .item
                                                                        ) {
                                                                            this._onItemInvoked(
                                                                                modalInfo.item
                                                                            );
                                                                        }
                                                                    },
                                                                    5000
                                                                );
                                                            } else {
                                                                this.setState({
                                                                    modalInfo: {
                                                                        ...modalInfo,
                                                                        typeNotSupport:
                                                                            true,
                                                                    },
                                                                });
                                                            }
                                                        });
                                                }
                                            }}
                                        />
                                    </AuthLink>
                                )}
                            {modalInfo.showSpinner && (
                                <Label>
                                    <Spinner
                                        size={SpinnerSize.small}
                                        label="Dataset is preparing at service side. Please wait for a moment."
                                    />
                                </Label>
                            )}
                            {modalInfo.typeNotSupport && (
                                <Label>
                                    <AuthLink
                                        url={modalInfo.imageUrl}
                                        target="_blank"
                                    >
                                        Don't support to display this file type.
                                        View it from the browser instead.
                                    </AuthLink>
                                </Label>
                            )}
                        </div>
                        <h3>File Details:</h3>
                        <Stack
                            verticalFill
                            verticalAlign="end"
                            style={{ marginLeft: "1%" }}
                        >
                            <table>
                                <tbody>
                                    {Object.keys(modalInfo.item)
                                        .filter((key) => !key.startsWith("_"))
                                        .map((key, index) => (
                                            <tr key={index}>
                                                <td>{key}</td>
                                                <td>{modalInfo.item[key]}</td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </Stack>
                    </div>
                    <div className="footer">
                        <DialogFooter styles={dialogFooterStyles}>
                            <PrimaryButton
                                disabled={!modalInfo.canPrev}
                                onClick={() =>
                                    this._onItemInvoked(
                                        items[modalInfo.navIndex - 1]
                                    )
                                }
                                text="Previous"
                            />
                            <DefaultButton
                                disabled={!modalInfo.canNext}
                                onClick={() =>
                                    this._onItemInvoked(
                                        items[modalInfo.navIndex + 1]
                                    )
                                }
                                text="Next"
                            />
                        </DialogFooter>
                    </div>
                </Modal>

                <Sticky stickyPosition={StickyPositionType.Header}>
                    <Stack
                        horizontal
                        horizontalAlign="start"
                        className="detailsTitle"
                        tokens={{ childrenGap: 10 }}
                    >
                        <Stack.Item align="baseline">
                            {datasetInfo.name === "sql_rawimages" && (
                                <Label>
                                    <h2>Dataset Viewer</h2>
                                    <span>
                                        Welcome! Select a dataset on left
                                        navigation bar to get started.
                                    </span>
                                </Label>
                            )}
                            {datasetInfo.name !== "sql_rawimages" && (
                                <Label>
                                    <h2>{datasetInfo.name}</h2>
                                </Label>
                            )}
                        </Stack.Item>
                        <Stack.Item align="baseline">
                            <Label>
                                {" "}
                                <h3>
                                    {datasetInfo.name === "sql_rawimages"
                                        ? ""
                                        : `Version${datasetInfo.version}`}
                                </h3>
                            </Label>
                        </Stack.Item>
                    </Stack>
                </Sticky>

                <div style={{ marginLeft: "10px" }}>
                    {datasetInfo.name !== "sql_rawimages" && (
                        <Pivot aria-label="PivotofDataSet">
                            <PivotItem
                                headerText="Overview"
                                style={{ marginLeft: "10px" }}
                                headerButtonProps={{
                                    "data-order": 1,
                                }}
                            >
                                {datasetInfo.name !== "sql_rawimages" && (
                                    <div style={{ display: "inline-flex" }}>
                                        <DocumentCard
                                            styles={cardStyles}
                                            style={{
                                                marginTop: "10px",
                                                height: "100%",
                                                overflowWrap: "anywhere",
                                            }}
                                            type={DocumentCardType.compact}
                                            accentColor="rgb(255, 241, 204)"
                                        >
                                            <DocumentCardDetails>
                                                <DocumentCardTitle
                                                    styles={cardTitleStyles}
                                                    title="Overview"
                                                ></DocumentCardTitle>
                                                {Object.keys(
                                                    datasetInfo.obj
                                                ).map((val, key) => {
                                                    if (val === "tags") {
                                                        let res = [];
                                                        for (let item in datasetInfo
                                                            .obj[val]) {
                                                            res.push(
                                                                item +
                                                                    " : " +
                                                                    datasetInfo
                                                                        .obj[
                                                                        val
                                                                    ][item]
                                                            );
                                                        }
                                                        return (
                                                            <div key={key}>
                                                                <DocumentCardTitle
                                                                    title={val}
                                                                    styles={
                                                                        cardContentTitleStyles
                                                                    }
                                                                />
                                                                <DocumentCardTitle
                                                                    title={res.join(
                                                                        "\n"
                                                                    )}
                                                                    styles={
                                                                        cardSecContentTitleStyles
                                                                    }
                                                                />
                                                            </div>
                                                        );
                                                    } else if (
                                                        val === "datasetUrl"
                                                    ) {
                                                        return (
                                                            <div key={key}>
                                                                <DocumentCardTitle
                                                                    title={val}
                                                                    styles={
                                                                        cardContentTitleStyles
                                                                    }
                                                                />
                                                                <Link
                                                                    href={
                                                                        datasetInfo
                                                                            .obj[
                                                                            val
                                                                        ]
                                                                    }
                                                                    target="_blank"
                                                                >
                                                                    <DocumentCardTitle
                                                                        title={
                                                                            datasetInfo
                                                                                .obj[
                                                                                val
                                                                            ]
                                                                        }
                                                                        styles={
                                                                            cardSecContentTitleStyles
                                                                        }
                                                                    />
                                                                </Link>
                                                            </div>
                                                        );
                                                    } else if (val !== "repr") {
                                                        return (
                                                            <div key={key}>
                                                                <DocumentCardTitle
                                                                    title={val}
                                                                    styles={
                                                                        cardContentTitleStyles
                                                                    }
                                                                />
                                                                <DocumentCardTitle
                                                                    title={
                                                                        datasetInfo
                                                                            .obj[
                                                                            val
                                                                        ]
                                                                    }
                                                                    styles={
                                                                        cardSecContentTitleStyles
                                                                    }
                                                                />
                                                            </div>
                                                        );
                                                    }
                                                    return <div />;
                                                })}
                                            </DocumentCardDetails>
                                        </DocumentCard>
                                        <DocumentCard
                                            styles={cardStyles}
                                            style={{
                                                marginTop: "10px",
                                                height: "100%",
                                                overflowWrap: "anywhere",
                                                marginLeft: "15px",
                                            }}
                                            type={DocumentCardType.compact}
                                            accentColor="rgb(255, 241, 204)"
                                        >
                                            <DocumentCardDetails>
                                                <DocumentCardTitle
                                                    styles={cardTitleStyles}
                                                    title="Details"
                                                />
                                                {Object.keys(datasetDetail).map(
                                                    (val, key) => {
                                                        return (
                                                            <div key={key}>
                                                                <DocumentCardTitle
                                                                    title={val}
                                                                    styles={
                                                                        cardContentTitleStyles
                                                                    }
                                                                />
                                                                <AuthLink
                                                                    url={`/api${datasetDetail[val]}`}
                                                                    target="_blank"
                                                                    download={datasetDetail[
                                                                        val
                                                                    ].replace(
                                                                        /\//g,
                                                                        "_"
                                                                    )}
                                                                >
                                                                    <DocumentCardTitle
                                                                        title={
                                                                            datasetDetail[
                                                                                val
                                                                            ]
                                                                        }
                                                                        styles={
                                                                            cardSecContentTitleStyles
                                                                        }
                                                                    />
                                                                </AuthLink>
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </DocumentCardDetails>
                                        </DocumentCard>
                                    </div>
                                )}
                            </PivotItem>
                            <PivotItem headerText="Data List">
                                <div
                                    className="ms-Grid-row"
                                    style={{ paddingTop: "10px" }}
                                >
                                    <Label
                                        className="ms-Grid-col ms-sm6 ms-md4 ms-lg2"
                                        style={{ textAlign: "right" }}
                                    >
                                        <b>Query:</b>
                                    </Label>
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                        <SearchBox
                                            placeholder="Search"
                                            onSearch={this._onSearch}
                                        />
                                    </div>
                                    <TooltipHost
                                        directionalHint={
                                            DirectionalHint.bottomCenter
                                        }
                                        tooltipProps={
                                            datasetRenderInfo.searchTooltipProps
                                        }
                                    >
                                        <IconButton
                                            iconProps={infoIcon}
                                            title="Info"
                                            ariaLabel="Info"
                                        />
                                    </TooltipHost>
                                </div>
                                <div
                                    className="ms-Grid-row"
                                    style={{ paddingTop: "20px" }}
                                >
                                    <Label
                                        className="ms-Grid-col ms-sm6 ms-md4 ms-lg2"
                                        style={{ textAlign: "right" }}
                                    >
                                        <b>Fields:</b>
                                    </Label>
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                        <Dropdown
                                            className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"
                                            multiSelect
                                            selectedKeys={selectedColumns}
                                            onChange={
                                                this._onColumnDropDownChange
                                            }
                                            options={dropDownOptions}
                                            styles={dropdownStyles}
                                        />
                                    </div>
                                </div>
                                <div
                                    className="ms-Grid-row"
                                    style={{ paddingTop: "20px" }}
                                >
                                    <Label>
                                        <div
                                            className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"
                                            style={{
                                                textAlign: "left",
                                                paddingLeft: "20px",
                                            }}
                                        >
                                            {itemCount > 0 &&
                                                "Showing " +
                                                    (currentPageIndex *
                                                        itemsPerPage +
                                                        1) +
                                                    " to " +
                                                    Math.min(
                                                        (currentPageIndex + 1) *
                                                            itemsPerPage,
                                                        items.length
                                                    ) +
                                                    " of " +
                                                    itemCount +
                                                    " cached items."}
                                        </div>
                                    </Label>
                                    <div
                                        className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"
                                        style={{
                                            justifyContent: "right",
                                            paddingRight: "20px",
                                        }}
                                    >
                                        <Stack
                                            horizontal
                                            horizontalAlign="end"
                                            tokens={{ childrenGap: 5 }}
                                            styles={{ root: { margin: 10 } }}
                                        >
                                            {indexes.map((idx, i) => (
                                                <Link
                                                    onClick={() => {
                                                        this._setPageIndex(
                                                            Number(idx) - 1
                                                        );
                                                    }}
                                                    key={"link" + i}
                                                    disabled={
                                                        idx === "..." ||
                                                        idx ===
                                                            (
                                                                currentPageIndex +
                                                                1
                                                            ).toString()
                                                    }
                                                >
                                                    {idx}
                                                </Link>
                                            ))}
                                        </Stack>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        padding: "20px,10px",
                                        justifyContent: "center",
                                    }}
                                >
                                    {errorMessage && (
                                        <div>{<ErrorMessage />}</div>
                                    )}
                                    <Stack>
                                        <DetailsList
                                            items={displayItems}
                                            columns={columns}
                                            selectionMode={SelectionMode.none}
                                            layoutMode={
                                                DetailsListLayoutMode.justified
                                            }
                                            onRenderDetailsHeader={
                                                onRenderDetailsHeader
                                            }
                                            onItemInvoked={this._onItemInvoked}
                                        />
                                    </Stack>
                                </div>
                            </PivotItem>
                        </Pivot>
                    )}
                </div>

                <Dialog
                    hidden={hideDialog}
                    onDismiss={this._closeDialog}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: "Error Details",
                        closeButtonAriaLabel: "Close",
                        subText: errorDetail,
                    }}
                    modalProps={{
                        titleAriaId: this._labelId,
                        subtitleAriaId: this._subTextId,
                        isBlocking: false,
                        styles: { main: { maxWidth: 450 } },
                    }}
                ></Dialog>
            </div>
        );
    }

    public async setDataset(datasetName: string, datasetVersion: string) {
        const { datasetInfo } = this.state;
        if (
            datasetInfo.name !== datasetName ||
            datasetInfo.version !== datasetVersion
        ) {
            try {
                const [datasetInfo, datasetDetail] = await Promise.all<
                    IOcrDatasetInfo,
                    IOcrDatasetDetails
                >([
                    queryDatasetInfo(datasetName, datasetVersion),
                    queryDatasetDetails(datasetName, datasetVersion),
                ]);

                let items: IOcrDatasetItem[];
                if (datasetInfo.isFileDataset) {
                    items = await queryDatasetFiles(
                        datasetName,
                        datasetVersion
                    );
                    items.forEach(
                        (item) => (item.onClick = this._onNavigateFolder)
                    );
                } else {
                    items = await queryDatasetItems(
                        datasetName,
                        datasetVersion
                    );
                }
                const renderInfo = getDatasetRenderInfo(datasetInfo);
                this.setState({
                    datasetInfo: datasetInfo,
                    datasetRenderInfo: renderInfo,
                    selectedColumns: renderInfo.initialSelected,
                    dropDownOptions: renderInfo.options,
                    allColumns: renderInfo.columns,
                    items: items,
                    itemCount: items.length,
                    itemFilter: "",
                    pageCount: Math.ceil(items.length / 100),
                    currentPageIndex: 0,
                    displayItems: items.slice(0, 100),
                    errorMessage: "",
                    errorDetail: "",
                    modalInfo: defaultModalInfo,
                    currentFolder: "",
                    datasetDetail: datasetDetail,
                });
            } catch (error) {
                let errorMsg = "";
                if (error instanceof UnauthorizedError) {
                    errorMsg =
                        "Authentication token expired. Please refresh the page to login again.";
                } else {
                    errorMsg = "Fail to load dataset.";
                }
                this.setState({
                    items: [],
                    itemCount: 0,
                    pageCount: 0,
                    currentPageIndex: 0,
                    displayItems: [],
                    errorMessage: errorMsg,
                    errorDetail: error.message,
                    modalInfo: defaultModalInfo,
                    currentFolder: "",
                });
            }
        }
    }

    private _appendDatasteItems(
        items: IOcrDatasetItem[],
        itemsAfter: IOcrDatasetItem[],
        srcInfo: IOcrDatasetInfo,
        srcFilter: string,
        srcFolder: string
    ): void {
        const { datasetInfo, currentFolder, itemFilter } = this.state;
        if (
            itemsAfter.length > 0 &&
            datasetInfo === srcInfo &&
            currentFolder === srcFolder &&
            itemFilter === srcFilter
        ) {
            let tempItems = items.concat(itemsAfter);
            this.setState({
                items: tempItems,
                pageCount: Math.ceil(tempItems.length / itemsPerPage),
                itemCount: tempItems.length,
            });
        }
    }

    private _setPageIndex(pageIndex: number): void {
        const {
            datasetInfo,
            items,
            currentFolder,
            itemCount,
            pageCount,
            itemFilter,
        } = this.state;
        if (pageIndex + 1 === pageCount) {
            if (datasetInfo.isFileDataset) {
                queryDatasetFiles(
                    datasetInfo.name,
                    datasetInfo.version,
                    currentFolder,
                    itemFilter,
                    itemsPerLoad,
                    itemCount
                ).then((itemsAfter) =>
                    this._appendDatasteItems(
                        items,
                        itemsAfter,
                        datasetInfo,
                        itemFilter,
                        currentFolder
                    )
                );
            } else {
                queryDatasetItems(
                    datasetInfo.name,
                    datasetInfo.version,
                    itemFilter,
                    itemsPerLoad,
                    itemCount
                ).then((itemsAfter) =>
                    this._appendDatasteItems(
                        items,
                        itemsAfter,
                        datasetInfo,
                        itemFilter,
                        currentFolder
                    )
                );
            }
        }

        if (this.state.currentPageIndex !== pageIndex) {
            let displayItems = datasetInfo.isFileDataset
                ? this._generateDisplayItemsForFileDataset(
                      items.slice(pageIndex * 100, (pageIndex + 1) * 100),
                      currentFolder
                  )
                : items.slice(pageIndex * 100, (pageIndex + 1) * 100);

            this.setState({
                currentPageIndex: pageIndex,
                displayItems: displayItems,
            });
        }
    }

    private async _onItemInvoked(item: IOcrDatasetItem): Promise<void> {
        const { datasetInfo, items } = this.state;
        let index = items.indexOf(item);
        if (datasetInfo.isFileDataset) {
            if (item.isDir) {
                item.onClick(item.path);
            } else {
                const url = [
                    `api/datasets/${datasetInfo.name}/versions`,
                    `/${datasetInfo.version}/tree`,
                    `/${encodeURIComponent(item.path)}`,
                ].join("");
                const modalInfo: IModalDisplayInfo = {
                    item: {
                        _id: item._id,
                        name: item.name,
                        path: item.path,
                        size: item.size,
                    },
                    imageUrl: url,
                    navIndex: index,
                    canPrev: index > 0 && !items[index - 1].isDir,
                    canNext: index + 1 < items.length,
                    showSpinner: false,
                    typeNotSupport: false,
                };
                this.setState({
                    showModal: true,
                    modalInfo: modalInfo,
                });
            }
        } else {
            const url = [
                `api/datasets/${datasetInfo.name}/versions/`,
                `${datasetInfo.version}/items`,
                `/${encodeURIComponent(item._id)}/fields/imagedata`,
            ].join("");
            const modalInfo: IModalDisplayInfo = {
                item: item,
                imageUrl: url,
                navIndex: index,
                canPrev: index > 0,
                canNext: index + 1 < items.length,
                showSpinner: false,
                typeNotSupport: false,
            };
            this.setState({
                showModal: true,
                modalInfo: modalInfo,
            });
        }
    }

    private async _onSearch(newValue: any) {
        const filter = newValue.trim();
        const { datasetInfo, currentFolder } = this.state;
        try {
            const items = datasetInfo.isFileDataset
                ? await queryDatasetFiles(
                      datasetInfo.name,
                      datasetInfo.version,
                      currentFolder,
                      filter
                  )
                : await queryDatasetItems(
                      datasetInfo.name,
                      datasetInfo.version,
                      filter
                  );

            const displayItems = datasetInfo.isFileDataset
                ? this._generateDisplayItemsForFileDataset(
                      items.slice(0, 100),
                      currentFolder
                  )
                : items.slice(0, 100);

            this.setState({
                items: items,
                itemCount: items.length,
                pageCount: Math.ceil(items.length / 100),
                itemFilter: filter,
                currentPageIndex: 0,
                displayItems: displayItems,
                errorMessage: "",
                errorDetail: "",
            });
        } catch (error) {
            this.setState({
                errorMessage: "Fail to filter dataset items.",
                errorDetail: error.message,
            });
        }
    }

    private _onColumnDropDownChange(
        event: React.FormEvent<HTMLDivElement>,
        option: IDropdownOption | undefined
    ): void {
        const newSelectedItems = [...this.state.selectedColumns];
        if (option) {
            if (option.selected) {
                // add the option if it's checked
                newSelectedItems.push(option.key as string);
            } else {
                // remove the option if it's unchecked
                const currIndex = newSelectedItems.indexOf(
                    option.key as string
                );
                if (currIndex > -1) {
                    newSelectedItems.splice(currIndex, 1);
                }
            }

            this.setState({ selectedColumns: newSelectedItems });
        }
    }

    private _getSelectionDetails(): string {
        const selectionCount = this._selection.getSelectedCount();

        switch (selectionCount) {
            case 0:
                return "No items selected";
            case 1:
                return "1 item selected: ";
            default:
                return `${selectionCount} items selected`;
        }
    }

    private _generatePageIndexes() {
        // currentPageIndex is zero based index
        const { pageCount, currentPageIndex } = this.state;
        if (pageCount <= 7) {
            return Array(pageCount).map((i) => (i + 1).toString());
        }

        let indexes: string[];
        if (currentPageIndex < 4) {
            indexes = ["1", "2", "3", "4", "5", "...", pageCount.toString()];
        } else if (pageCount - currentPageIndex < 5) {
            indexes = [
                "1",
                "...",
                (pageCount - 4).toString(),
                (pageCount - 3).toString(),
                (pageCount - 2).toString(),
                (pageCount - 1).toString(),
                pageCount.toString(),
            ];
        } else {
            indexes = [
                "1",
                "...",
                currentPageIndex.toString(),
                (currentPageIndex + 1).toString(),
                (currentPageIndex + 2).toString(),
                "...",
                pageCount.toString(),
            ];
        }
        return indexes;
    }

    private _showDialog = (): void => {
        this.setState({ hideDialog: false });
    };

    private _closeDialog = (): void => {
        this.setState({ hideDialog: true });
    };

    private async _onNavigateFolder(folder: string) {
        const { datasetInfo } = this.state;
        try {
            let items = await queryDatasetFiles(
                datasetInfo.name,
                datasetInfo.version,
                folder
            );

            let displayItems = this._generateDisplayItemsForFileDataset(
                items.slice(0, 100),
                folder
            );

            this.setState({
                items: items,
                itemCount: items.length,
                pageCount: Math.ceil(items.length / 100),
                currentPageIndex: 0,
                displayItems: displayItems,
                errorMessage: "",
                errorDetail: "",
                currentFolder: folder,
            });
        } catch (error) {
            this.setState({
                errorMessage: "Fail to navigate to the folder.",
                errorDetail: error.message,
            });
        }
    }

    private _generateDisplayItemsForFileDataset(
        items: IOcrDatasetItem[],
        folder: string
    ) {
        let displayItems: IOcrDatasetItem[];
        if (folder) {
            let parts = folder.split("/") as string[];
            let parent = parts.slice(0, parts.length - 1).join("/");
            displayItems = [
                {
                    name: "[..]",
                    path: parent,
                    isDir: true,
                },
                ...items,
            ];
        } else {
            displayItems = items;
        }
        displayItems.forEach((item) => (item.onClick = this._onNavigateFolder));
        return displayItems;
    }
}
