import * as React from "react";

import { Stack, IStackItemStyles } from "@fluentui/react";
import { IColumn } from "@fluentui/react/lib/DetailsList";
import {
    DropdownMenuItemType,
    IDropdownOption,
} from "@fluentui/react/lib/Dropdown";
import { Link } from "@fluentui/react/lib/Link";
import { ITooltipProps } from "@fluentui/react/lib/Tooltip";

import { IOcrDatasetItem, IOcrDatasetInfo } from "./Request";
import AuthLink from "../../AuthComponent/AuthLink";

// prettier-ignore
const imagesColumns: IColumn[] = [
    { key: "imageid",       name: "ImageID",       fieldName: "imageid",       minWidth: 100, maxWidth: 200, isResizable: true, onRender: item => <AuthLink url={"/api/data/blobimage?blobpath=" + item.imageblobpath} target="_blank">{item.imageid}</AuthLink> },
    { key: "language",      name: "Language",      fieldName: "language",      minWidth: 100, maxWidth: 200, isResizable: true },
    { key: "category",      name: "Category",      fieldName: "category",      minWidth: 100, maxWidth: 200, isResizable: true },
    { key: "source",        name: "Source",        fieldName: "source",        minWidth: 100, maxWidth: 200, isResizable: true },
    { key: "tags",          name: "Tags",          fieldName: "tags",          minWidth: 100, maxWidth: 200, isResizable: true },
    { key: "imageblobpath", name: "ImageBlobPath", fieldName: "imageblobpath", minWidth: 100, maxWidth: 200, isResizable: true },
    { key: "usages",        name: "Usages",        fieldName: "usages",        minWidth: 100, maxWidth: 200, isResizable: true },
    { key: "checks",        name: "Checks",        fieldName: "checks",        minWidth: 100, maxWidth: 200, isResizable: true },
    { key: "processes",     name: "Processes",     fieldName: "processes",     minWidth: 100, maxWidth: 200, isResizable: true },
    { key: "bboxlabel",     name: "BBoxLabel",     fieldName: "bboxlabel",     minWidth: 100, maxWidth: 200, isResizable: true },
    { key: "testlabel",     name: "TestLabel",     fieldName: "testlabel",     minWidth: 100, maxWidth: 200, isResizable: true },
    { key: "reserved",      name: "Reserved",      fieldName: "reserved",      minWidth: 100, maxWidth: 200, isResizable: true },
    { key: "date",          name: "Date",          fieldName: "date",          minWidth: 100, maxWidth: 200, isResizable: true },
];

// prettier-ignore
const textlinesColumns: IColumn[] = [
    { key: "textlineid",        name: "TextLineID",        fieldName: "textlineid",        minWidth: 100, maxWidth: 200, isResizable: true, onRender: item => <AuthLink url={"/api/data/blobimage?blobpath=" + item.texlinepath} target="_blank">{item.textlineid}</AuthLink> },
    { key: "language",          name: "Language",          fieldName: "language",          minWidth: 100, maxWidth: 200, isResizable: true },
    { key: "category",          name: "Category",          fieldName: "category",          minWidth: 100, maxWidth: 200, isResizable: true },
    { key: "texttag",           name: "TextTag",           fieldName: "texttag",           minWidth: 100, maxWidth: 200, isResizable: true },
    { key: "style",             name: "Style",             fieldName: "style",             minWidth: 100, maxWidth: 200, isResizable: true },
    { key: "checks",            name: "Checks",            fieldName: "checks",            minWidth: 100, maxWidth: 200, isResizable: true },
    { key: "_text",             name: "_TEXT",             fieldName: "_text",             minWidth: 100, maxWidth: 200, isResizable: true },
    { key: "_initaltext",       name: "_INITALTEXT",       fieldName: "_initaltext",       minWidth: 100, maxWidth: 200, isResizable: true },
    { key: "_categorytag",      name: "_CATEGORYTAG",      fieldName: "_categorytag",      minWidth: 100, maxWidth: 200, isResizable: true },
    { key: "_textdirectiontag", name: "_TEXTDIRECTIONTAG", fieldName: "_textdirectiontag", minWidth: 100, maxWidth: 200, isResizable: true },
    { key: "_tag",              name: "_TAG",              fieldName: "_tag",              minWidth: 100, maxWidth: 200, isResizable: true },
    { key: "_polygon",          name: "_POLYGON",          fieldName: "_polygon",          minWidth: 100, maxWidth: 200, isResizable: true },
    { key: "_keypoints",        name: "_KEYPOINTS",        fieldName: "_keypoints",        minWidth: 100, maxWidth: 200, isResizable: true },
    { key: "_info",             name: "_INFO",             fieldName: "_info",             minWidth: 100, maxWidth: 200, isResizable: true },
    { key: "_angle",            name: "_ANGLE",            fieldName: "_angle",            minWidth: 100, maxWidth: 200, isResizable: true },
    { key: "_lines",            name: "_LINES",            fieldName: "_lines",            minWidth: 100, maxWidth: 200, isResizable: true },
    { key: "timestamp",         name: "Timestamp",         fieldName: "timestamp",         minWidth: 100, maxWidth: 200, isResizable: true },
    { key: "textlinepath",      name: "TextLinePath",      fieldName: "texlinepath",       minWidth: 100, maxWidth: 200, isResizable: true },
    { key: "usages",            name: "Usages",            fieldName: "usages",            minWidth: 100, maxWidth: 200, isResizable: true },
];

// prettier-ignore
const imagesOptions: IDropdownOption[] = [
    { key: "rawimageHeader", text: "RawImages", itemType: DropdownMenuItemType.Header },
    { key: "imageid",        text: "ImageID",   disabled: true },
    { key: "language",       text: "Language" },
    { key: "category",       text: "Category" },
    { key: "source",         text: "Source" },
    { key: "tags",           text: "Tags" },
    { key: "imageblobpath",  text: "ImageBlobPath" },
    { key: "usages",         text: "Usages" },
    { key: "checks",         text: "Checks" },
    { key: "processes",      text: "Processes" },
    { key: "bboxlabel",      text: "BBoxLabel" },
    { key: "testlabel",      text: "TestLabel" },
    { key: "reserved",       text: "Reserved" },
    { key: "date",           text: "Date" },
];

// prettier-ignore
const textlinesOptions: IDropdownOption[] = [
    { key: "textlinesHeader",   text: "TextLines",  itemType: DropdownMenuItemType.Header },
    { key: "textlineid",        text: "TextLineID", disabled: true },
    { key: "language",          text: "Language" },
    { key: "category",          text: "Category" },
    { key: "texttag",           text: "TextTag" },
    { key: "style",             text: "Style" },
    { key: "checks",            text: "Checks" },
    { key: "_text",             text: "_TEXT" },
    { key: "_initaltext",       text: "_INITALTEXT" },
    { key: "_categorytag",      text: "_CATEGORYTAG" },
    { key: "_textdirectiontag", text: "_TEXTDIRECTIONTAG" },
    { key: "_tag",              text: "_TAG" },
    { key: "_polygon",          text: "_POLYGON" },
    { key: "_keypoints",        text: "_KEYPOINTS" },
    { key: "_info",             text: "_INFO" },
    { key: "_angle",            text: "_ANGLE" },
    { key: "_lines",            text: "_LINES" },
    { key: "timestamp",         text: "Timestamp" },
    { key: "texlinepath",       text: "TexLinePath" },
    { key: "usages",            text: "Usages" },
];

const stackItemStyles: IStackItemStyles = {
    root: {
        padding: 5,
    },
};

const stackIconItemStyles: IStackItemStyles = {
    root: {
        padding: 5,
        width: 20,
    },
};

function getFileTypeIcon(name: string, version: string, item: IOcrDatasetItem) {
    if (item.isDir) {
        return (
            <Stack horizontal>
                <Stack.Item align="end" styles={stackIconItemStyles}>
                    <i
                        className="ms-Icon ms-Icon--FabricFolderFill"
                        aria-hidden="true"
                        style={{
                            color: "#dcb67a",
                            fontSize: 16,
                            display: "flex",
                        }}
                    />
                </Stack.Item>
                <Stack.Item align="end" styles={stackItemStyles}>
                    <Link onClick={() => item.onClick(item.path)}>
                        {item.name}
                    </Link>
                </Stack.Item>
            </Stack>
        );
    } else {
        let parts = item.name.split(".") as string[];
        let iconName = "Page";
        if (parts.length > 1) {
            switch (parts[parts.length - 1].toLowerCase()) {
                case "pdf":
                    iconName = "PDF";
                    break;
                case "jpg":
                case "jpeg":
                case "png":
                case "tif":
                case "tiff":
                case "gif":
                case "bmp":
                    iconName = "FileImage";
                    break;
                case "xml":
                    iconName = "Embed";
                    break;
            }
        }

        const urlPrefix =
            "api/datasets/" + name + "/versions/" + version + "/tree/";
        return (
            <Stack horizontal>
                <Stack.Item align="end" styles={stackIconItemStyles}>
                    <i
                        className={"ms-Icon ms-Icon--" + iconName}
                        aria-hidden="true"
                        style={{ fontSize: 16, display: "flex" }}
                    />
                </Stack.Item>
                <Stack.Item align="end" styles={stackItemStyles}>
                    <AuthLink
                        url={urlPrefix + encodeURIComponent(item.path)}
                        target="_blank"
                    >
                        {item.name}
                    </AuthLink>
                </Stack.Item>
            </Stack>
        );
    }
}

function getFilesetColumns(name: string, version: string): IColumn[] {
    const filesetColumns: IColumn[] = [
        {
            key: "filepath",
            name: "File Path",
            fieldName: "path",
            minWidth: 200,
            isResizable: true,
            onRender: (item) => getFileTypeIcon(name, version, item),
        },
        {
            key: "size",
            name: "Size",
            fieldName: "size",
            minWidth: 200,
            isResizable: true,
        },
    ];

    return filesetColumns;
}

const filesetOptions: IDropdownOption[] = [
    { key: "filepath", text: "filepath", disabled: true },
    { key: "size", text: "size", disabled: true },
];

const helperUrl =
    "https://dev.azure.com/msresearch/OneOCR/_git/OcrDataPipeline?path=%2Fservice%2Fbackend%2FREADME.md&_a=preview&anchor=dataset-item-filter";

const defaultTooltipProps: ITooltipProps = {
    onRenderContent: () => (
        <p onMouseDown={(ev) => ev.preventDefault()}>
            Select a dataset on left navigation bar to get started.
        </p>
    ),
};

const datasetTooltipProps: ITooltipProps = {
    onRenderContent: () => (
        <p onMouseDown={(ev) => ev.preventDefault()}>
            Use OData filter syntax filter items.&nbsp;
            <Link href={helperUrl} target="_blank">
                Supported filter expressions.
            </Link>
            &nbsp;Here're some examples:
            <ul style={{ margin: "10px 20px 10px 20px", padding: 0 }}>
                <li>startswith(filename, '03c7') and width gt 200</li>
                <li>contains(filepath, 'DevTesting/Images/Document')</li>
                <li>keypoints eq null</li>
            </ul>
        </p>
    ),
};

const filesetTooltipProps: ITooltipProps = {
    onRenderContent: () => (
        <p onMouseDown={(ev) => ev.preventDefault()}>
            Type on the search box to filter files with prefix match.
        </p>
    ),
};

function getDatasetColumns(info: IOcrDatasetInfo): IColumn[] {
    const regexp = RegExp("^bytes: \\d*$", "g");
    return info.fields.map((f) => {
        const column: IColumn = {
            key: f,
            name: f,
            fieldName: f,
            minWidth: 50,
            maxWidth: 200,
            isResizable: true,
            onRender: (item) => {
                const val = item[f as keyof IOcrDatasetItem] as string;
                if (regexp.exec(val) !== null) {
                    const url = [
                        `api/datasets/${info.name}/versions/${info.version}`,
                        `/items/${encodeURIComponent(item._id)}/fields/${f}`,
                    ].join("");
                    return (
                        <AuthLink url={url} target="_blank">
                            {val}
                        </AuthLink>
                    );
                } else {
                    return val;
                }
            },
        };
        return column;
    });
}

function getDatasetDropdownOptions(info: IOcrDatasetInfo): IDropdownOption[] {
    return info.fields.map((f) => {
        const option: IDropdownOption = {
            key: f,
            text: f,
        };
        return option;
    });
}

export interface IDatasetRenderInfo {
    options: IDropdownOption[];
    columns: IColumn[];
    initialSelected: string[];
    searchTooltipProps?: ITooltipProps;
}

export function getDatasetRenderInfo(
    datasetInfo: IOcrDatasetInfo
): IDatasetRenderInfo {
    if (datasetInfo.name === "sql_rawimages") {
        return {
            options: imagesOptions,
            columns: imagesColumns,
            initialSelected: [
                "imageid",
                "language",
                "category",
                "tags",
                "imageblobpath",
                "usages",
            ],
            searchTooltipProps: defaultTooltipProps,
        };
    } else if (datasetInfo.name === "sql_textlines") {
        return {
            options: textlinesOptions,
            columns: textlinesColumns,
            initialSelected: [
                "textlineid",
                "language",
                "category",
                "_text",
                "textlinepath",
                "usages",
            ],
            searchTooltipProps: defaultTooltipProps,
        };
    } else if (datasetInfo.isFileDataset) {
        return {
            options: filesetOptions,
            columns: getFilesetColumns(datasetInfo.name, datasetInfo.version),
            initialSelected: ["filepath", "size"],
            searchTooltipProps: filesetTooltipProps,
        };
    } else {
        return {
            options: getDatasetDropdownOptions(datasetInfo),
            columns: getDatasetColumns(datasetInfo),
            initialSelected: datasetInfo.fields,
            searchTooltipProps: datasetTooltipProps,
        };
    }
}
