import * as React from "react";
import "./OverviewTable.scss";
import "./TableList.scss";
import { Paragraph, TableCell, TextRun } from "docx";

import {
    // DetailsList
    DetailsList,
    DetailsListLayoutMode,
    DetailsRow,
    IColumn,
    IDetailsListProps,
    IDetailsRowStyles,
    SelectionMode,
    ConstrainMode,

    // Stack
    Stack,

    //tootip
    DirectionalHint,
    ITooltipHostStyles,
    TooltipHost,

    // others
    getTheme,
    Label,
    FontIcon,
    mergeStyles,
    IDetailsHeaderProps,
    DetailsHeader,
    IconButton,
    IDetailsListStyles,
} from "@fluentui/react";

import { calculateGap, calculateGapNum, calculateUnit } from "../Utils";
import { IMetricUnit, RGB_Color } from "../DataContract";
import { ColumnValueType, NumContrastPolicy, TableColumn } from "./TableList";
import { FilterBar } from "./FilterBar";
import _ from "lodash";
import { Consumer } from "../Layout";
import CopyToClipboard from "react-copy-to-clipboard";
import { CalloutType, formartTarget } from "./CalloutTable";
import { saveTableCellsToDocx } from "../Utils/ExportFile";

type ITableItem<T extends IMetricUnit> = [string, (T | null)[]];
interface IProps<T> {
    evalData: T[][];
    columns: TableColumn[];
    tableTitle: string;
    downloadTableTitle?: string;
    cellWidth?: number;
    gapUnit?: calculateUnit;
    isCompactStyle?: boolean;
    datasetName?: string;
    enableFilter?: boolean;
    displayNaN?: boolean;
    isDarkTheme?: boolean;
    prefixIcon?: boolean;
    layoutMode?: DetailsListLayoutMode;
    targetKeys?: string[];
    calloutType?: CalloutType;
    onItemInvoked?: (item?: any, index?: number, ev?: Event) => void;
    deepLinkHandler?: (key: string, linkData: any) => void;
    onCellDoubleClick?: (item: any, column: IColumn, ev?: Event) => void;
}

interface IState<T extends IMetricUnit> {
    columns: TableColumn[];
    displayItems: ITableItem<T>[];
    filterDict: Map<string | number, any[]>;
    queryString: string;
    childColumns?: TableColumn[];
}

const calloutProps = {
    gapSpace: 0,
    isBeakVisible: false,
    directionalHint: DirectionalHint.bottomAutoEdge,
};
const hostStyles: Partial<ITooltipHostStyles> = {
    root: { display: "inline-block", marginTop: "6px" },
};
const hostCompactStyles: Partial<ITooltipHostStyles> = {
    root: { display: "inline-block" },
};
const theme = getTheme();
const tipIconClass = mergeStyles({
    fontSize: 18,
    height: 18,
    width: 18,
    marginLeft: "10px",
});

const gridStyles: Partial<IDetailsListStyles> = {
    root: {
        overflowX: "scroll",
        width: "100%",
        height: "100%",
        selectors: {
            "& [role=grid]": {
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                height: "100%",
                width: "100%",
            },
        },
    },
    headerWrapper: {
        flex: "0 0 auto",
    },
    contentWrapper: {
        flex: "1 1 auto",
    },
};

export class OverviewTable<IMetric extends IMetricUnit> extends React.Component<
    IProps<IMetric>,
    IState<IMetric>
> {
    private _gapUnit: calculateUnit;
    constructor(props: IProps<IMetric>) {
        super(props);

        this._gapUnit = this.props.gapUnit ?? calculateUnit.Number;
        this._updateSourceItemsAndFilterRules =
            this._updateSourceItemsAndFilterRules.bind(this);
        this.state = {
            displayItems: Object.entries(props.evalData),
            columns: [],
            filterDict: new Map<string | number, any[]>(),
            queryString: "",
        };
    }

    public render() {
        return <>{this._renderList()}</>;
    }

    private _renderList = () => {
        const { columns, displayItems, filterDict, queryString } = this.state;
        const {
            enableFilter,
            evalData,
            layoutMode = DetailsListLayoutMode.justified,
            tableTitle,
            downloadTableTitle,
        } = this.props;
        return (
            <Stack style={{ paddingBottom: "10px" }}>
                <Stack
                    horizontal
                    tokens={{ childrenGap: 10 }}
                    className="titleBar"
                >
                    <Label>{tableTitle}</Label>
                    <IconButton
                        iconProps={{ iconName: "WordLogoInverse16" }}
                        className="itemId__copy"
                        title="Download table as docx"
                        ariaLabel="Download table as docx"
                        onClick={async (
                            event: React.MouseEvent<
                                HTMLButtonElement,
                                MouseEvent
                            >
                        ) => {
                            await this._downloadTableAsDocx(
                                event,
                                columns,
                                displayItems,
                                downloadTableTitle ?? "Table"
                            );
                        }}
                    ></IconButton>
                </Stack>
                {!!enableFilter && (
                    <Stack className={"filterContainer"}>
                        <FilterBar
                            columns={columns}
                            allItems={Object.entries(evalData)}
                            filterDict={filterDict}
                            queryString={queryString}
                            updateDisplayItemsAndFilterDict={
                                this._updateSourceItemsAndFilterRules
                            }
                            isDarkTheme={this.props.isDarkTheme}
                        ></FilterBar>
                    </Stack>
                )}
                <Consumer>
                    {(value) => {
                        return (
                            <DetailsList
                                styles={gridStyles}
                                key={String(value)}
                                columns={columns}
                                constrainMode={ConstrainMode.unconstrained}
                                items={displayItems}
                                layoutMode={layoutMode}
                                selectionMode={SelectionMode.none}
                                onItemInvoked={this.props.onItemInvoked}
                                onRenderRow={this._onRenderRow}
                                listProps={{
                                    className: this.props.isCompactStyle
                                        ? "compactList"
                                        : undefined,
                                }}
                                onShouldVirtualize={
                                    //Fluent UI detail list issue that rendering blank row (Version: ^7.123.9)
                                    //Here is workaround for that. (Nov 12, 2021)
                                    () => false
                                }
                                onRenderDetailsHeader={
                                    this._onRenderDetailsHeader
                                }
                            />
                        );
                    }}
                </Consumer>
            </Stack>
        );
    };

    public shouldComponentUpdate(
        nextProps: Readonly<IProps<IMetric>>,
        nextState: Readonly<IState<IMetric>>
    ) {
        if (
            _.isEqual(nextState, this.state) &&
            _.isEqual(nextProps.columns, this.props.columns) &&
            _.isEqual(nextProps.evalData, this.props.evalData) &&
            _.isEqual(nextProps.tableTitle, this.props.tableTitle)
        ) {
            return false;
        }
        return true;
    }

    public componentDidMount() {
        this._columnsUpdate();
    }

    public componentDidUpdate(prevProps: IProps<IMetric>) {
        if (this.props.gapUnit !== prevProps.gapUnit) {
            this._gapUnit = this.props.gapUnit ?? calculateUnit.Number;
        }

        if (this.props.columns !== prevProps.columns) {
            this._columnsUpdate();
        }
        if (this.props.evalData !== prevProps.evalData) {
            this._getDisplayItems().then((items) =>
                this.setState({
                    queryString: "",
                    filterDict: new Map<string | number, any[]>(),
                    displayItems: items,
                })
            );
        }
    }

    private _columnsUpdate() {
        const columns = this.props.columns.map((col) => {
            return {
                ...col,
                fieldName: col.key,
                onRender: this._onColumnRender,
                isResizable: true,
            };
        });

        this.setState({
            columns: columns,
        });
    }

    private _onColumnRender = (
        item?: any,
        _index?: number | undefined,
        column?: IColumn | undefined
    ): any => {
        const { targetKeys = [], calloutType, onCellDoubleClick } = this.props;
        const value = item[1];
        const metricColumn = column as TableColumn;

        let container = <></>;

        switch (metricColumn.valueType) {
            case ColumnValueType.String:
                container = this._renderStringColumn(
                    value as IMetric[],
                    metricColumn.fieldName!,
                    metricColumn.distinctStr,
                    false,
                    metricColumn.maxWidth
                );

                break;
            case ColumnValueType.StringWithCopyBtn:
                container = this._renderStringColumn(
                    value as IMetric[],
                    metricColumn.fieldName!,
                    metricColumn.distinctStr,
                    true,
                    metricColumn.maxWidth
                );

                break;
            case ColumnValueType.Number:
                container = this._renderNumericColumn(
                    value as IMetric[],
                    metricColumn.fieldName!,
                    metricColumn.maxDecimalPlaces,
                    metricColumn.contrastPolicy,
                    metricColumn.maxWidth
                );

                break;
        }
        if (
            metricColumn.supportsDoubleClick &&
            calloutType === CalloutType.cell
        ) {
            const targetValues = targetKeys.map((key) => item[1][0][key]);
            const targetId = formartTarget(
                `${targetValues.join("-")}-${column?.key}`
            );
            return (
                <div
                    id={targetId}
                    onDoubleClick={(ev) => {
                        onCellDoubleClick &&
                            onCellDoubleClick(
                                item,
                                metricColumn,
                                ev as unknown as Event
                            );
                    }}
                >
                    {container}
                </div>
            );
        }

        return container;
    };

    private _renderNumericColumn(
        data: IMetric[],
        fieldName: string,
        maxDecimalPlaces: number = 1,
        contrastPolicy: NumContrastPolicy = NumContrastPolicy.PositiveRed_NegativeGreen,
        colMaxWidth?: number
    ): any {
        let inlineStyle: React.CSSProperties = {};
        const values = data.map((d) => {
            const fieldVal = d[fieldName as keyof IMetric] as any;
            if (fieldVal === undefined) {
                return NaN;
            }
            if (fieldVal === null) {
                return 0;
            }
            return parseFloat(fieldVal.toString());
        });

        inlineStyle = {
            width: this.props.cellWidth
                ? this.props.cellWidth
                : this._calculateCellWidth(values, colMaxWidth),
        };

        const base = values[data.length - 1];
        const displayText = values.map((val, idx) => {
            const diff = calculateGapNum(
                val,
                base,
                maxDecimalPlaces,
                this._gapUnit
            );
            if (diff === 0) {
                return (
                    <span
                        className="table__item"
                        key={`${fieldName}_${idx}`}
                        style={inlineStyle}
                    >
                        {isNaN(val)
                            ? this._displayNaN()
                            : +val.toFixed(maxDecimalPlaces)}
                    </span>
                );
            } else {
                return (
                    <span
                        className="table__item"
                        key={`${fieldName}_${idx}`}
                        style={inlineStyle}
                    >
                        {isNaN(val)
                            ? this._displayNaN()
                            : +val.toFixed(maxDecimalPlaces)}
                        <span
                            style={{
                                color: this._getColorByContrastPolicy(
                                    val,
                                    base,
                                    contrastPolicy
                                ),
                                fontSize: "10px",
                            }}
                        >
                            {!Number.isNaN(diff) && (
                                <b>
                                    &nbsp;(
                                    {calculateGap(
                                        val,
                                        base,
                                        maxDecimalPlaces,
                                        this._gapUnit
                                    )}
                                    )
                                </b>
                            )}
                        </span>
                    </span>
                );
            }
        });
        return displayText;
    }

    private _displayNaN = () => {
        const { displayNaN = true } = this.props;

        return displayNaN ? "NaN" : "";
    };

    private _renderStringColumn(
        data: IMetric[] | null,
        fieldName: string,
        distinctStr: boolean = false,
        showCopyBtn: boolean = false,
        colMaxWidth?: number
    ): any {
        if (!data) {
            return;
        }

        let values = data.map((d) => {
            return String(d ? d[fieldName as keyof IMetric] : "");
        });

        if (distinctStr) {
            values = [...new Set(values)];
        }

        const cellWidth = this._calculateCellWidth(values, colMaxWidth);
        return this._renderWithTooltip(values, cellWidth, showCopyBtn);
    }

    private _renderWithTooltip(
        values: string[],
        cellWidth: number,
        showCopyBtn: boolean = false
    ) {
        return (
            <TooltipHost
                content={
                    <>
                        {values.map((value, index) => {
                            return <span key={`value_${index}`}>{value}</span>;
                        })}
                    </>
                }
                calloutProps={calloutProps}
                styles={
                    this.props.isCompactStyle ? hostCompactStyles : hostStyles
                }
            >
                <Stack
                    style={{ padding: "0 10px 0 0" }}
                    tokens={{ childrenGap: 10 }}
                    horizontal
                >
                    {values.map((value, index) => {
                        if (showCopyBtn) {
                            return (
                                <Stack horizontal verticalAlign="center">
                                    <CopyToClipboard text={String(value)}>
                                        <IconButton
                                            className="itemId__copy"
                                            iconProps={{ iconName: "Copy" }}
                                            title="copy"
                                            ariaLabel="copy"
                                        />
                                    </CopyToClipboard>
                                    <span
                                        key={`value_${index}`}
                                        style={{
                                            whiteSpace: "nowrap",
                                            textOverflow: "ellipsis",
                                            overflow: "hidden",
                                            width: "60px",
                                        }}
                                    >
                                        {value}
                                    </span>
                                </Stack>
                            );
                        } else {
                            return (
                                <span
                                    key={`value_${index}`}
                                    style={{
                                        width: `${cellWidth}px`,
                                    }}
                                >
                                    {value}
                                </span>
                            );
                        }
                    })}
                </Stack>
            </TooltipHost>
        );
    }

    private _onRenderRow: IDetailsListProps["onRenderRow"] = (props) => {
        const customStyles: Partial<IDetailsRowStyles> = {};
        const { isCompactStyle, targetKeys, calloutType } = this.props;
        const [innerCellHeight, rowHeight] = [20, 24];
        const { prefixIcon = true } = this.props;
        if (props) {
            if (isCompactStyle) {
                customStyles.cell = {
                    height: innerCellHeight,
                    minHeight: innerCellHeight,
                    paddingTop: 1,
                    paddingBottom: 1,
                };
            }
            if (props.itemIndex % 2 === 0) {
                // Every other row renders with a different background color
                customStyles.root = isCompactStyle
                    ? {
                          height: rowHeight,
                          minHeight: rowHeight,
                          backgroundColor: this.props.isDarkTheme
                              ? theme.palette.neutralDark
                              : theme.palette.neutralLighterAlt,
                      }
                    : {
                          backgroundColor: this.props.isDarkTheme
                              ? theme.palette.neutralDark
                              : theme.palette.neutralLighterAlt,
                      };
            } else {
                customStyles.root = isCompactStyle
                    ? {
                          height: rowHeight,
                          minHeight: rowHeight,
                          backgroundColor: this.props.isDarkTheme
                              ? theme.palette.neutralPrimary
                              : theme.palette.white,
                      }
                    : {
                          backgroundColor: this.props.isDarkTheme
                              ? theme.palette.neutralPrimary
                              : theme.palette.white,
                      };
            }
            let newProps = props;
            if (targetKeys && calloutType === CalloutType.row) {
                const targetValues = targetKeys.map(
                    (key) => props.item[0][key]
                );
                const targetId = formartTarget(targetValues.join("-"));
                newProps = { ...props, ...{ id: targetId } };
            }

            return (
                <>
                    {prefixIcon ? (
                        <Stack
                            horizontal
                            verticalAlign="center"
                            styles={customStyles}
                        >
                            {props?.item[1][0].coverage === "100.00%" ||
                            props?.item[1][0].coverage === "--" ? (
                                <FontIcon
                                    aria-label="Compass"
                                    iconName="CompletedSolid"
                                    className={tipIconClass}
                                    style={{
                                        color: "#217346",
                                    }}
                                />
                            ) : (
                                <FontIcon
                                    aria-label="Compass"
                                    iconName="CompletedSolid"
                                    className={tipIconClass}
                                    style={{
                                        color: "transparent",
                                    }}
                                />
                            )}

                            <DetailsRow {...newProps} styles={customStyles} />
                        </Stack>
                    ) : (
                        <DetailsRow {...newProps} styles={customStyles} />
                    )}
                </>
            );
        }
        return null;
    };

    private _calculateCellWidth(
        values: any[],
        colMaxWidth: number | undefined
    ) {
        const defCellWidth = 100;
        const defCompareCount = 1;
        const widthAdjustmentFactor = 20;

        const compareCount =
            values.length === 0 ? defCompareCount : values.length;
        const cellWidth = colMaxWidth
            ? (colMaxWidth - widthAdjustmentFactor) / compareCount
            : defCellWidth;

        return cellWidth;
    }

    private _getColorByContrastPolicy(
        original: number,
        target: number,
        contrastPolicy: NumContrastPolicy = NumContrastPolicy.PositiveRed_NegativeGreen
    ): string | undefined {
        let color = undefined;
        if (contrastPolicy === NumContrastPolicy.PositiveRed_NegativeGreen) {
            if (original > target) {
                color = RGB_Color.Red;
            } else if (original < target) {
                color = RGB_Color.Green;
            }
        } else if (
            contrastPolicy === NumContrastPolicy.PositiveGreen_NegativeRed
        ) {
            if (original > target) {
                color = RGB_Color.Green;
            } else if (original < target) {
                color = RGB_Color.Red;
            }
        }

        return color;
    }

    private _negativeSignChecker(strOfNum: string): string {
        return strOfNum.indexOf("-") === 0 ? strOfNum : "-" + strOfNum;
    }
    private _updateSourceItemsAndFilterRules(
        items: any[],
        filterDict: Map<string | number, any[]>,
        queryString: string
    ): void {
        this.setState({
            filterDict: filterDict,
            displayItems: items,
            queryString: queryString,
        });
    }

    private async _getDisplayItems(): Promise<ITableItem<IMetric>[]> {
        const { evalData } = this.props;
        const { columns, filterDict, queryString } = this.state;
        // filter items
        let toDisplayItems: ITableItem<IMetric>[] = Object.entries(evalData);
        if ((filterDict && filterDict.size > 0) || queryString) {
            toDisplayItems = FilterBar.filterItemsByColumnEnumAndQueryString(
                toDisplayItems,
                columns,
                filterDict,
                queryString
            );
        }

        return toDisplayItems;
    }
    private _onRenderDetailsHeader = (
        props: IDetailsHeaderProps | undefined
    ): JSX.Element => {
        const { prefixIcon = true } = this.props;
        if (props) {
            return (
                <Consumer>
                    {(value) => {
                        return (
                            <>
                                {prefixIcon ? (
                                    <Stack
                                        horizontal
                                        verticalAlign="center"
                                        styles={{
                                            root: {
                                                backgroundColor: value
                                                    ? theme.palette.neutralDark
                                                    : theme.palette.white,
                                            },
                                        }}
                                    >
                                        <FontIcon
                                            aria-label="Compass"
                                            iconName="Completed"
                                            className={tipIconClass}
                                            style={{
                                                color: "transparent",
                                                display: "hidden",
                                            }}
                                        />

                                        <DetailsHeader
                                            {...props}
                                        ></DetailsHeader>
                                    </Stack>
                                ) : (
                                    <DetailsHeader {...props}></DetailsHeader>
                                )}
                            </>
                        );
                    }}
                </Consumer>
            );
        } else {
            return <></>;
        }
    };

    private async _downloadTableAsDocx(
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        columns: TableColumn[],
        displayItems: ITableItem<IMetric>[],
        title: string
    ) {
        const defaultDecimalPlaces = 1;
        const visibleColumns = columns.filter(
            (col) => col.valueType !== ColumnValueType.Placeholder
        );
        const tableHeader = visibleColumns.map(
            (col) =>
                new TableCell({
                    children: [new Paragraph(col.name)],
                })
        );
        const tableBody = displayItems.map((item) =>
            visibleColumns.map((col) => {
                let tableCell: TableCell = new TableCell({
                    children: [new Paragraph("")],
                });
                if (!!col.isKey) {
                    tableCell = new TableCell({
                        children: [new Paragraph(item[0])],
                    });
                } else if (col.fieldName) {
                    const metrics = item[1] as IMetric[];
                    if (col.valueType === ColumnValueType.Number) {
                        const values = metrics.map((metric) => {
                            const fieldVal = metric[
                                col.fieldName as keyof IMetric
                            ] as any;
                            if (fieldVal === undefined) {
                                return NaN;
                            }
                            if (fieldVal === null) {
                                return 0;
                            }
                            return parseFloat(fieldVal.toString());
                        });

                        const maxDecimalPlaces =
                            col.maxDecimalPlaces ?? defaultDecimalPlaces;
                        const base = values[item[1].length - 1];
                        tableCell = new TableCell({
                            children: values.map((val) => {
                                const diff = calculateGapNum(
                                    val,
                                    base,
                                    maxDecimalPlaces,
                                    this._gapUnit
                                );
                                if (diff === 0 || isNaN(diff)) {
                                    return new Paragraph(
                                        isNaN(val)
                                            ? this._displayNaN()
                                            : (+val.toFixed(
                                                  maxDecimalPlaces
                                              )).toString()
                                    );
                                } else {
                                    return new Paragraph({
                                        children: [
                                            new TextRun({
                                                text: isNaN(val)
                                                    ? this._displayNaN()
                                                    : `${+val
                                                          .toFixed(
                                                              maxDecimalPlaces
                                                          )
                                                          .toString()}`,
                                            }),
                                            new TextRun({
                                                text: `(${calculateGap(
                                                    val,
                                                    base,
                                                    maxDecimalPlaces,
                                                    this._gapUnit
                                                )})`,
                                                color: this._getColorByContrastPolicy(
                                                    val,
                                                    base,
                                                    col.contrastPolicy
                                                ),
                                            }),
                                        ],
                                    });
                                }
                            }),
                        });
                    } else {
                        const itemValues = metrics.map((metric) => {
                            const fieldVal = metric[
                                col.fieldName as keyof IMetric
                            ] as any;

                            return fieldVal.toString();
                        });
                        if (!!col.distinctStr) {
                            tableCell = new TableCell({
                                children: [...new Set(itemValues)].map(
                                    (item) => new Paragraph(item)
                                ),
                            });
                        } else {
                            tableCell = new TableCell({
                                children: itemValues.map(
                                    (item) => new Paragraph(item)
                                ),
                            });
                        }
                    }
                }

                return tableCell;
            })
        );

        tableBody.unshift(tableHeader);
        await saveTableCellsToDocx(tableBody, title);
    }
}
