import React from "react";
import "./EntityMetrics.scss";
import {
    //DetailList
    CollapseAllVisibility,
    DetailsHeader,
    DetailsList,
    DetailsListLayoutMode,
    DetailsRow,
    GroupHeader,
    IColumn,
    IDetailsGroupDividerProps,
    IDetailsGroupRenderProps,
    IDetailsHeaderProps,
    IDetailsRowProps,
    IDetailsRowStyles,
    IGroup,
    IGroupHeaderProps,
    SelectionMode,

    //MessageBar
    MessageBar,
    MessageBarType,

    //Others
    ConstrainMode,
    getTheme,
    IChoiceGroupOption,
    Icon,
    Label,
    Stack,
    Toggle,
    TooltipHost,
} from "@fluentui/react";

import {
    CommonView,
    ICommonProps,
    ICommonState,
} from "../Common/CommonMetrics";

import { calculateGap, formatNumberDisplay } from "../../Utils";
import { Guid } from "guid-typescript";
import { DatasetSet, IDataItem, Typename } from "../../DataContract";
import {
    ENTITY_GROUP_CONFIG,
    IEntityUnion,
    ENTITY_COLUMNS,
    DATASET_NAME_SYMBOLS,
    CHOICE_OPTIONS,
    ENTITY_POPUP_COLUMNS,
    DEFAULT_ENTITY_COLUMNS,
} from "./EntityConstants";
import { from } from "linq-to-typescript";
import { ITableConfigs, TableColumn, TableHeader } from "../../Controls";
import _ from "lodash";
import { ExpandCard } from "../../Controls/Common/ExpandCard";
import { ChildArray, GroupList, IGroupResult } from "../../Controls/GroupList";
import { Consumer } from "../../Layout";
import { exportOverviewListData } from "../../Utils/ExportFile";
import { store } from "../../../store";
import { updateStateAction } from "../../../store/reducers/setting";
import { NameSymbols } from "./NameSymbols";
import { FullScreen } from "../Common/FullScreen";
import { NoDataTip } from "../../Controls/NoDataTip";
import { CalloutTable, formartTarget } from "../../Controls/CalloutTable";

enum CalcMethod {
    Sum,
    Avg,
}

enum ChildrenEntityLevel {
    Lv1,
    Lv2,
    Max,
}

enum StatisticsType {
    Standard,
    CrossLang,
}

export interface OcrEntityStateInSession {
    selectLanguage?: string;
}

export interface OcrEntityResult extends IGroupResult {
    entity: string;
    sentenceCount: number;
    wordCount: number;
    correct: number;
    substitueRate: number;
    deleteRate: number;
    insertRate: number;
    error: number;
    sentenceErrorRate: number;
    sentenceDeleteErrorRate: number;
    sentenceSubsErrorRate: number;
    sentenceErrorCount: number;
    id?: string;
    children?: OcrEntityResult[];
    childrenStat?: string;
    statsType?: StatisticsType;
    datasetName?: string;
}

export interface OcrEntityMetrics {
    entity_wer: Array<OcrEntityResult>;
    entity_cer: Array<OcrEntityResult>;
}

interface CalcField {
    fieldName: string;
    calcMethod: CalcMethod;
}

interface EntityResultListSource {
    ocrEntityResult2dArr: OcrEntityResult[][];
    groups: IGroup[];
}

interface IEntityDataItem {
    entity: OcrEntityResult;
    data?: IDataItem<OcrEntityMetrics>;
}

interface IEntityGroup {
    collapsedByDef: boolean;
    displayName: string;
    description?: string;
    entityMembers?: OcrEntityResult[][];
}

interface IDataProp {
    groupId: string;
    dataDesc?: string;
}

interface IState extends ICommonState<OcrEntityMetrics> {
    dataItems: IDataItem<OcrEntityMetrics>[];
    childExpandStat: Map<string, boolean>;
    crossLangChildExpandStat: Map<string, boolean>;
    showDescription?: boolean;
    crossLangTableSwitch?: boolean;
    chooseOption?: string;

    selectedCrossLanguages?: string[];
    selectedEntities?: string[];
    linkData?: any;
    expandItem?: Map<number, boolean>;
    popupTarget?: string;
}

interface IProps extends ICommonProps {
    selectedPivot: string;
    getIntersectLanguages?: (intersectLanguages: string[]) => void;
}

// prettier-ignore
const SPECIFIC_ENTITY_GROUPS_DEFINE: Map<string, IEntityGroup> = new Map<string, IEntityGroup>([
    [ "spacy_",            { displayName: "NER spaCy[Experimental]",            collapsedByDef: true, entityMembers:[],  description:"An Industrial-Strength Natural Language Processing Library IN PYTHON https://spacy.io/", },],
]);

// prettier-ignore
const CALC_FIELDS: Array<CalcField> = [
    { fieldName: "sentenceCount",             calcMethod: CalcMethod.Sum },
    { fieldName: "wordCount",                 calcMethod: CalcMethod.Sum },
    { fieldName: "correct",                   calcMethod: CalcMethod.Avg },
    { fieldName: "substitueRate",             calcMethod: CalcMethod.Avg },
    { fieldName: "deleteRate",                calcMethod: CalcMethod.Avg },
    { fieldName: "insertRate",                calcMethod: CalcMethod.Avg },
    { fieldName: "error",                     calcMethod: CalcMethod.Avg },
    { fieldName: "sentenceErrorRate",         calcMethod: CalcMethod.Avg },
    { fieldName: "sentenceDeleteErrorRate",   calcMethod: CalcMethod.Avg },
    { fieldName: "sentenceSubsErrorRate",     calcMethod: CalcMethod.Avg },
    { fieldName: "sentenceErrorCount",        calcMethod: CalcMethod.Sum },
];

const GENERIC_ENTITY_GROUP_KEY = "generic";

// prettier-ignore
export const METRICS_LVL_OPTIONS: IChoiceGroupOption[] = [
    { key: "lv0",     text: "Level 0: Ignore Space/Punc/Symbol errors", },
    { key: "lv1",     text: "Level 1: Ignore Space errors" },
    { key: "lv2",     text: "Level 2: Default, counting all errors" },
    { key: "lv2_ci",  text: "Level 2 - Case Insensitive: Counting all errors while ignoring error caused by case difference",},
];

const WIDTH_ADJUSTMENT_FACTOR = 20;
const KEY_SEPERATEOR = "|||";
const theme = getTheme();

export class EntityMetricsOverview extends CommonView<
    IProps,
    IState,
    OcrEntityMetrics
> {
    private readonly standardEntityNameTree: Array<IEntityUnion>;
    private readonly lv1ChildEntityNameTree: Array<IEntityUnion>;
    private readonly lv1ChildEntityNames: Array<string>;
    private readonly lv2ChildEntityNames: Array<string>;
    private intersectLanguages: string[];
    private crossLangLv1ChildDictMap: Map<string, OcrEntityResult[]>;
    private crossLangLv2ChildDictMap: Map<string, OcrEntityResult[]>;
    private groupCollapsedStatusMap: Map<string, boolean>;
    private groupRenderProps: IDetailsGroupRenderProps;
    private supportCrossLang: boolean;
    private _tableCount = 0;

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataItems: [],
            showDescription: true,
            crossLangTableSwitch: true,
            childExpandStat: new Map<string, boolean>(),
            crossLangChildExpandStat: new Map<string, boolean>(),
            chooseOption: DATASET_NAME_SYMBOLS.Verticals,
            expandItem: new Map<number, boolean>(),
            selectedEntities: ["Receipt"],
            matchDatasetVersion: true,
            level: "lv2",
        };
        this._onColumnDropDownChange = this._onColumnDropDownChange.bind(this);
        this._onRenderGroupHeader = this._onRenderGroupHeader.bind(this);
        this._onRenderGroupHeaderTitle =
            this._onRenderGroupHeaderTitle.bind(this);
        this._onRenderRow = this._onRenderRow.bind(this);
        this._onRenderRowForLv2Child = this._onRenderRowForLv2Child.bind(this);
        this._onRowItemInvoked = this._onRowItemInvoked.bind(this);
        this._renderItemColumn = this._renderItemColumn.bind(this);
        this.deepLinkHandler = this.deepLinkHandler.bind(this);
        this._onItemInvoked = this._onItemInvoked.bind(this);
        this.crossLangLv1ChildDictMap = new Map<string, OcrEntityResult[]>();
        this.crossLangLv2ChildDictMap = new Map<string, OcrEntityResult[]>();
        this.groupCollapsedStatusMap = new Map<string, boolean>();
        this.supportCrossLang = true;

        this.intersectLanguages = this._getLanguageList(
            this.state.matchDatasetVersion!
        );
        this.groupRenderProps = {
            onRenderHeader: this._onRenderGroupHeader,
        };

        const standardEntityUnionArr = new Array<IEntityUnion>();
        const lv1ChildEntityUnionArr = ENTITY_GROUP_CONFIG.filter((config) => {
            const isMatched = ENTITY_GROUP_CONFIG.some((innerConfig) => {
                return innerConfig.children.includes(config.name);
            });
            if (!isMatched) {
                standardEntityUnionArr.push(_.cloneDeep(config));
            }
            return isMatched;
        });
        this.standardEntityNameTree = standardEntityUnionArr;
        this.lv1ChildEntityNameTree = lv1ChildEntityUnionArr;
        this.lv1ChildEntityNames = standardEntityUnionArr.flatMap(
            (entity) => entity.children
        );
        this.lv2ChildEntityNames = lv1ChildEntityUnionArr.flatMap(
            (entity) => entity.children
        );
    }

    public render() {
        return (
            <>
                {this.props.selectedPivot !== "Overview" ? (
                    <></>
                ) : (
                    <FullScreen>
                        <div className="displayFlex">
                            <NameSymbols
                                onSelectItemKey={(itemKey) =>
                                    this.setState((prevState) => {
                                        return {
                                            expandItem: new Map<
                                                number,
                                                boolean
                                            >(),
                                            chooseOption: itemKey,
                                            selectedCrossLanguages:
                                                this._extractDatasetNameByChooseOption(
                                                    itemKey
                                                ),
                                        };
                                    })
                                }
                            />
                            <div
                                style={{
                                    height: "100%",
                                    overflow: "hidden auto",
                                }}
                            >
                                {this._renderAsTable()}
                            </div>
                        </div>
                    </FullScreen>
                )}
            </>
        );
    }

    public componentWillReceiveProps(nextProps: IProps) {
        super.componentWillReceiveProps(nextProps);
        if (nextProps.selectedPivot === "Overview") {
            store.dispatch(
                updateStateAction({
                    columns: ENTITY_COLUMNS,
                    saveKey: `${
                        this.props.saveSetKey ??
                        `${store.getState().globalReducer.workSpace}_${
                            Typename.EntityMetrics
                        }`
                    }_Overview`,
                    selectedItems: DEFAULT_ENTITY_COLUMNS,
                    levels: METRICS_LVL_OPTIONS,
                })
            );
        }
    }

    componentDidMount() {
        super.componentDidMount();
        store.dispatch(
            updateStateAction({
                columns: ENTITY_COLUMNS,
                saveKey: `${
                    this.props.saveSetKey ??
                    `${store.getState().globalReducer.workSpace}_${
                        Typename.EntityMetrics
                    }`
                }_Overview`,
                selectedItems: DEFAULT_ENTITY_COLUMNS,
                levels: METRICS_LVL_OPTIONS,
                selectedLevel: "lv2",
            })
        );
    }
    exportAction = () => {
        if (this.props.selectedPivot !== "Overview") {
            return;
        }
        const legalCrossDatasetNames = this._extractDatasetNameByChooseOption();
        const items = this._getCurrentData(
            legalCrossDatasetNames,
            this.state.matchDatasetVersion!
        );
        const data = this._prepareExportData(
            items,
            this.state.matchDatasetVersion!
        );
        const opt = CHOICE_OPTIONS.filter(
            (op) => op.key === this.state.chooseOption
        )[0]?.text;
        exportOverviewListData(
            this.exportData.concat(data),
            ENTITY_COLUMNS.filter((col) => col.key !== "childrenStat"),
            `EntityOverviewMetrics_${opt}`
        );
    };

    public componentWillUnmount() {
        super.componentWillUnmount();
        store.dispatch(
            updateStateAction({
                levels: undefined,
            })
        );
    }

    componentDidUpdate(prevProps: ICommonProps, prevState: IState) {
        if (this.props.selectedPivot !== "Overview") {
            return;
        }
        if (
            !_.isEqual(this.props.records, prevProps.records) ||
            this.state.showDescription !== prevState.showDescription ||
            this.state.level !== prevState.level
        ) {
            this.queryMetricsResult();
        }
        if (
            !_.isEqual(prevState.dataItems, this.state.dataItems) ||
            !_.isEqual(
                prevState.matchDatasetVersion,
                this.state.matchDatasetVersion
            )
        ) {
            this.intersectLanguages = this._getLanguageList(
                this.state.matchDatasetVersion!
            );
            const legalCrossDatasetNames =
                this._extractDatasetNameByChooseOption();
            const entitys = this._getEntities(legalCrossDatasetNames);
            let selectedEntities = this.state.selectedEntities || entitys;
            const entitysIncludesSelectEntity = entitys.some((entity) =>
                this.state.selectedEntities?.includes(entity)
            );
            if (!entitysIncludesSelectEntity) {
                selectedEntities = entitys.includes("Receipt")
                    ? ["Receipt"]
                    : entitys.slice(0, 1);
            }

            this.setState(
                {
                    selectedCrossLanguages:
                        this._extractDatasetNameByChooseOption(),
                    selectedEntities: selectedEntities,
                },
                () => {}
            );
        }
    }

    queryMetricsResult() {
        const lvl_str =
            this.state.level === "lv2" ? "" : `_${this.state.level}`;
        let metricsFile = `overall_entities_wer_cer${lvl_str}.json`;
        if (this.state.showDescription) {
            metricsFile = `doc_entities_wer_cer${lvl_str}.json`;
        }
        this._queryMetricsResult(metricsFile);
    }

    _getLanguageList(matchDatasetVersion: boolean): string[] {
        const { dataItems } = this.state;
        const { getIntersectLanguages } = this.props;
        if (dataItems && dataItems.length > 0) {
            const langStatsMap = new Map<string, Array<Boolean>>();
            dataItems.forEach((item) => {
                if (
                    !_.isEmpty(item.metrics) &&
                    item.metrics.entity_wer.length > 0
                ) {
                    const lang = matchDatasetVersion
                        ? item.recordDetail.dataset.displayFullName
                        : item.recordDetail.dataset.displayName;

                    let existStat = langStatsMap.get(lang);
                    if (existStat === undefined) {
                        existStat = new Array<Boolean>(
                            this.props.records.length
                        ).fill(false);
                    }

                    existStat[item.recordIndex] = true;
                    langStatsMap.set(lang, existStat);
                }
            });

            const languages = Array.from(langStatsMap)
                .filter(([_, stat]) => stat.every((s) => s === true))
                .map(([lang, _]) => lang)
                .sort();

            getIntersectLanguages && getIntersectLanguages(languages);
            return languages;
        } else {
            return super._getLanguageList(matchDatasetVersion);
        }
    }

    private _renderAsTable() {
        this._tableCount = 0;
        const {
            crossLangTableSwitch,
            matchDatasetVersion,
            selectedColumns,
            showDescription,
            expandItem,
            popupTarget,
        } = this.state;
        const { alwaysShow } = this.props;
        const columns: TableColumn[] = ENTITY_COLUMNS.filter(
            (value) =>
                value.isIconOnly ||
                selectedColumns?.findIndex((col) => col === value.key) !== -1
        );

        this.intersectLanguages = this._getLanguageList(
            this.state.matchDatasetVersion!
        );
        const legalCrossDatasetNames = this._extractDatasetNameByChooseOption();
        const data = this._prepareRenderData(matchDatasetVersion!);
        const formattedData = this._formatDataForEntityTable(data);

        const crossItems = this._getCurrentData(
            legalCrossDatasetNames,
            matchDatasetVersion!,
            true
        );
        const crossLangData_new = this._prepareRenderCrossLanguageData(
            _.cloneDeep(crossItems)
        );

        const popupData = this._prepareRenderPopupData(_.cloneDeep(crossItems));

        const crossLangTableDataReady =
            (crossLangData_new && crossLangData_new.length > 0) ||
            legalCrossDatasetNames.length > 0;

        if (formattedData) {
            this._tableCount += formattedData.length;
        }

        this.exportData = [];
        if (
            this.supportCrossLang &&
            crossLangData_new &&
            crossLangData_new.length > 0 &&
            crossLangTableSwitch
        ) {
            this.exportData.splice(0, 0, crossLangData_new[0]);
        }

        return (
            <>
                <div className="overview__detail">
                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                        <Toggle
                            label="Show entities under documental scenario only"
                            defaultChecked={showDescription ?? false}
                            inlineLabel
                            onText="On"
                            offText="Off"
                            onChange={(event, checked) => {
                                this.setState({
                                    showDescription: checked,
                                });
                            }}
                        />
                        {this.supportCrossLang && crossLangTableDataReady && (
                            <Toggle
                                label="Show Cross-Language Entity Metrics"
                                defaultChecked={crossLangTableSwitch}
                                inlineLabel
                                onText="On"
                                offText="Off"
                                onChange={(event, checked) => {
                                    this.setState({
                                        crossLangTableSwitch: checked,
                                        popupTarget: undefined,
                                    });
                                }}
                            />
                        )}
                    </Stack>

                    {showDescription! && (
                        <MessageBar
                            onDismiss={() => {
                                this.setState({
                                    showDescription: false,
                                });
                            }}
                            dismissButtonAriaLabel="Close"
                            messageBarType={MessageBarType.info}
                            messageBarIconProps={{
                                iconName: "InfoSolid",
                            }}
                            styles={{
                                root: {
                                    backgroundColor: "#eff6fc",
                                },
                                icon: {
                                    color: "#0078d4",
                                },
                            }}
                        >
                            <b>
                                Entities under documental scenario
                                include:&nbsp;&nbsp;
                            </b>
                            Document | Invoice | Receipt | EyeChart | Table |
                            CharacterBox | BankChecks | TravelerEntryForms |
                            BillsReceipts | Applications | MedicalPrescriptions
                            | Invoices | Contracts | Tables
                        </MessageBar>
                    )}

                    {this.supportCrossLang &&
                        crossLangTableDataReady &&
                        crossLangTableSwitch && (
                            <>
                                <Stack horizontal verticalAlign="center">
                                    <Label title="This table summarizes all language entity metrics on this page">
                                        Cross-Language Entity Metrics
                                    </Label>
                                    {this._renderTableHeader(
                                        legalCrossDatasetNames
                                    )}
                                </Stack>
                                <MessageBar
                                    messageBarType={MessageBarType.info}
                                    messageBarIconProps={{
                                        iconName: "InfoSolid",
                                    }}
                                    styles={{
                                        root: {
                                            backgroundColor: "#eff6fc",
                                            marginTop: "10px",
                                            marginLeft: "5px",
                                            width: "99%",
                                        },
                                        icon: {
                                            color: "#0078d4",
                                        },
                                    }}
                                >
                                    <b>
                                        Double click to check the entity data by
                                        dataset
                                    </b>
                                </MessageBar>
                                <div>
                                    {popupTarget &&
                                        popupData &&
                                        !_.isEmpty(popupData) && (
                                            <CalloutTable
                                                tableTitle={popupTarget}
                                                targetId={formartTarget(
                                                    popupTarget
                                                )}
                                                columns={ENTITY_POPUP_COLUMNS}
                                                evalData={popupData}
                                                message="Double click to check by image entity metrics for given dataset and entity"
                                                highlightKey={[
                                                    "sentenceErrorCount",
                                                    "sentenceErrorRate",
                                                ]}
                                                evalDataCount={
                                                    this.props.records.length
                                                }
                                                onItemInvoked={
                                                    this._calloutItemInvoked
                                                }
                                                onDisMiss={() => {
                                                    this.setState({
                                                        popupTarget: undefined,
                                                    });
                                                }}
                                            />
                                        )}
                                    <Consumer>
                                        {(value) => {
                                            return (
                                                <GroupList
                                                    isDarkTheme={value}
                                                    hiddenTitle
                                                    columns={columns}
                                                    targetKeys={["entity"]}
                                                    evalData={
                                                        crossLangData_new as any
                                                    }
                                                    onItemInvoked={(item) => {
                                                        if (item) {
                                                            const canClick =
                                                                !item.childrens;
                                                            if (
                                                                canClick &&
                                                                item.length > 0
                                                            ) {
                                                                this.setState({
                                                                    popupTarget:
                                                                        (
                                                                            item![0] as OcrEntityResult
                                                                        )
                                                                            .entity,
                                                                });
                                                            }
                                                        }
                                                    }}
                                                    highlightKey={[
                                                        "sentenceErrorCount",
                                                        "sentenceErrorRate",
                                                    ]}
                                                />
                                            );
                                        }}
                                    </Consumer>
                                </div>
                            </>
                        )}
                </div>

                {formattedData &&
                    formattedData.map(([datasetName, tableData], index) => {
                        if (tableData) {
                            const listSource = this._arrangeListSource(
                                datasetName,
                                tableData
                            );

                            const datasetDisplayName =
                                datasetName.slice(0, datasetName.indexOf(":")) +
                                "    Dataset: " +
                                datasetName.slice(
                                    datasetName.indexOf(":") + 1,
                                    datasetName.length
                                );

                            return alwaysShow ? (
                                <div
                                    className="overview__detail"
                                    key={`table_${datasetName}`}
                                >
                                    <Stack
                                        horizontal
                                        tokens={{ childrenGap: 10 }}
                                    >
                                        <Label
                                            style={{
                                                color: this.props.isDarkTheme
                                                    ? theme.palette.white
                                                    : theme.palette.black,
                                            }}
                                        >
                                            {datasetDisplayName}
                                        </Label>
                                    </Stack>

                                    <DetailsList
                                        styles={{
                                            root: { overflowX: "auto" },
                                        }}
                                        columns={columns}
                                        groups={listSource.groups}
                                        groupProps={this.groupRenderProps}
                                        items={listSource.ocrEntityResult2dArr}
                                        onRenderDetailsHeader={
                                            this._onRenderDetailsHeader
                                        }
                                        onRenderItemColumn={
                                            this._renderItemColumn
                                        }
                                        selectionMode={SelectionMode.none}
                                        constrainMode={
                                            ConstrainMode.unconstrained
                                        }
                                        layoutMode={
                                            DetailsListLayoutMode.justified
                                        }
                                        onItemInvoked={this._onItemInvoked}
                                        onRenderRow={this._onRenderRow}
                                    />
                                </div>
                            ) : (
                                <div
                                    className="overview__detail expand"
                                    key={`table_${datasetName}`}
                                >
                                    <ExpandCard
                                        text={datasetDisplayName}
                                        expandAll={
                                            expandItem?.get(index) ??
                                            store.getState().globalReducer
                                                .expandAll
                                        }
                                        onExpandChange={(expand: boolean) => {
                                            expandItem?.set(index, expand);
                                            this.setState(
                                                {
                                                    expandItem: expandItem,
                                                },
                                                () =>
                                                    this._expandCountChange(
                                                        this._tableCount
                                                    )
                                            );
                                        }}
                                        isDarkTheme={this.props.isDarkTheme}
                                    >
                                        <DetailsList
                                            styles={{
                                                root: { overflowX: "auto" },
                                            }}
                                            columns={columns}
                                            groups={listSource.groups}
                                            groupProps={this.groupRenderProps}
                                            items={
                                                listSource.ocrEntityResult2dArr
                                            }
                                            onRenderDetailsHeader={
                                                this._onRenderDetailsHeader
                                            }
                                            onRenderItemColumn={
                                                this._renderItemColumn
                                            }
                                            selectionMode={SelectionMode.none}
                                            constrainMode={
                                                ConstrainMode.unconstrained
                                            }
                                            layoutMode={
                                                DetailsListLayoutMode.justified
                                            }
                                            onItemInvoked={this._onItemInvoked}
                                            onRenderRow={this._onRenderRow}
                                        />
                                    </ExpandCard>
                                </div>
                            );
                        } else {
                            return <NoDataTip>{this.noDataPrompt()}</NoDataTip>;
                        }
                    })}

                {formattedData.length === 0 && (
                    <NoDataTip>{this.noDataPrompt()}</NoDataTip>
                )}
            </>
        );
    }

    noDataPrompt = () => {
        const { chooseOption } = this.state;
        const chooseOp = CHOICE_OPTIONS.find((op) => op.key === chooseOption);

        return `No ${chooseOp?.text} Data Generated`;
    };

    private _grouponItemInvoked = (item?: any, index?: number, ev?: Event) => {
        if (item) {
            const canClick = !item.childrens;
            if (canClick && item.length > 0) {
                this.setState({
                    popupTarget: (item![0] as OcrEntityResult).entity,
                });
            }
        }
    };

    private _calloutItemInvoked = (
        item?: OcrEntityResult[],
        index?: number,
        ev?: Event
    ) => {
        const values = item as any[];
        if (values?.length >= 2) {
            const fieldMetricsArr = values[1][0] as OcrEntityResult;
            if (fieldMetricsArr) {
                let linkData: { [key: string]: string | undefined } = {};
                linkData.toSelectLanguage = fieldMetricsArr.datasetName;
                linkData.toSelectEntity = this.state.popupTarget;

                this.deepLinkHandler("ByImage", linkData);
            }
        }
    };

    private _getEntities = (datasetNames: string[]) => {
        if (this.state.chooseOption === DATASET_NAME_SYMBOLS.Verticals) {
            const entitise = Array.from(
                new Set(
                    datasetNames.map(
                        (datasetName) => datasetName.split(":")[0].split("_")[1]
                    )
                )
            );

            let notReceiptEntity = entitise.filter(
                (entity) => !entity.includes("Receipt")
            );
            if (notReceiptEntity.length !== entitise.length) {
                notReceiptEntity = notReceiptEntity.concat(["Receipt"]);
            }

            return notReceiptEntity;
        }
        return [];
    };

    private _renderTableHeader(datasetNames: string[]): JSX.Element {
        const entitys = this._getEntities(datasetNames);
        let selectedEntities = this.state.selectedEntities || entitys;
        const entitysIncludesSelectEntity = entitys.some((entity) =>
            this.state.selectedEntities?.includes(entity)
        );
        if (!entitysIncludesSelectEntity) {
            selectedEntities = entitys.includes("Receipt")
                ? ["Receipt"]
                : entitys.slice(0, 1);
        }
        let selectedCrossLanguages =
            this.state.selectedCrossLanguages || datasetNames;
        if (
            !_.isEqual(
                selectedEntities,
                this._getEntities(selectedCrossLanguages)
            ) &&
            this.state.chooseOption === DATASET_NAME_SYMBOLS.Verticals
        ) {
            selectedCrossLanguages = datasetNames.filter((datasetName) =>
                selectedEntities.some((entity) =>
                    datasetName.split(":")[0].split("_")[1].includes(entity)
                )
            );
        }
        let imageConfigurations: ITableConfigs = [
            {
                key: "languages",
                text: "Datasets Calculated In Cross Language Table:",
                options: datasetNames,
                multiSelect: true,
                selectedKeys: selectedCrossLanguages,
                onChange: (language) => {
                    let selectedLangs = selectedCrossLanguages;
                    if (language.selected) {
                        if (
                            selectedLangs &&
                            !selectedLangs.includes(language.key)
                        ) {
                            selectedLangs.push(language.key);
                            this.setState({
                                selectedCrossLanguages: selectedLangs,
                                selectedEntities:
                                    this.state.chooseOption ===
                                    DATASET_NAME_SYMBOLS.Verticals
                                        ? this._getEntities(selectedLangs)
                                        : ["Receipt"],
                            });
                        }
                    } else {
                        if (
                            selectedLangs &&
                            selectedLangs.includes(language.key)
                        ) {
                            selectedLangs = selectedLangs.filter(
                                (lang) => lang !== language.key
                            );
                            this.setState({
                                selectedCrossLanguages: selectedLangs,
                                selectedEntities:
                                    this.state.chooseOption ===
                                    DATASET_NAME_SYMBOLS.Verticals
                                        ? this._getEntities(selectedLangs)
                                        : ["Receipt"],
                            });
                        }
                    }
                },
            },
        ];

        const verticalsConfigurations: ITableConfigs = [
            {
                key: "entity",
                text: "Entity filter:",
                options: entitys,
                multiSelect: true,
                selectedKeys: selectedEntities,
                onChange: (entity) => {
                    let selectedEntitys = selectedEntities;
                    if (entity.selected) {
                        if (
                            selectedEntitys &&
                            !selectedEntitys.includes(entity.key)
                        ) {
                            selectedEntitys.push(entity.key);
                            this.setState({
                                selectedEntities: selectedEntitys,
                                selectedCrossLanguages: datasetNames.filter(
                                    (datasetName) =>
                                        selectedEntitys.some((entity) =>
                                            datasetName
                                                .split(":")[0]
                                                .split("_")[1]
                                                .includes(entity)
                                        )
                                ),
                            });
                        }
                    } else {
                        if (
                            selectedEntitys &&
                            selectedEntitys.includes(entity.key)
                        ) {
                            selectedEntitys = selectedEntitys.filter(
                                (lang) => lang !== entity.key
                            );
                            this.setState({
                                selectedEntities: selectedEntitys,
                                selectedCrossLanguages: datasetNames.filter(
                                    (datasetName) =>
                                        selectedEntitys.some((entity) =>
                                            datasetName
                                                .split(":")[0]
                                                .split("_")[1]
                                                .includes(entity)
                                        )
                                ),
                            });
                        }
                    }
                },
            },
        ];
        if (this.state.chooseOption === DATASET_NAME_SYMBOLS.Verticals) {
            imageConfigurations = imageConfigurations.concat(
                verticalsConfigurations
            );
        }
        return <TableHeader options={imageConfigurations} />;
    }

    private _onItemInvoked(item: any, index?: number | undefined): void {
        const values = item as any[];
        if (values?.length >= 1) {
            const fieldMetricsArr = values[0] as OcrEntityResult;
            if (fieldMetricsArr) {
                let linkData: { [key: string]: string | undefined } = {};
                linkData.toSelectLanguage = fieldMetricsArr.datasetName;
                linkData.toSelectEntity = fieldMetricsArr.entity;

                this.deepLinkHandler("ByImage", linkData);
            }
        }
    }

    private _onRenderDetailsHeader(
        props: IDetailsHeaderProps | undefined
    ): JSX.Element {
        if (props) {
            props.collapseAllVisibility = CollapseAllVisibility.hidden;
            return (
                <DetailsHeader
                    {...props}
                    styles={{ root: { padding: "0px 0px 0px 38px" } }}
                ></DetailsHeader>
            );
        } else {
            return <></>;
        }
    }

    private _onRenderRow(rowProps: IDetailsRowProps | undefined): JSX.Element {
        if (rowProps !== undefined) {
            const [
                customStyles,
                isExpanded,
                hasChildren,
                columns,
                childrenEntity2dArr,
            ] = this._renderRowByChildrenLv(rowProps, ChildrenEntityLevel.Lv1);
            return (
                <>
                    <DetailsRow {...rowProps} styles={customStyles} />
                    {isExpanded && hasChildren && (
                        <DetailsList
                            columns={columns}
                            items={childrenEntity2dArr}
                            onRenderItemColumn={this._renderItemColumn}
                            selectionMode={SelectionMode.none}
                            constrainMode={ConstrainMode.unconstrained}
                            layoutMode={DetailsListLayoutMode.justified}
                            onRenderRow={this._onRenderRowForLv2Child}
                            onItemInvoked={this._onItemInvoked}
                        />
                    )}
                </>
            );
        } else {
            return <></>;
        }
    }

    private _onRenderRowForLv2Child(
        rowProps: IDetailsRowProps | undefined
    ): JSX.Element {
        if (rowProps !== undefined) {
            const [
                customStyles,
                isExpanded,
                hasChildren,
                columns,
                childrenEntity2dArr,
            ] = this._renderRowByChildrenLv(rowProps, ChildrenEntityLevel.Lv2);
            return (
                <>
                    <DetailsRow {...rowProps} styles={customStyles} />
                    {isExpanded && hasChildren && (
                        <DetailsList
                            columns={columns}
                            items={childrenEntity2dArr}
                            onRenderItemColumn={this._renderItemColumn}
                            constrainMode={ConstrainMode.unconstrained}
                            selectionMode={SelectionMode.none}
                            layoutMode={DetailsListLayoutMode.justified}
                            onItemInvoked={this._onItemInvoked}
                        />
                    )}
                </>
            );
        } else {
            return <></>;
        }
    }

    private _onRowItemInvoked(item?: any, index?: number, ev?: Event) {
        const items = item as Array<OcrEntityResult>;
        if (items?.length > 0) {
            const [rowId, statsType] = this._getRowItemsMetaInfo(items);
            const isCrossLangType =
                statsType != null && statsType === StatisticsType.CrossLang;

            let expandStat = isCrossLangType
                ? this.state.crossLangChildExpandStat
                : this.state.childExpandStat;

            if (expandStat.has(rowId)) {
                const stat = expandStat.get(rowId);
                expandStat.set(rowId, !stat);
            } else {
                expandStat.set(rowId, true);
            }

            if (isCrossLangType) {
                this.setState({
                    crossLangChildExpandStat: expandStat,
                });
            } else {
                this.setState({
                    childExpandStat: expandStat,
                });
            }
        }
    }

    private _onRenderGroupHeader(
        props: IDetailsGroupDividerProps | undefined
    ): JSX.Element {
        if (props?.group?.key === GENERIC_ENTITY_GROUP_KEY) {
            return <></>;
        } else {
            if (props) {
                let groupData: IDataProp | undefined;
                if (props.group) {
                    groupData = props.group.data as IDataProp;
                    const isCollapsed = this.groupCollapsedStatusMap.get(
                        groupData.groupId
                    );

                    if (isCollapsed !== undefined) {
                        props.group.isCollapsed = isCollapsed;
                    }
                }
                const baseOnToggleCollapse = props.onToggleCollapse;
                props.onRenderTitle = this._onRenderGroupHeaderTitle;
                props.onToggleCollapse = (group: IGroup) => {
                    baseOnToggleCollapse!(group);
                    if (groupData) {
                        this.groupCollapsedStatusMap.set(
                            groupData.groupId,
                            group.isCollapsed!
                        );
                    }
                };
            }

            return <GroupHeader {...props}></GroupHeader>;
        }
    }

    private _onRenderGroupHeaderTitle(
        titleProps: IGroupHeaderProps | undefined
    ): JSX.Element {
        let descStr = "";
        if (titleProps?.group?.data) {
            const dataProp = titleProps.group.data as IDataProp;
            if (dataProp.dataDesc) {
                descStr = dataProp.dataDesc;
            }
        }

        return (
            <>
                <span>{titleProps?.group?.name}</span>
                {descStr !== "" && (
                    <span style={{ marginLeft: "20px", cursor: "help" }}>
                        <TooltipHost content={descStr}>
                            <Icon iconName="Comment" />
                        </TooltipHost>
                    </span>
                )}
            </>
        );
    }

    private _renderItemColumn(
        item: OcrEntityResult[],
        index?: number,
        column?: IColumn
    ) {
        const key = column!.fieldName;
        if (key === "entity") {
            if (item && item.length > 0) {
                const avlOcrEntity = item.find(
                    (result) =>
                        result &&
                        result[key as keyof OcrEntityResult]?.toString() !==
                            "NaN"
                );

                if (avlOcrEntity) {
                    return (
                        <span className="table__item">
                            {avlOcrEntity[key as keyof OcrEntityResult]}
                        </span>
                    );
                }
            }

            return <></>;
        } else if (key === "childrenStat") {
            const childrenStat = item[0]?.childrenStat;
            if (childrenStat && childrenStat !== "") {
                return (
                    <span
                        className="table__item"
                        key={`${key}_stat`}
                        style={{ cursor: "pointer" }}
                        onClick={(
                            event: React.MouseEvent<
                                Element,
                                globalThis.MouseEvent
                            >
                        ) => {
                            this._onRowItemInvoked(item);
                        }}
                    >
                        {childrenStat}
                    </span>
                );
            } else {
                return <></>;
            }
        } else {
            const cellClass = [
                "sentenceErrorCount",
                "sentenceErrorRate",
            ].includes(key as string)
                ? "table__item_highlight"
                : "table__item";
            const values = item.map((v) => {
                return v[key as keyof OcrEntityResult] as number;
            });
            const base = values[values.length - 1];

            const defCellWidth = 100;
            const defCompareCount = 1;
            const compareCount =
                values.length === 0 ? defCompareCount : values.length;
            const cellWidth = column?.maxWidth
                ? (column.maxWidth - WIDTH_ADJUSTMENT_FACTOR) / compareCount
                : defCellWidth;
            const cellStyle: React.CSSProperties = {
                padding: "2px",
                width: `${cellWidth}px`,
                textAlign: "left",
            };
            const displayText = values.map((val, idx) => {
                if (val === base) {
                    return (
                        <span
                            className={cellClass}
                            style={cellStyle}
                            key={`${key}_${idx}`}
                        >
                            {formatNumberDisplay(val)}
                        </span>
                    );
                } else {
                    return (
                        <span
                            className={cellClass}
                            style={cellStyle}
                            key={`${key}_${idx}`}
                        >
                            {val}
                            <span
                                className={cellClass}
                                style={{
                                    color: val > base ? "red" : "green",
                                    fontSize: "10px",
                                    position: "relative",
                                    marginTop: "0px",
                                }}
                            >
                                <b>&nbsp;({calculateGap(val, base, 2)})</b>
                            </span>
                        </span>
                    );
                }
            });

            return displayText;
        }
    }

    private _renderRowByChildrenLv(
        rowProps: IDetailsRowProps,
        childrenEntityLv: ChildrenEntityLevel
    ): [
        Partial<IDetailsRowStyles>,
        boolean,
        boolean,
        IColumn[],
        OcrEntityResult[][]
    ] {
        const { selectedColumns } = this.state;
        const columns: IColumn[] = ENTITY_COLUMNS.filter(
            (value) =>
                value.isIconOnly ||
                selectedColumns?.findIndex((col) => col === value.key) !== -1
        );

        let childrenEntity2dArr: Array<OcrEntityResult[]> = [];
        const rowItems = rowProps?.item as Array<OcrEntityResult>;
        const [rowId, statsType] = this._getRowItemsMetaInfo(rowItems);
        const isCrossLangType =
            statsType != null && statsType === StatisticsType.CrossLang;

        childrenEntity2dArr = isCrossLangType
            ? this._constructCrossLangChildrenEntityListModel(
                  rowItems,
                  childrenEntityLv
              )
            : this._constructChildrenEntityListModel(rowItems);

        const isExpanded = isCrossLangType
            ? this.state.crossLangChildExpandStat.get(rowId) === true
            : this.state.childExpandStat.get(rowId) === true;

        const hasChildren = childrenEntity2dArr?.length > 0;
        if (hasChildren) {
            rowItems[0].childrenStat = isExpanded ? "▼" : "▶";
        }
        const customStyles: Partial<IDetailsRowStyles> = {};
        if (rowProps.itemIndex % 2 === 0) {
            // Every other row renders with a different background color
            customStyles.root = {
                backgroundColor: this.props.isDarkTheme
                    ? theme.palette.neutralDark
                    : theme.palette.neutralLighterAlt,
            };
        } else {
            customStyles.root = {
                backgroundColor: this.props.isDarkTheme
                    ? theme.palette.neutralPrimary
                    : theme.palette.white,
            };
        }

        return [
            customStyles,
            isExpanded,
            hasChildren,
            columns,
            childrenEntity2dArr,
        ];
    }

    private deepLinkHandler(key: string, linkData: any): void {
        if (this.props.setSelectedSubPivot) {
            this.props.setSelectedSubPivot(key, linkData);
        }
    }

    //#endregion

    //#region Calculate and construct data
    private _prepareRenderData(matchDatasetVersion: boolean) {
        // find all datasets
        const datasets = Array.from(
            new DatasetSet(this.props.records.flatMap((r) => r.getDatasets()))
        );

        const allDatasetNames = Array.from(
            new Set(
                datasets.map((dataset) =>
                    matchDatasetVersion
                        ? dataset.displayFullName
                        : dataset.displayName
                )
            )
        );

        const datasetNames = this._filterDatasetsForReport(allDatasetNames);
        const legalDatasetNames = this._extractDatasetNameByChooseOption(
            this.state.chooseOption,
            datasetNames
        );

        const data = legalDatasetNames.map((datasetName) => {
            const items = this.props.records.map((_, recordIndex) => {
                const item = this.state.dataItems.find(
                    (item) =>
                        item.recordIndex === recordIndex &&
                        (matchDatasetVersion
                            ? item.recordDetail.dataset.displayFullName ===
                              datasetName
                            : item.recordDetail.dataset.displayName ===
                              datasetName)
                );

                return item;
            });

            const entities = Array.from(
                new Set(
                    items.flatMap((item) => {
                        const wers = item?.metrics.entity_wer;
                        return wers ? wers.map((wer) => wer.entity) : [];
                    })
                )
            );
            const groupData = entities.map((category) => {
                // retrieve wer data
                const werItems = items.map((item) => {
                    let wer: OcrEntityResult | undefined;
                    if (item !== undefined) {
                        const { metrics } = item;
                        const { recordDetail } = item;
                        if (metrics && metrics.entity_wer) {
                            wer = metrics.entity_wer.find(
                                (wer) => wer.entity === category
                            );
                            if (wer) {
                                wer.datasetName =
                                    recordDetail.dataset.displayFullName;
                            }
                        }
                    }

                    if (wer === undefined) {
                        wer = {
                            entity: category,
                            sentenceCount: NaN,
                            wordCount: NaN,
                            correct: NaN,
                            substitueRate: NaN,
                            deleteRate: NaN,
                            insertRate: NaN,
                            error: NaN,
                            sentenceErrorRate: NaN,
                            sentenceDeleteErrorRate: NaN,
                            sentenceSubsErrorRate: NaN,
                            sentenceErrorCount: NaN,
                            datasetName: datasetName,
                        };
                    } else {
                        if (!wer.id) {
                            wer.id = Guid.create().toString();
                        }

                        if (wer?.children && wer.children.length > 0) {
                            wer.children = wer.children.map((child) => {
                                if (!child.id) {
                                    child.id = Guid.create().toString();
                                }
                                return child;
                            });
                        }
                    }

                    return {
                        data: item,
                        entity: wer,
                    } as IEntityDataItem;
                });
                return [category, werItems] as [string, IEntityDataItem[]];
            });

            return [datasetName, groupData] as [
                string,
                [string, IEntityDataItem[]][]
            ];
        });

        return data.filter(([_, groupData]) => groupData.length > 0);
    }

    private _prepareExportData(
        all_items: IDataItem<OcrEntityMetrics>[],
        matchDatasetVersion: boolean
    ) {
        // find all datasets
        const datasets = Array.from(
            new DatasetSet(this.props.records.flatMap((r) => r.getDatasets()))
        );

        const allDatasetNames = Array.from(
            new Set(
                datasets.map((dataset) =>
                    matchDatasetVersion
                        ? dataset.displayFullName
                        : dataset.displayName
                )
            )
        );

        const datasetNames = this._filterDatasetsForReport(allDatasetNames);
        const legalDatasetNames = this._extractDatasetNameByChooseOption(
            this.state.chooseOption,
            datasetNames
        );

        const data = legalDatasetNames.map((datasetName) => {
            const items = this.props.records.map((_, recordIndex) => {
                const item = all_items.find(
                    (item) =>
                        item.recordIndex === recordIndex &&
                        (matchDatasetVersion
                            ? item.recordDetail.dataset.displayFullName ===
                              datasetName
                            : item.recordDetail.dataset.displayName ===
                              datasetName)
                );

                return item;
            });

            const groupEntity = this._prepareRenderGroupData(
                items.filter((v) => v) as IDataItem<OcrEntityMetrics>[]
            );

            const entities = Array.from(
                new Set(
                    items.flatMap((item) => {
                        const wers = item?.metrics.entity_wer;
                        return wers ? wers.map((wer) => wer.entity) : [];
                    })
                )
            );

            const groupData = entities.map((language) => {
                const result: OcrEntityResult[] = [];
                this.props.records.forEach((r, recordIndex) => {
                    const items = all_items.filter(
                        (item) =>
                            item.recordIndex === recordIndex &&
                            (matchDatasetVersion
                                ? item.recordDetail.dataset.displayFullName ===
                                  datasetName
                                : item.recordDetail.dataset.displayName ===
                                  datasetName)
                    );

                    const language_items = items
                        .map((item) => item.metrics?.entity_wer)
                        .filter((value) => value)
                        .flatMap((entity_wer) => entity_wer)
                        .filter((entity) => entity.entity === language);

                    const entity_wers = language_items.map((item) => {
                        const entity = this._dataCalcInEntityResult_new(
                            language,
                            item
                        );

                        return entity;
                    });
                    if (entity_wers.length > 0) {
                        let ocrEntityResult = _.cloneDeep(entity_wers[0]);
                        for (
                            let index = 1;
                            index < entity_wers.length;
                            index++
                        ) {
                            const existEntity = entity_wers[index];
                            ocrEntityResult = this._sumEntitiesData(
                                ocrEntityResult,
                                existEntity
                            );
                        }
                        const calcOcrEntityResult =
                            this._calcTheOverallAverageRate(ocrEntityResult);

                        result.push(calcOcrEntityResult);
                    } else {
                        const ocrEntity = {
                            entity: language,
                            sentenceCount: NaN,
                            wordCount: NaN,
                            correct: NaN,
                            substitueRate: NaN,
                            deleteRate: NaN,
                            insertRate: NaN,
                            error: NaN,
                            sentenceErrorRate: NaN,
                            sentenceDeleteErrorRate: NaN,
                            sentenceSubsErrorRate: NaN,
                            sentenceErrorCount: NaN,
                        };
                        result.push(ocrEntity);
                    }
                });

                return result;
            });
            return [datasetName, this._groupData(groupData, groupEntity)] as [
                string,
                OcrEntityResult[][]
            ];
        });

        return data.filter(([_, groupData]) => groupData.length > 0);
    }

    private _getCurrentData = (
        allDatasetNames: string[],
        matchDatasetVersion: boolean,
        isCrossLanguage: boolean = false
    ) => {
        const datasetNames = this._filterDatasetsForReport(allDatasetNames);
        const legalDatasetNames = this._extractDatasetNameByChooseOption(
            this.state.chooseOption,
            datasetNames
        );

        let all_items = this.props.records
            .flatMap((_, recordIndex) => {
                const item = this.state.dataItems.filter(
                    (item) => item.recordIndex === recordIndex
                );
                return item;
            })
            .filter((item) =>
                legalDatasetNames.includes(
                    matchDatasetVersion
                        ? item.recordDetail.dataset.displayFullName
                        : item.recordDetail.dataset.displayName
                )
            );

        if (this.state.selectedCrossLanguages && isCrossLanguage) {
            let selectedCrossLanguages = this.state.selectedCrossLanguages;
            if (
                !_.isEqual(
                    this.state.selectedEntities,
                    this._getEntities(selectedCrossLanguages)
                ) &&
                this.state.chooseOption === DATASET_NAME_SYMBOLS.Verticals
            ) {
                selectedCrossLanguages = legalDatasetNames.filter(
                    (datasetName) =>
                        this.state.selectedEntities?.some((entity) =>
                            datasetName
                                .split(":")[0]
                                .split("_")[1]
                                .includes(entity)
                        )
                );
            }
            all_items = all_items.filter((item) =>
                selectedCrossLanguages?.includes(
                    matchDatasetVersion
                        ? item.recordDetail.dataset.displayFullName
                        : item.recordDetail.dataset.displayName
                )
            );
        }

        return all_items;
    };

    private _prepareRenderPopupData = (
        all_items: IDataItem<OcrEntityMetrics>[]
    ) => {
        const { popupTarget, matchDatasetVersion } = this.state;
        if (!popupTarget || all_items.length === 0) {
            return undefined;
        }
        const items = all_items.filter(
            (item) =>
                !_.isEmpty(item.metrics) &&
                item.metrics.entity_wer.some(
                    (entity) => entity.entity === popupTarget
                )
        );
        const datasets = Array.from(
            new Set(
                items.map((item) =>
                    matchDatasetVersion
                        ? item.recordDetail.dataset.displayFullName
                        : item.recordDetail.dataset.displayName
                )
            )
        );

        const groupData = datasets.map((datasetName) => {
            const result = this.props.records.map((r, recordIndex) => {
                const items = all_items
                    .filter((item) => item.recordIndex === recordIndex)
                    .map((item) => item.metrics.entity_wer)
                    .filter((value) => value)
                    .flatMap((entity) => entity)
                    .filter(
                        (entity) =>
                            entity.entity === popupTarget &&
                            entity.datasetName === datasetName
                    );
                if (items.length > 0) {
                    let entity = items[0];
                    entity.datasetName = datasetName;

                    return entity;
                } else {
                    return undefined;
                }
            });

            return result;
        });
        const result: any = {};
        const filterData = groupData.filter((data) =>
            data.every((d) => d !== undefined)
        );
        filterData.forEach((value, index) => {
            result[index] = value;
        });

        return result;
    };

    private _prepareRenderCrossLanguageData = (
        all_items: IDataItem<OcrEntityMetrics>[]
    ) => {
        if (all_items.length === 0) {
            return undefined;
        }

        const groupEntity = this._prepareRenderGroupData(all_items);

        const allLanguage = Array.from(
            new Set(
                all_items
                    .map((item) => item.metrics.entity_wer)
                    .filter((value) => value)
                    .flatMap((entityWer) => entityWer)
                    .map((entity) => entity.entity)
            )
        );

        const data = ["crosslanguage"].map((name) => {
            const groupData = allLanguage.map((language) => {
                const result: OcrEntityResult[] = [];
                this.props.records.forEach((r, recordIndex) => {
                    const items = all_items.filter(
                        (item) => item.recordIndex === recordIndex
                    );

                    const language_items = items
                        .map((item) => item.metrics?.entity_wer)
                        .filter((value) => value)
                        .flatMap((entity_wer) => entity_wer)
                        .filter((entity) => entity.entity === language);

                    const entity_wers = language_items.map((item) => {
                        const entity = this._dataCalcInEntityResult_new(
                            language,
                            item
                        );

                        return entity;
                    });
                    if (entity_wers.length > 0) {
                        let ocrEntityResult = _.cloneDeep(entity_wers[0]);
                        for (
                            let index = 1;
                            index < entity_wers.length;
                            index++
                        ) {
                            const existEntity = entity_wers[index];
                            ocrEntityResult = this._sumEntitiesData(
                                ocrEntityResult,
                                existEntity
                            );
                        }
                        const calcOcrEntityResult =
                            this._calcTheOverallAverageRate(ocrEntityResult);

                        result.push(calcOcrEntityResult);
                    } else {
                        const ocrEntity = {
                            entity: language,
                            sentenceCount: NaN,
                            wordCount: NaN,
                            correct: NaN,
                            substitueRate: NaN,
                            deleteRate: NaN,
                            insertRate: NaN,
                            error: NaN,
                            sentenceErrorRate: NaN,
                            sentenceDeleteErrorRate: NaN,
                            sentenceSubsErrorRate: NaN,
                            sentenceErrorCount: NaN,
                        };
                        result.push(ocrEntity);
                    }
                });

                return result;
            });
            return [name, this._groupData(groupData, groupEntity)] as [
                string,
                OcrEntityResult[][]
            ];
        });
        return data.filter(([_, groupData]) => groupData.length > 0);
    };

    private _prepareRenderGroupData = (
        all_items: IDataItem<OcrEntityMetrics>[]
    ) => {
        const groupKey = ENTITY_GROUP_CONFIG.map((config) => {
            const keys: string[] = [];
            this._getChildKeys(config, keys);
            return { key: config.name, value: keys };
        });

        const groupData: any = [];
        groupKey.forEach((g_k) => {
            const result: OcrEntityResult[] = [];
            this.props.records.forEach((r, recordIndex) => {
                const items = all_items.filter(
                    (item) => item.recordIndex === recordIndex
                );

                const language_items = items
                    .map((item) => item.metrics?.entity_wer)
                    .filter((value) => value)
                    .flatMap((entity_wer) => entity_wer)
                    .filter((entity) => g_k.value.includes(entity.entity));

                const entity_wers = language_items.map((item) => {
                    const entity = this._dataCalcInEntityResult_new(
                        g_k.key,
                        item
                    );

                    return entity;
                });

                if (entity_wers.length > 0) {
                    let ocrEntityResult = _.cloneDeep(entity_wers[0]);
                    for (let index = 1; index < entity_wers.length; index++) {
                        const existEntity = entity_wers[index];
                        ocrEntityResult = this._sumEntitiesData(
                            ocrEntityResult,
                            existEntity
                        );
                    }
                    ocrEntityResult.childrenStatKey = g_k.key;
                    const calcOcrEntityResult =
                        this._calcTheOverallAverageRate(ocrEntityResult);

                    result.push(calcOcrEntityResult);
                }
            });

            if (result.length > 0) {
                groupData.push({ key: g_k.key, value: result });
            }
        });

        return groupData;
    };

    private _getChildKeys = (entityUnion: IEntityUnion, keys: string[]) => {
        const un = ENTITY_GROUP_CONFIG.filter((config) =>
            entityUnion.children.includes(config.name)
        );
        entityUnion.children.forEach((key) => keys.push(key));

        un.forEach((u) => this._getChildKeys(u, keys));
    };

    private _groupData = (
        groupData: OcrEntityResult[][],
        groupEntity: { key: string; value: OcrEntityResult[] }[]
    ) => {
        const groupKeys = ENTITY_GROUP_CONFIG.map(
            (config) => config.children
        ).flatMap((key) => key);
        const firstFloorKeys = ENTITY_GROUP_CONFIG.filter(
            (config) => !groupKeys.includes(config.name)
        ).map((c) => c.name);
        let result: ChildArray<any> = [];
        const groupKeyData = groupData.filter((items) => {
            return items.some((item) => groupKeys.includes(item.entity));
        });
        const otherData = groupData.filter((items) => {
            return items.some((item) => !groupKeys.includes(item.entity));
        });

        if (otherData.length > 0) {
            result = result.concat(otherData);
        }

        const dataObj: any = {};
        ENTITY_GROUP_CONFIG.forEach((config) => {
            const configData = groupKeyData.filter((items) => {
                return items.some((item) =>
                    config.children.includes(item.entity)
                );
            });
            if (configData.length > 0) {
                dataObj[config.name] = configData;
            }
        });

        groupEntity.forEach((entity) => {
            const child = dataObj[entity.key];
            const childEntity = entity.value as ChildArray<OcrEntityResult>;
            childEntity.childrens = child;

            const un = ENTITY_GROUP_CONFIG.filter(
                (config) => config.name === entity.key
            );
            if (un.length > 0) {
                un[0].children.forEach((key) => {
                    const child = groupEntity
                        .filter((e) => e.key === key)
                        .map((e) => e.value);
                    if (child.length > 0) {
                        if (childEntity.childrens) {
                            childEntity.childrens =
                                childEntity.childrens.concat(child);
                        } else {
                            childEntity.childrens = child;
                        }
                    }
                });
            }
        });

        const groupResutl = groupEntity
            .filter((entity) => firstFloorKeys.includes(entity.key))
            .map((en) => en.value);
        if (groupResutl.length > 0) {
            result = result.concat(groupResutl);
        }
        return result;
    };

    private _dataCalcInEntityResult_new(
        entityName: string,
        entity: OcrEntityResult
    ): OcrEntityResult {
        return {
            entity: entityName,
            sentenceCount: entity.sentenceCount,
            wordCount: entity.wordCount,
            correct: entity.wordCount * entity.correct,
            substitueRate: entity.wordCount * entity.substitueRate,
            deleteRate: entity.wordCount * entity.deleteRate,
            insertRate: entity.wordCount * entity.insertRate,
            error: entity.wordCount * entity.error,
            sentenceErrorRate: entity.sentenceCount * entity.sentenceErrorRate,
            sentenceDeleteErrorRate:
                entity.sentenceCount * entity.sentenceDeleteErrorRate,
            sentenceSubsErrorRate:
                entity.sentenceCount * entity.sentenceSubsErrorRate,
            sentenceErrorCount: entity.sentenceErrorCount,
        };
    }

    private _formatDataForEntityTable(
        data: Array<[string, [string, IEntityDataItem[]][]]>
    ): Array<[string, OcrEntityResult[][]]> {
        const formatData = data.map(([datasetName, groupData]) => {
            const tableData = groupData.map(([_, entityItems]) => {
                return entityItems.map((entityItem) => entityItem.entity);
            });
            return [datasetName, tableData] as [string, OcrEntityResult[][]];
        });

        const reformatData = formatData.map((currentVal) => {
            const dataset = currentVal[0];
            const ocrEntityResult2dArr = currentVal[1];
            const tableDataMap = new Map<string, OcrEntityResult[]>();
            if (ocrEntityResult2dArr && ocrEntityResult2dArr.length > 0) {
                ocrEntityResult2dArr.forEach((entityHorizontalArr) => {
                    const sampleEntity = entityHorizontalArr.find(
                        (entity) => entity.entity
                    );

                    if (sampleEntity) {
                        const unfoldEntities = entityHorizontalArr.map(
                            (ocrEntityResult, colIndex) => {
                                let ocrEntityResultCopy =
                                    _.cloneDeep(ocrEntityResult);
                                if (
                                    ocrEntityResultCopy.children &&
                                    ocrEntityResultCopy.children.length > 0
                                ) {
                                    const childrenEntityArr = _.cloneDeep(
                                        ocrEntityResultCopy.children
                                    );

                                    this._unfoldChildrenEntities(
                                        childrenEntityArr,
                                        tableDataMap,
                                        colIndex,
                                        dataset
                                    );

                                    ocrEntityResultCopy.children = [];
                                }

                                return ocrEntityResultCopy;
                            }
                        );

                        tableDataMap.set(sampleEntity.entity, unfoldEntities);
                    }
                });

                const allkeys = tableDataMap.keys();
                const lv1DataMap = new Map<string, OcrEntityResult[]>();
                const lv2DataMap = new Map<string, OcrEntityResult[]>();
                for (let key of allkeys) {
                    if (this.lv1ChildEntityNames.includes(key)) {
                        const val = tableDataMap.get(key)!;
                        lv1DataMap.set(key, _.cloneDeep(val));
                        tableDataMap.delete(key);
                    } else if (this.lv2ChildEntityNames.includes(key)) {
                        const val = tableDataMap.get(key)!;
                        lv2DataMap.set(key, _.cloneDeep(val));
                        tableDataMap.delete(key);
                    }
                }

                this._foldChildrenEntities(
                    this.lv1ChildEntityNameTree,
                    lv1DataMap,
                    lv2DataMap,
                    this.props.records.length,
                    dataset
                );

                this._foldChildrenEntities(
                    this.standardEntityNameTree,
                    tableDataMap,
                    lv1DataMap,
                    this.props.records.length,
                    dataset
                );

                if (lv1DataMap.size > 0)
                    this._graftIsolateToRoot(tableDataMap, lv1DataMap);

                if (lv2DataMap.size > 0)
                    this._graftIsolateToRoot(tableDataMap, lv2DataMap);
            }

            return [dataset, Array.from(tableDataMap.values())] as [
                string,
                OcrEntityResult[][]
            ];
        });

        return reformatData;
    }

    private _foldChildrenEntities(
        parentEntityUnionArr: IEntityUnion[],
        parentDataMap: Map<string, OcrEntityResult[]>,
        childDataMap: Map<string, OcrEntityResult[]>,
        contrastCount: number,
        dataset: string
    ) {
        const childKeys = childDataMap.keys();
        for (let childKey of childKeys) {
            const parentEntityName = from(parentEntityUnionArr).first(
                (entity) => entity.children.includes(childKey)
            ).name;

            if (!parentDataMap.has(parentEntityName)) {
                const virtualEntityArr = new Array<OcrEntityResult>(
                    contrastCount
                );

                for (let i = 0; i < contrastCount; i++) {
                    virtualEntityArr[i] = {
                        id: dataset + parentEntityName + i,
                        entity: parentEntityName,
                        sentenceCount: NaN,
                        wordCount: NaN,
                        correct: NaN,
                        substitueRate: NaN,
                        deleteRate: NaN,
                        insertRate: NaN,
                        error: NaN,
                        sentenceErrorRate: NaN,
                        sentenceDeleteErrorRate: NaN,
                        sentenceSubsErrorRate: NaN,
                        sentenceErrorCount: NaN,
                    };
                }

                parentDataMap.set(parentEntityName, virtualEntityArr);
            }

            const parentEntityHorizontalArr = parentDataMap.get(
                parentEntityName
            ) as OcrEntityResult[];
            const childEntityHorizontalArr = childDataMap.get(
                childKey
            ) as OcrEntityResult[];

            for (let i = 0; i < contrastCount; i++) {
                if (!parentEntityHorizontalArr[i].children) {
                    parentEntityHorizontalArr[i].children = [];
                }

                parentEntityHorizontalArr[i].children!.push(
                    _.cloneDeep(childEntityHorizontalArr[i])
                );
            }

            childDataMap.delete(childKey);
            parentDataMap.set(parentEntityName, parentEntityHorizontalArr);
        }

        const entityKeysToCalcChildren = from(parentDataMap.keys())
            .intersect(from(parentEntityUnionArr.map((e) => e.name)))
            .toArray();

        entityKeysToCalcChildren.forEach((key) => {
            let ocrEntityArr = parentDataMap.get(key) as OcrEntityResult[];
            for (let i = 0; i < ocrEntityArr.length; i++) {
                const childrenOcrEntities = ocrEntityArr[i].children;
                if (
                    isNaN(ocrEntityArr[i].sentenceCount) &&
                    isNaN(ocrEntityArr[i].wordCount) &&
                    childrenOcrEntities &&
                    childrenOcrEntities.length > 0
                ) {
                    childrenOcrEntities.forEach((child) => {
                        const calcedEntityResult = this._dataCalcInEntityResult(
                            child.entity,
                            child
                        );

                        CALC_FIELDS.forEach((field) => {
                            const childFieldVal = calcedEntityResult[
                                field.fieldName as keyof OcrEntityResult
                            ] as number;

                            if (!isNaN(childFieldVal)) {
                                let parentFieldVal = ocrEntityArr[i][
                                    field.fieldName as keyof OcrEntityResult
                                ] as number;

                                if (isNaN(parentFieldVal)) {
                                    parentFieldVal = 0;
                                }

                                (ocrEntityArr[i][
                                    field.fieldName as keyof OcrEntityResult
                                ] as number) = parentFieldVal + childFieldVal;
                            }
                        });
                    });

                    ocrEntityArr[i] = this._calcTheOverallAverageRate(
                        ocrEntityArr[i]
                    );
                }
            }

            parentDataMap.set(key, ocrEntityArr);
        });
    }

    private _unfoldChildrenEntities(
        ocrEntityResultVerticalArr: OcrEntityResult[],
        tableDataMap: Map<string, OcrEntityResult[]>,
        colIndex: number,
        dataset: string
    ) {
        ocrEntityResultVerticalArr.forEach((ocrEntityResult) => {
            if (
                ocrEntityResult.children &&
                ocrEntityResult.children.length > 0
            ) {
                const childrenEntities = _.cloneDeep(ocrEntityResult.children);
                this._unfoldChildrenEntities(
                    childrenEntities,
                    tableDataMap,
                    colIndex,
                    dataset
                );

                ocrEntityResult.children = [];
            }

            let entityArr = tableDataMap.get(ocrEntityResult.entity);
            if (!entityArr) {
                entityArr = new Array<OcrEntityResult>(
                    this.props.records.length
                );
                for (let i = 0; i < this.props.records.length; i++) {
                    entityArr[i] = {
                        id: dataset + ocrEntityResult.entity + i,
                        entity: ocrEntityResult.entity,
                        sentenceCount: NaN,
                        wordCount: NaN,
                        correct: NaN,
                        substitueRate: NaN,
                        deleteRate: NaN,
                        insertRate: NaN,
                        error: NaN,
                        sentenceErrorRate: NaN,
                        sentenceDeleteErrorRate: NaN,
                        sentenceSubsErrorRate: NaN,
                        sentenceErrorCount: NaN,
                        statsType: StatisticsType.Standard,
                    };
                }
            }
            entityArr[colIndex] = ocrEntityResult;
            tableDataMap.set(ocrEntityResult.entity, entityArr);
        });
    }

    private _graftIsolateToRoot(
        rootDataMap: Map<string, OcrEntityResult[]>,
        isolateDataMap: Map<string, OcrEntityResult[]>
    ): void {
        if (rootDataMap && isolateDataMap && isolateDataMap.size > 0) {
            for (let dataItem of isolateDataMap) {
                rootDataMap.set(dataItem[0], dataItem[1]);
            }
        }
    }

    private _extractDatasetNameByChooseOption(
        chooseOption: string | undefined = this.state.chooseOption,
        datasetNames: string[] = this.intersectLanguages
    ) {
        switch (chooseOption) {
            case DATASET_NAME_SYMBOLS.OCR_Printed: {
                datasetNames = datasetNames.filter(
                    (name) =>
                        !name.startsWith(DATASET_NAME_SYMBOLS.Verticals) &&
                        !name.startsWith(DATASET_NAME_SYMBOLS.Entity) &&
                        name.indexOf(DATASET_NAME_SYMBOLS.OCR_Handwritten) ===
                            -1
                );
                this.supportCrossLang = true;
                break;
            }
            case DATASET_NAME_SYMBOLS.OCR_Handwritten: {
                datasetNames = datasetNames.filter(
                    (name) =>
                        !name.startsWith(DATASET_NAME_SYMBOLS.Verticals) &&
                        !name.startsWith(DATASET_NAME_SYMBOLS.Entity) &&
                        name.indexOf(DATASET_NAME_SYMBOLS.OCR_Handwritten) !==
                            -1
                );
                this.supportCrossLang = true;
                break;
            }
            case DATASET_NAME_SYMBOLS.Entity: {
                datasetNames = datasetNames.filter((name) =>
                    name.startsWith(DATASET_NAME_SYMBOLS.Entity)
                );
                this.supportCrossLang = false;
                break;
            }
            case DATASET_NAME_SYMBOLS.Verticals: {
                datasetNames = datasetNames.filter((name) =>
                    name.startsWith(DATASET_NAME_SYMBOLS.Verticals)
                );
                this.supportCrossLang = true;
                break;
            }
        }

        return datasetNames;
    }

    private _calcDataByEachEntityInAllLang(
        data: [string, OcrEntityResult[][]][]
    ): OcrEntityResult[][] {
        const {
            selectedCrossLanguages = this._extractDatasetNameByChooseOption(),
        } = this.state;
        const dictMap = new Map<string, OcrEntityResult[]>();
        const childrenLv1DictMap = new Map<string, OcrEntityResult[]>();
        const childrenLv2DictMap = new Map<string, OcrEntityResult[]>();
        for (
            let colIndex = 0;
            colIndex < this.props.records.length;
            colIndex++
        ) {
            data.forEach(([datasetName, groupData]) => {
                if (selectedCrossLanguages?.includes(datasetName)) {
                    groupData.forEach((entityDataItems) => {
                        const entityName = from(entityDataItems).firstOrDefault(
                            (entityDataItem) =>
                                entityDataItem.entity ? true : false
                        )?.entity;

                        if (entityDataItems[colIndex] && entityName) {
                            const entity = entityDataItems[colIndex];
                            if (
                                entity &&
                                !isNaN(entity?.sentenceCount) &&
                                !isNaN(entity?.wordCount)
                            ) {
                                let ocrEntityResult =
                                    this._dataCalcInEntityResult(
                                        entityName,
                                        entity
                                    );

                                ocrEntityResult.statsType =
                                    StatisticsType.CrossLang;
                                this._calcChildrenDataByEachEntityInAllLang(
                                    entity,
                                    [childrenLv1DictMap, childrenLv2DictMap],
                                    colIndex,
                                    this.props.records.length,
                                    ChildrenEntityLevel.Lv1
                                );

                                if (dictMap.has(entityName)) {
                                    const existEntityArr =
                                        dictMap.get(entityName);
                                    if (
                                        existEntityArr &&
                                        colIndex < existEntityArr?.length
                                    ) {
                                        let existEntity =
                                            existEntityArr[colIndex];

                                        if (
                                            existEntity &&
                                            !isNaN(
                                                existEntity?.sentenceCount
                                            ) &&
                                            !isNaN(existEntity?.wordCount)
                                        ) {
                                            ocrEntityResult =
                                                this._sumEntitiesData(
                                                    ocrEntityResult,
                                                    existEntity
                                                );
                                        }

                                        existEntityArr[colIndex] =
                                            ocrEntityResult;
                                        dictMap.set(entityName, existEntityArr);
                                    }
                                } else {
                                    let newEntityArr =
                                        new Array<OcrEntityResult>(
                                            this.props.records.length
                                        );

                                    for (
                                        let i = 0;
                                        i < this.props.records.length;
                                        i++
                                    ) {
                                        newEntityArr[i] = {
                                            id: "cross" + entityName + i,
                                            entity: entityName,
                                            sentenceCount: NaN,
                                            wordCount: NaN,
                                            correct: NaN,
                                            substitueRate: NaN,
                                            deleteRate: NaN,
                                            insertRate: NaN,
                                            error: NaN,
                                            sentenceErrorRate: NaN,
                                            sentenceDeleteErrorRate: NaN,
                                            sentenceSubsErrorRate: NaN,
                                            sentenceErrorCount: NaN,
                                            statsType: StatisticsType.CrossLang,
                                        };
                                    }

                                    newEntityArr[colIndex] = ocrEntityResult;
                                    dictMap.set(entityName, newEntityArr);
                                }
                            }
                        }
                    });
                }
            });
        }
        this._convertChildrenDataMapToEntityArray(
            childrenLv1DictMap,
            ChildrenEntityLevel.Lv1
        );
        this._convertChildrenDataMapToEntityArray(
            childrenLv2DictMap,
            ChildrenEntityLevel.Lv2
        );

        return Array.from(dictMap, ([key, ocrEntityResultArr]) => {
            return ocrEntityResultArr.map((ocrEntityResult) => {
                return this._calcTheOverallAverageRate(ocrEntityResult);
            });
        });
    }

    private _calcChildrenDataByEachEntityInAllLang(
        parentEntity: OcrEntityResult,
        childrenDictMapArr: Array<Map<string, OcrEntityResult[]>>,
        colIndex: number,
        colCount: number,
        childrenLv: ChildrenEntityLevel
    ) {
        if (childrenLv < ChildrenEntityLevel.Max) {
            const childrenEntities = parentEntity?.children;
            if (childrenEntities && childrenEntities.length > 0) {
                childrenEntities.forEach((childEntity) => {
                    let childrenDictMap = childrenDictMapArr[childrenLv];
                    let ocrEntityResult = this._dataCalcInEntityResult(
                        childEntity.entity,
                        childEntity
                    );
                    ocrEntityResult.statsType = StatisticsType.CrossLang;

                    this._calcChildrenDataByEachEntityInAllLang(
                        ocrEntityResult,
                        childrenDictMapArr,
                        colIndex,
                        colCount,
                        childrenLv + 1
                    );

                    const complexKey =
                        parentEntity.entity +
                        KEY_SEPERATEOR +
                        childEntity.entity;

                    if (childrenDictMap.has(complexKey)) {
                        let childrenEntityArr = childrenDictMap.get(complexKey);
                        if (
                            childrenEntityArr &&
                            colIndex < childrenEntityArr.length
                        ) {
                            let existEntity = childrenEntityArr[colIndex];
                            if (
                                existEntity &&
                                !isNaN(existEntity?.sentenceCount) &&
                                !isNaN(existEntity?.wordCount)
                            ) {
                                ocrEntityResult = this._sumEntitiesData(
                                    ocrEntityResult,
                                    existEntity
                                );
                            }

                            childrenEntityArr[colIndex] = ocrEntityResult;
                            childrenDictMap.set(complexKey, childrenEntityArr);
                        }
                    } else {
                        let newChildrenEntityArr = new Array<OcrEntityResult>(
                            colCount
                        );

                        for (let i = 0; i < colCount; i++) {
                            newChildrenEntityArr[i] = {
                                id: "cross" + childEntity.entity + i,
                                entity: childEntity.entity,
                                sentenceCount: NaN,
                                wordCount: NaN,
                                correct: NaN,
                                substitueRate: NaN,
                                deleteRate: NaN,
                                insertRate: NaN,
                                error: NaN,
                                sentenceErrorRate: NaN,
                                sentenceDeleteErrorRate: NaN,
                                sentenceSubsErrorRate: NaN,
                                sentenceErrorCount: NaN,
                                statsType: StatisticsType.CrossLang,
                            };
                        }

                        newChildrenEntityArr[colIndex] = ocrEntityResult;
                        childrenDictMap.set(complexKey, newChildrenEntityArr);
                    }
                });
            }
        }
    }

    private _constructChildrenEntityListModel(
        rowItems: Array<OcrEntityResult>
    ): Array<OcrEntityResult[]> {
        let childrenEntity2dArr: Array<OcrEntityResult[]> = [];
        if (rowItems?.length > 0) {
            const dictMap = new Map<string, OcrEntityResult[]>();
            rowItems.forEach((rowItem, index) => {
                const childrenItems = rowItem?.children;
                if (childrenItems && childrenItems.length > 0) {
                    childrenItems.forEach((item) => {
                        let ocrEntityArr: Array<OcrEntityResult> = [];
                        if (dictMap.has(item.entity)) {
                            ocrEntityArr = dictMap.get(item.entity)!;
                        } else {
                            ocrEntityArr = new Array<OcrEntityResult>(
                                rowItems.length
                            ).fill({
                                entity: item.entity,
                                sentenceCount: NaN,
                                wordCount: NaN,
                                correct: NaN,
                                substitueRate: NaN,
                                deleteRate: NaN,
                                insertRate: NaN,
                                error: NaN,
                                sentenceErrorRate: NaN,
                                sentenceDeleteErrorRate: NaN,
                                sentenceSubsErrorRate: NaN,
                                sentenceErrorCount: NaN,
                            });
                        }

                        ocrEntityArr[index] = item;
                        dictMap.set(item.entity, ocrEntityArr);
                    });
                }
            });

            childrenEntity2dArr = Array.from(
                dictMap,
                ([key, ocrEntityResultArr]) => {
                    return ocrEntityResultArr;
                }
            );
        }

        return childrenEntity2dArr;
    }

    private _calcTheOverallAverageRate(
        ocrEntityResult: OcrEntityResult
    ): OcrEntityResult {
        ocrEntityResult.correct = this._ensureDecimalAccuracy(
            ocrEntityResult.correct / ocrEntityResult.wordCount
        );
        ocrEntityResult.substitueRate = this._ensureDecimalAccuracy(
            ocrEntityResult.substitueRate / ocrEntityResult.wordCount
        );
        ocrEntityResult.deleteRate = this._ensureDecimalAccuracy(
            ocrEntityResult.deleteRate / ocrEntityResult.wordCount
        );
        ocrEntityResult.insertRate = this._ensureDecimalAccuracy(
            ocrEntityResult.insertRate / ocrEntityResult.wordCount
        );
        ocrEntityResult.error = this._ensureDecimalAccuracy(
            ocrEntityResult.error / ocrEntityResult.wordCount
        );
        ocrEntityResult.sentenceErrorRate = this._ensureDecimalAccuracy(
            ocrEntityResult.sentenceErrorRate / ocrEntityResult.sentenceCount
        );
        ocrEntityResult.sentenceSubsErrorRate = this._ensureDecimalAccuracy(
            ocrEntityResult.sentenceSubsErrorRate /
                ocrEntityResult.sentenceCount
        );
        ocrEntityResult.sentenceDeleteErrorRate = this._ensureDecimalAccuracy(
            ocrEntityResult.sentenceDeleteErrorRate /
                ocrEntityResult.sentenceCount
        );

        return ocrEntityResult;
    }

    private _constructCrossLangChildrenEntityListModel(
        rowItems: Array<OcrEntityResult>,
        childrenEntityLv: ChildrenEntityLevel
    ): Array<OcrEntityResult[]> {
        let childrenEntity2dArr: Array<OcrEntityResult[]> = [];
        let crossLangChildDictMap: Map<string, OcrEntityResult[]>;
        if (childrenEntityLv === ChildrenEntityLevel.Lv1) {
            crossLangChildDictMap = this.crossLangLv1ChildDictMap;
        } else if (childrenEntityLv === ChildrenEntityLevel.Lv2) {
            crossLangChildDictMap = this.crossLangLv2ChildDictMap;
        } else {
            return childrenEntity2dArr;
        }

        if (rowItems?.length > 0) {
            childrenEntity2dArr = [];
            if (crossLangChildDictMap) {
                crossLangChildDictMap.forEach((entityArr, key) => {
                    const parentEntityName = key.split(KEY_SEPERATEOR)[0];
                    if (parentEntityName === rowItems[0]?.entity) {
                        childrenEntity2dArr.push(entityArr);
                    }
                });
            }
        }

        return childrenEntity2dArr;
    }

    private _convertChildrenDataMapToEntityArray(
        childrenDataDict: Map<string, OcrEntityResult[]>,
        childrenEntityLv: ChildrenEntityLevel
    ) {
        let crossLangChildDictMap: Map<string, OcrEntityResult[]>;
        if (childrenEntityLv === ChildrenEntityLevel.Lv1) {
            crossLangChildDictMap = this.crossLangLv1ChildDictMap;
        } else if (childrenEntityLv === ChildrenEntityLevel.Lv2) {
            crossLangChildDictMap = this.crossLangLv2ChildDictMap;
        } else {
            return;
        }

        crossLangChildDictMap.clear();
        childrenDataDict.forEach((item, key) => {
            item.forEach((entity, cIndex, thisItem) => {
                thisItem[cIndex] = this._calcTheOverallAverageRate(entity);
            });

            crossLangChildDictMap.set(key, item);
        });
    }

    private _dataCalcInEntityResult(
        entityName: string,
        entity: OcrEntityResult
    ): OcrEntityResult {
        return {
            id: entity.id,
            entity: entityName,
            sentenceCount: entity.sentenceCount,
            wordCount: entity.wordCount,
            correct: entity.wordCount * entity.correct,
            substitueRate: entity.wordCount * entity.substitueRate,
            deleteRate: entity.wordCount * entity.deleteRate,
            insertRate: entity.wordCount * entity.insertRate,
            error: entity.wordCount * entity.error,
            sentenceErrorRate: entity.sentenceCount * entity.sentenceErrorRate,
            sentenceDeleteErrorRate:
                entity.sentenceCount * entity.sentenceDeleteErrorRate,
            sentenceSubsErrorRate:
                entity.sentenceCount * entity.sentenceSubsErrorRate,
            sentenceErrorCount: entity.sentenceErrorCount,
            statsType: entity?.statsType,
            children: entity?.children,
        };
    }

    private _arrangeListSource(
        datasetName: string,
        ocrEntityResult2dArr: OcrEntityResult[][]
    ): EntityResultListSource {
        let specificGroups = _.cloneDeep(SPECIFIC_ENTITY_GROUPS_DEFINE);
        let result: EntityResultListSource = {
            ocrEntityResult2dArr: [],
            groups: [],
        };
        if (ocrEntityResult2dArr?.length > 0) {
            const groupSymbols = [...specificGroups.keys()];
            let genericEntityResult2dArr = ocrEntityResult2dArr.filter(
                (ocrEntityResultArr) => {
                    let filterResult = true;
                    if (ocrEntityResultArr?.length > 0) {
                        groupSymbols.some((symbol) => {
                            const availabeOcrEntity = ocrEntityResultArr.find(
                                (e) => e
                            );

                            if (
                                availabeOcrEntity &&
                                availabeOcrEntity.entity
                                    .toLowerCase()
                                    .startsWith(symbol)
                            ) {
                                let group = specificGroups.get(symbol)!;
                                group.entityMembers?.push(ocrEntityResultArr);
                                specificGroups.set(symbol, group);
                                filterResult = false;
                                return true;
                            }

                            return false;
                        });
                    }

                    return filterResult;
                }
            );

            let queueEndIndex = 0;
            if (genericEntityResult2dArr?.length > 0) {
                result.groups.push({
                    key: GENERIC_ENTITY_GROUP_KEY,
                    name: "Generic",
                    startIndex: queueEndIndex,
                    count: genericEntityResult2dArr.length,
                    level: 0,
                    isCollapsed: false,
                });
                result.ocrEntityResult2dArr = genericEntityResult2dArr;
                queueEndIndex += genericEntityResult2dArr.length;
            }

            specificGroups.forEach((group, symbol) => {
                if (group?.entityMembers && group?.entityMembers.length > 0) {
                    result.groups.push({
                        key: symbol,
                        name: group.displayName,
                        startIndex: queueEndIndex,
                        count: group.entityMembers.length,
                        level: 0,
                        isCollapsed: group.collapsedByDef,
                        data: {
                            groupId: datasetName + symbol,
                            dataDesc: group?.description,
                        } as IDataProp,
                    });
                    queueEndIndex += group.entityMembers.length;
                    result.ocrEntityResult2dArr =
                        result.ocrEntityResult2dArr.concat(group.entityMembers);
                }
            });
        }

        return result;
    }
    //#endregion

    //#region Help method
    private _ensureDecimalAccuracy(
        num: number,
        decimalCount: number = 1
    ): number {
        return +num.toFixed(decimalCount);
    }

    private _getRowItemsMetaInfo(
        items: Array<OcrEntityResult>
    ): [string, StatisticsType | null] {
        let rowId: string = "";
        let statsType: StatisticsType | null = null;

        if (items?.length > 0) {
            items.some((item) => {
                if (item.id && !rowId) {
                    rowId = item.id;
                }

                if (item?.statsType && statsType == null) {
                    statsType = item?.statsType;
                }

                return rowId && statsType != null;
            });
        }

        return [rowId, statsType];
    }

    private _sumEntitiesData(
        currentEntity: OcrEntityResult,
        existEntity: OcrEntityResult
    ): OcrEntityResult {
        CALC_FIELDS.forEach((field) => {
            (currentEntity[
                field.fieldName as keyof OcrEntityResult
            ] as number) += existEntity[
                field.fieldName as keyof OcrEntityResult
            ] as number;
        });

        return currentEntity;
    }
    //#endregion
}
