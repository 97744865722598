import React from "react";
import "../Common/MetricStyle.scss";
import {
    //Document Card
    DocumentCard,
    DocumentCardType,

    //Others
    Label,
} from "@fluentui/react";
import { DatasetSet, Workspaces } from "../../DataContract";
import { ICommonProps, ICommonState } from "../Common/CommonMetrics";

import {
    IGroupedLineChartData,
    VerticalLineChart,
} from "../../Controls/D3/VerticalLineChart";

import {
    ILatencyDataItem,
    IScatterDataItem,
    languageToScript,
    OcrLatencyPerfMetrics,
    OcrLatencyScatterData,
} from "./Interface";
import {
    IScatterChartDataPoint,
    VerticalScatterChart,
} from "../../Controls/D3/VerticalScatterChart";

import { VerticalPieChart } from "../../Controls/D3/VerticalPieChart";
import { Dictionary } from "lodash";

import { ITableConfigurations, TableHeader } from "../../Controls";

import { CommonAnalysisView, LatencyType } from "./CommonAnalysisView";
import { getColorByIndex } from "../../Utils";
import { mergeDataItems } from "./LatencyUtil";
import { NoDataTip } from "../../Controls/NoDataTip";
import _ from "lodash";

interface IProps extends ICommonProps {
    dataItems: ILatencyDataItem[];
    matchDatasetVersion: boolean;
    includesLongTail: boolean;
    crossLanguage: boolean;
}
enum ChartType {
    LineChart = "LineChart",
    ScatterChart = "ScatterChart",
    PieChart = "PieChart",
}

const CHARTOPTIONS: string[] = [
    ChartType.LineChart,
    ChartType.ScatterChart,
    ChartType.PieChart,
];

interface IState extends ICommonState<OcrLatencyPerfMetrics> {
    dataItems: ILatencyDataItem[];
    selectedType?: string | number;
    xLabel?: number;
    yAxisFrom?: number;
    yAxisTo?: number;
    xAxisList?: string[];
    selectedXAxis?: string;
    selectedYAxis?: string;
    languages: string[];
    selectLanguage?: string;
}
export class ComponentLatency extends CommonAnalysisView<
    IState,
    OcrLatencyPerfMetrics
> {
    constructor(props: IProps) {
        super(props);

        this._renderChartHeader = this._renderChartHeader.bind(this);
        this.state = {
            dataItems: [],
            selectedType: CHARTOPTIONS[0],
            matchDatasetVersion: true,
            languages: this.getLanguageList(props.crossLanguage),
        };
    }
    queryMetricsResult() {
        const { selectLanguage, languages } = this.state;
        this._queryMetricsResult(
            "overall_latency_image_metrics.json",
            selectLanguage ?? languages[0]
        );
    }

    public render() {
        const { selectedType, dataItems } = this.state;
        let items = Array<ILatencyDataItem>();
        dataItems.forEach((dataItem) => {
            if (!Array.isArray(dataItem)) {
                items.push(dataItem);
            }
        });
        return (
            <>
                {this.props.dataItems && this._renderChartHeader()}
                {selectedType === ChartType.LineChart && (
                    <div style={{ height: "100%", overflow: "hidden auto" }}>
                        {this._renderLineChart()}
                    </div>
                )}
                {selectedType === ChartType.ScatterChart && dataItems && (
                    <div style={{ height: "100%", overflow: "hidden auto" }}>
                        {this._renderScatterChart(items)}
                    </div>
                )}
                {selectedType === ChartType.PieChart && (
                    <div style={{ height: "100%", overflow: "hidden auto" }}>
                        {this._renderPieChart()}
                    </div>
                )}
            </>
        );
    }
    public componentDidMount(): void {
        super.componentDidMount();
        this.setState({ xAxisList: this.getLineChartxAxisList() });
    }

    public componentDidUpdate(prevProps: IProps, prevState: IState): void {
        super.componentDidUpdate(prevProps, prevState);
        if (!_.isEqual(prevState.selectedType, this.state.selectedType)) {
            let xAxisList: any[] = [];
            if (this.state.selectedType === ChartType.LineChart) {
                xAxisList = this.getLineChartxAxisList();
            } else if (this.state.selectedType === ChartType.ScatterChart) {
                xAxisList = this.getScatterChartxAxisList();
            }
            this.setState({ xAxisList: xAxisList });
        }

        if (
            !_.isEqual(prevProps.crossLanguage, this.props.crossLanguage) &&
            this.state.selectedType === ChartType.ScatterChart
        ) {
            this.setState({
                languages: this.getLanguageList(this.props.crossLanguage),
            });
        }
    }

    getLineChartxAxisList = () => {
        const { dataItems } = this.props;
        let xAxisList: any[] = [];
        const sampleItemFromPorps =
            this._findSamplePercentileLatencyDataItem(dataItems);
        if (
            sampleItemFromPorps &&
            sampleItemFromPorps.metrics?.percentile_latency.length > 0
        ) {
            sampleItemFromPorps.metrics.percentile_latency.forEach((metric) => {
                xAxisList.push(metric.chartname);
            });
        }
        return xAxisList;
    };
    getScatterChartxAxisList = () => {
        let xAxisList: any[] = [];
        const sampleItemFromState = this._findSampleLatencyDataItem(
            this.state.dataItems
        );
        if (sampleItemFromState) {
            xAxisList = Object.keys(
                Object.values(sampleItemFromState.metrics).find((val) => val)
            );
        }
        return xAxisList;
    };

    _tryFindMatchedItems(
        crossLanguage: boolean,
        recordIndex: number,
        matchDatasetVersion: boolean,
        dataRecordName: string
    ) {
        // all item in this list will be merged into one single item
        let items = Array<ILatencyDataItem>();
        if (
            this.state.selectedType === ChartType.LineChart ||
            this.state.selectedType === ChartType.PieChart
        ) {
            const { defaultCrossLanguage = "Unknown" } = this.props;

            items = this.props.dataItems
                .filter((item) => {
                    let match = false;
                    if (!crossLanguage) {
                        match = matchDatasetVersion
                            ? item.recordDetail.dataset.displayFullName ===
                              dataRecordName
                            : item.recordDetail.dataset.displayName ===
                              dataRecordName;
                    } else {
                        let language = item.recordDetail.dataset.language;
                        match =
                            (languageToScript[language] ??
                                defaultCrossLanguage) === dataRecordName;
                    }

                    return match && item.recordIndex === recordIndex;
                })
                .filter((item) => !_.isEmpty(item.metrics.category_latency));
        }
        return items;
    }

    protected _renderLineChart() {
        const { includesLongTail, crossLanguage } = this.props;

        const data = this._prepareAnalysisRenderData(
            true,
            crossLanguage,
            LatencyType.ComponentLatency
        );

        return data.length !== 0 ? (
            data.map(([datasetName, groupData], datasetIndex) => {
                let xAxisList: string[] = [];
                const sampleItem = this.props.dataItems.find(
                    (d) => d.metrics.percentile_latency?.length > 0
                );
                if (sampleItem) {
                    sampleItem.metrics.percentile_latency.forEach((metric) => {
                        xAxisList.push(metric.chartname);
                    });
                }

                const chartDatas = groupData
                    .filter(([metricsName, _]) => {
                        return (
                            metricsName ===
                            (this.state.selectedXAxis ??
                                (xAxisList[0] as string))
                        );
                    })
                    .map(([chartname, chartItems], index) => {
                        const points = chartItems.map(
                            (chartItem, chartIndex) => {
                                if (
                                    chartItem.chartData.chartPoints.length === 0
                                ) {
                                    return {
                                        legend: "",
                                        data: [{ x: 0, y: 0 }],
                                        color: getColorByIndex(chartIndex),
                                        metrics: chartItem.chartData.chartname,
                                    };
                                } else {
                                    return {
                                        legend:
                                            chartItem.data?.recordDetail.name ??
                                            "",
                                        data: chartItem.chartData.chartPoints.filter(
                                            (element) =>
                                                element.x <=
                                                (includesLongTail === true
                                                    ? 100
                                                    : 90)
                                        ),
                                        color: getColorByIndex(chartIndex),
                                        metrics: chartItem.chartData.chartname,
                                    };
                                }
                            }
                        );
                        return {
                            chartToDisplay: {
                                chartTitle: datasetName + "_" + chartname,
                                lineChartData: points,
                            } as IGroupedLineChartData,
                        };
                    });

                return (
                    <DocumentCard
                        key={`chart_${datasetName}`}
                        className="overview__card_latency"
                        type={DocumentCardType.compact}
                        style={{ width: `4000px` }}
                    >
                        {chartDatas.map((chartData, index) => {
                            let keyIndex = 0;
                            let sampleLineChartData =
                                chartData.chartToDisplay.lineChartData.find(
                                    (l, i) => {
                                        if (l.legend) {
                                            keyIndex = i;
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    }
                                );
                            if (sampleLineChartData === undefined) {
                                sampleLineChartData =
                                    chartData.chartToDisplay.lineChartData[0];
                            }

                            let xRange = sampleLineChartData.data.map(
                                (d) => d.x as number
                            );
                            let xNumber = Math.max(...xRange);
                            let yRange = sampleLineChartData.data.map(
                                (d) => d.y as number
                            );
                            let yNumber = Math.max(...yRange);

                            return (
                                <VerticalLineChart
                                    key={"verticalLine_" + index}
                                    height={500}
                                    id={
                                        "vLineChart" +
                                        datasetIndex.toString() +
                                        index.toString()
                                    }
                                    data={chartData.chartToDisplay}
                                    keyIndex={keyIndex}
                                    sliderOnChange={(slideVal: number) =>
                                        this.sliderOnChange(slideVal)
                                    }
                                    xRange={this.state.xLabel ?? xNumber}
                                    yAxisFrom={this.state.yAxisFrom ?? 0}
                                    yAxisTo={this.state.yAxisTo ?? yNumber}
                                    YMax={yNumber}
                                    changeYAxisRange={(
                                        from: number,
                                        to: number
                                    ) => this.changeYAxisRange(from, to)}
                                    isDarkTheme={this.props.isDarkTheme}
                                ></VerticalLineChart>
                            );
                        })}
                    </DocumentCard>
                );
            })
        ) : (
            <NoDataTip>No Latency Metrics Generated</NoDataTip>
        );
    }
    protected _renderScatterChart(items: ILatencyDataItem[]) {
        const { languages } = this.state;
        const data = this._prepareScatterRenderData(true, items);

        let chartDatas: any[];
        let newData = data.filter(([datasetName, _]) => {
            return datasetName === languages[0];
        });

        chartDatas = newData.map(([_, groupData]) => {
            let points = groupData.map((chartItems, groupId) => {
                return {
                    data: chartItems.chartData.chartPoints,
                    color: getColorByIndex(groupId),
                    legend: chartItems.data?.recordDetail.name ?? "",
                };
            });

            return {
                chartItems: points,
            };
        });

        return data.length !== 0 && items.length !== 0 ? (
            <DocumentCard
                key={`chart_0}`}
                className="overview__card"
                type={DocumentCardType.normal}
                style={{ width: `4000px` }}
            >
                {chartDatas.map((chartData: any, index: number) => {
                    let xNumber: number = 0;
                    let yNumber: number = 0;

                    chartData.chartItems.forEach((chartItem: any) => {
                        const xArr = chartItem.data
                            .map((d: any) => d.x as number)
                            .filter((item: any) => item);
                        if (
                            xArr &&
                            typeof xArr[Symbol.iterator] === "function"
                        ) {
                            const xMax = Math.max(...xArr);
                            if (xMax > xNumber) {
                                xNumber = xMax;
                            }
                        }

                        const yArr = chartItem.data
                            .map((d: any) => d.y as number)
                            .filter((item: any) => item);
                        if (
                            yArr &&
                            typeof yArr[Symbol.iterator] === "function"
                        ) {
                            const yMax = Math.max(...yArr);
                            if (yMax > yNumber) {
                                yNumber = yMax;
                            }
                        }
                    });

                    return (
                        chartData.chartItems.length !== 0 && (
                            <VerticalScatterChart
                                key={"verticalScatter_" + index}
                                height={500}
                                id={"vSctterChart" + index.toString()}
                                keyIndex={index}
                                data={chartData.chartItems}
                                chartTitle={
                                    this.state.selectLanguage ?? languages[0]
                                }
                                yAxisFrom={this.state.yAxisFrom ?? 0}
                                yAxisTo={this.state.yAxisTo ?? yNumber}
                                YMax={yNumber}
                                xRange={this.state.xLabel ?? xNumber}
                                xMax={xNumber}
                                changeYAxisRange={(from: number, to: number) =>
                                    this.changeYAxisRange(from, to)
                                }
                                sliderOnChange={(slideVal: number) =>
                                    this.sliderOnChange(slideVal)
                                }
                                isDarkTheme={this.props.isDarkTheme}
                            ></VerticalScatterChart>
                        )
                    );
                })}
            </DocumentCard>
        ) : (
            <NoDataTip>No Scatter Point Chart Generated</NoDataTip>
        );
    }
    private sliderOnChange(value: number) {
        this.setState({
            xLabel: value,
        });
    }

    private _prepareScatterRenderData(
        matchDatasetVersion: boolean,
        items: ILatencyDataItem[]
    ) {
        const { selectedXAxis, selectedYAxis, languages } = this.state;
        let axisList: string[] = [];

        const data = languages.map((language) => {
            let chartGroupObj: any = {};
            // retrieve latency data
            const chartItems = items.map((item: any, index: number) => {
                let chartData: OcrLatencyScatterData = {
                    chartAxisList: [],
                    chartPoints: [],
                };
                let chartPoints: IScatterChartDataPoint[] = [];
                if (item !== undefined) {
                    Object.entries(item.metrics).forEach(
                        ([key, metric]: any) => {
                            let chartObj: any = {};
                            Object.entries(metric).forEach(
                                ([keyName, value]) => {
                                    if (axisList.indexOf(keyName) === -1) {
                                        axisList.push(keyName);
                                    }
                                    chartObj[keyName] = value;
                                }
                            );
                            chartGroupObj[key] = chartObj;
                        }
                    );
                    let x = selectedXAxis ?? axisList[0];
                    let y = selectedYAxis ?? axisList[1];

                    chartPoints = Object.values(chartGroupObj).map(
                        (metric: any) => {
                            return {
                                x: metric[x],
                                y: metric[y],
                            };
                        }
                    );

                    chartData = {
                        chartAxisList: axisList,
                        chartPoints: chartPoints,
                    };
                }

                return {
                    data: item[index],
                    chartData: chartData,
                } as IScatterDataItem;
            });
            return [language, chartItems] as [string, IScatterDataItem[]];
        });
        return data as [string, IScatterDataItem[]][];
    }

    private changeYAxisRange(from: number, to: number) {
        this.setState({
            yAxisFrom: from,
            yAxisTo: to,
        });
    }

    protected _prepareRenderPieData(
        matchDatasetVersion: boolean,
        crossLanguage: boolean
    ) {
        // find all datasets
        const { defaultCrossLanguage = "Unknown" } = this.props;
        const datasets = Array.from(
            new DatasetSet(this.props.records.flatMap((r) => r.getDatasets()))
        );
        const dataRecordNames = Array.from(
            new Set(
                datasets.map((dataset) => {
                    if (!crossLanguage) {
                        return matchDatasetVersion
                            ? dataset.displayFullName
                            : dataset.displayName;
                    } else {
                        // use script name instead of dataset name
                        let language = dataset.displayFullName.split(":")[0];
                        return (
                            languageToScript[language] ?? defaultCrossLanguage
                        );
                    }
                })
            )
        );
        const data = dataRecordNames.map((dataRecordName) => {
            const items = this.props.records.map((_, recordIndex) => {
                // all item in this list will be merged into one single item
                let items = this._tryFindMatchedItems(
                    crossLanguage,
                    recordIndex,
                    matchDatasetVersion,
                    dataRecordName
                );
                // use the first item as merged target, use cloned data incase dup merge
                let mergedItem: any = items[0];
                if (crossLanguage) {
                    mergedItem = mergeDataItems(
                        items,
                        this.workSpace,
                        this.props.defaultCategory,
                        LatencyType.ComponentLatency
                    );
                }
                return mergedItem;
            });
            let categories = Array.from(
                new Set(
                    items.flatMap((item) => {
                        const latencys = item?.metrics.category_latency;
                        if (latencys) {
                            if (latencys instanceof Array) {
                                return latencys.map(
                                    (latencys) => latencys["category"]
                                );
                            } else {
                                return latencys["category"];
                            }
                        }
                        return [];
                    })
                )
            );

            const groupData = categories.map((category) => {
                const latencyItems = items.map((item) => {
                    let latency: Dictionary<any> | undefined;
                    if (item !== undefined) {
                        const { metrics } = item;
                        if (metrics.category_latency instanceof Array) {
                            latency = metrics.category_latency.find(
                                (latency: any) =>
                                    latency["category"] === category
                            );
                        } else {
                            latency = metrics.category_latency;
                        }
                    }
                    return {
                        data: item,
                        latency: latency,
                    } as ILatencyDataItem;
                });
                return [category, latencyItems] as [string, ILatencyDataItem[]];
            });
            return [dataRecordName, groupData, categories] as [
                string,
                [string, ILatencyDataItem[]][],
                string[]
            ];
        });
        return data.filter(([_, groupData]) => groupData.length > 0);
    }

    private _renderPieChart() {
        const { crossLanguage } = this.props;
        const data = this._prepareRenderPieData(true, crossLanguage);
        let pieChartData: any[] = [];
        return data.length !== 0 ? (
            data.map(([datasetName, groupData], datasetIndex) => {
                const chartDatas = groupData
                    .filter(([metricsName, _]) => {
                        return (
                            metricsName === "Total" || metricsName === "Overall"
                        );
                    })
                    .map(([chartName, chartItems], index) => {
                        const pieArray = chartItems.map(
                            (chartItem, chartIndex) => {
                                let pieContent: any = {};

                                let toolTipData: any[] = [];
                                if (chartItem.latency !== undefined) {
                                    const latencyPropsArr = Object.entries(
                                        chartItem.latency
                                    );

                                    pieChartData = latencyPropsArr.filter(
                                        ([key, val]) => {
                                            return this.workSpaceKey(key);
                                        }
                                    );
                                    toolTipData = latencyPropsArr.filter(
                                        ([key, val]) => {
                                            const keyLower = key.toLowerCase();
                                            return [
                                                "detectormetrics",
                                                "normalizermetrics",
                                                "analyzermetrics",
                                                "recognizermetrics",
                                                "paragraphmetrics",
                                                "Detector",
                                                "Normalizer",
                                                "Analyzer",
                                                "Recognizer",
                                                "Barcode",
                                                "ocrnativeperfnative",
                                                "ocrNativePerf",
                                                "table",
                                                "PODNative",
                                                "PodBackbone",
                                                "PodRegionGrouping",
                                                "pod",
                                                "RecognizerMs",
                                                "Paragraphmetrics",
                                                "_checkboxnativeperfcheckboxnativemetrics",
                                                "mathformulametrics",
                                                "mathfm",
                                                "figure",
                                                "mathformula",
                                                "hierarchyanalyzernativeperf.totalms",
                                                "kvpnative",
                                            ].some(
                                                (s) =>
                                                    keyLower.indexOf(s) !== -1
                                            );
                                        }
                                    );
                                }

                                let baseValue = 0;
                                const totalMSData = pieChartData.find(
                                    ([key, val]) => {
                                        let shortenKey = key;
                                        if (
                                            this.workSpace === Workspaces.OcrPod
                                        ) {
                                            return [
                                                "_podNativePerf.PODNativeMetrics.TotalMs",
                                                "_hierarchyAnalyzerNativePerf.HierarchyAnalyzerNativeMetrics.NativeTotalMs",
                                            ].includes(shortenKey);
                                        } else if (
                                            this.workSpace ===
                                            Workspaces.OcrTable
                                        ) {
                                            return (
                                                shortenKey ===
                                                "_tableNativePerf.TableNativeMetrics.DetectorMs.TotalMs"
                                            );
                                        } else if (
                                            this.workSpace ===
                                            Workspaces.OcrFigure
                                        ) {
                                            return (
                                                shortenKey ===
                                                "_figureNativePerf.FigureNativeMetrics.DetectorMs.TotalMs"
                                            );
                                        } else if (
                                            this.workSpace ===
                                            Workspaces.OcrMath
                                        ) {
                                            return (
                                                shortenKey ===
                                                (shortenKey.indexOf(
                                                    "MathFm"
                                                ) === -1
                                                    ? "_ocrNativePerf.NativeMetrics.MathformulaMetrics.TotalMs"
                                                    : "_ocrNativePerf.NativeMetrics.MathFm.TotalMs")
                                            );
                                        } else if (
                                            this.workSpace ===
                                            Workspaces.OcrCheckbox
                                        ) {
                                            return (
                                                shortenKey ===
                                                "_checkboxNativePerfCheckboxNativeMetrics.TotalMS"
                                            );
                                        } else if (
                                            this.workSpace === Workspaces.Kvp
                                        ) {
                                            return (
                                                shortenKey ===
                                                "_kvpNativePerf.KVPNativeMetrics.TotalMs"
                                            );
                                        } else {
                                            return (
                                                shortenKey ===
                                                "_ocrNativePerfNativeMetrics.TotalMs"
                                            );
                                        }
                                    }
                                );
                                pieChartData = pieChartData.filter(
                                    (obj) => obj !== totalMSData
                                );
                                baseValue = totalMSData ? totalMSData[1] : 0;

                                const pie = pieChartData.map(
                                    ([key, val], index) => {
                                        let toolTip: any[] = [];
                                        let data: any = {};
                                        let tips: any = {};
                                        let str: string = "";
                                        data = {
                                            x: key,
                                            y: baseValue
                                                ? (
                                                      (val / baseValue) *
                                                      100
                                                  ).toFixed(2)
                                                : 0,
                                        };
                                        toolTipData.forEach(
                                            ([tipKey, tipVal]) => {
                                                let getTip = key.substring(
                                                    key.indexOf(".") + 1,
                                                    key.lastIndexOf(".")
                                                );
                                                if (
                                                    tipKey.indexOf(getTip) !==
                                                    -1
                                                ) {
                                                    tips = {
                                                        [tipKey]: tipVal,
                                                    };
                                                    toolTip.push(tips);
                                                }
                                            }
                                        );
                                        let newTip: any = {};
                                        toolTip.forEach((item) => {
                                            newTip = { ...newTip, ...item };
                                        });
                                        for (let tip in newTip) {
                                            str +=
                                                tip +
                                                ":" +
                                                newTip[tip] +
                                                "<br />";
                                        }

                                        return {
                                            data: data,
                                            color: getColorByIndex(index),
                                            legend: key,
                                            tooltip: str,
                                        };
                                    }
                                );

                                pieContent = {
                                    chartId: chartIndex,
                                    content: pie,
                                    legend:
                                        chartItem.data?.recordDetail.name ?? "",
                                };
                                return pieContent;
                            }
                        );

                        return {
                            chartToDisplay: {
                                chartTitle:
                                    index === 0
                                        ? datasetName + "_" + chartName
                                        : chartName,
                                pieChartData: pieArray,
                            },
                        };
                    });

                return pieChartData.length !== 0 ? (
                    <DocumentCard
                        key={`chart_${datasetIndex}}`}
                        className="overview__card"
                        type={DocumentCardType.normal}
                    >
                        {chartDatas.map((chartData, chartIndex) => {
                            return (
                                <VerticalPieChart
                                    id={
                                        "vPieChart" +
                                        datasetIndex.toString() +
                                        chartIndex.toString()
                                    }
                                    key={`pieChart_${chartIndex}`}
                                    height={500}
                                    data={chartData.chartToDisplay}
                                    keyIndex={0}
                                    isDarkTheme={this.props.isDarkTheme}
                                />
                            );
                        })}
                    </DocumentCard>
                ) : (
                    <Label>
                        Woops! Due to data incomplete, no Pie Chart generated
                    </Label>
                );
            })
        ) : (
            <Label>Woops! Due to data incomplete, no Chart generated</Label>
        );
    }

    workSpaceKey = (key: string) => {
        if (this.workSpace === Workspaces.OcrPod) {
            return (
                [
                    "_podNativePerf.PODNativeMetrics.TotalMs",
                    "_podNativePerf.PODNativeMetrics.UnaccountedMs",
                    "_podNativePerf.PODNativeMetrics.ImageRotationMs",
                    "_podNativePerf.PODNativeMetrics.NativeImgDecodeMs",
                    "_podNativePerf.PODNativeMetrics.PostProcessBboxMs",
                    "_podNativePerf.PODNativeMetrics.PostProcessMs",
                    "_podNativePerf.PODNativeMetrics.PreprocessBboxMs",
                ].includes(key) ||
                [
                    "_hierarchyAnalyzerNativePerf.HierarchyAnalyzerNativeMetrics.NativeTotalMs",
                    "_hierarchyAnalyzerNativePerf.TotalMS",
                    "_hierarchyAnalyzerNativePerf.PreprocessMS",
                    "_hierarchyAnalyzerNativePerf.OverheadMS",
                    "_hierarchyAnalyzerNativePerf.PoolWaitingMS",
                ].includes(key)
            );
        } else if (this.workSpace === Workspaces.OcrTable) {
            return [
                "_tableNativePerf.TableNativeMetrics.RecognizerMs.AccumulatedModelMs",
                "_tableNativePerf.TableNativeMetrics.RecognizerMs.AccumulatedPreProcessMs",
                "_tableNativePerf.TableNativeMetrics.RecognizerMs.TotalMs",
                "_tableNativePerf.TableNativeMetrics.DetectorMs.UnaccountedMs",
                "_tableNativePerf.TableNativeMetrics.DetectorMs.TotalMs",
                "_tableNativePerf.TableNativeMetrics.DetectorMs.ImageRotationMs",
                "_tableNativePerf.TableNativeMetrics.DetectorMs.ImageResizeMs",
                "_tableNativePerf.TableNativeMetrics.DetectorMs.PreprocessMs",
                "_tableNativePerf.TableNativeMetrics.DetectorMs.ModelMs",
                "_tableNativePerf.TableNativeMetrics.DetectorMs.PostProcessMs",
            ].includes(key);
        } else if (this.workSpace === Workspaces.OcrFigure) {
            return [
                "_figureNativePerf.FigureNativeMetrics.DetectorMs.TotalMs",
                "_figureNativePerf.FigureNativeMetrics.DetectorMs.ImageRotationMs",
                "_figureNativePerf.FigureNativeMetrics.DetectorMs.ImageResizeMs",
                "_figureNativePerf.FigureNativeMetrics.DetectorMs.PreprocessMs",
                "_figureNativePerf.FigureNativeMetrics.DetectorMs.ModelMs",
                "_figureNativePerf.FigureNativeMetrics.DetectorMs.UnaccountedMs",
            ].includes(key);
        } else if (this.workSpace === Workspaces.OcrMath) {
            return (
                [
                    "_ocrNativePerf.NativeMetrics.MathFm.TotalMs",
                    "_ocrNativePerf.NativeMetrics.MathFm.NormalizerMetrics.TotalMs",
                    "_ocrNativePerf.NativeMetrics.MathFm.DetectorMetrics.TotalMs",
                    "_ocrNativePerf.NativeMetrics.MathFm.ParagraphMetrics.TotalMs",
                    "_ocrNativePerf.NativeMetrics.MathFm.RecognizerMetrics.WallTimeMs",
                    "_ocrNativePerf.NativeMetrics.MathFm.UnaccountedMs",
                    "_ocrNativePerf.NativeMetrics.MathFm.DedupMathTextMs",
                ].includes(key) ||
                [
                    "_ocrNativePerf.NativeMetrics.MathformulaMetrics.TotalMs",
                    "_ocrNativePerf.NativeMetrics.MathformulaMetrics.NormalizerMetrics.TotalMs",
                    "_ocrNativePerf.NativeMetrics.MathformulaMetrics.DetectorMetrics.TotalMs",
                    "_ocrNativePerf.NativeMetrics.MathformulaMetrics.ParagraphMetrics.TotalMs",
                    "M_ocrNativePerf.NativeMetrics.MathformulaMetrics.RecognizerMetrics.WallTimeMs",
                    "_ocrNativePerf.NativeMetrics.MathformulaMetrics.UnaccountedMs",
                    "_ocrNativePerf.NativeMetrics.MathformulaMetrics.DedupMathTextMs",
                ].includes(key)
            );
        } else if (this.workSpace === Workspaces.OcrCheckbox) {
            const keyLower = key.toLowerCase();
            return (
                keyLower.indexOf("checkboxnativemetrics") !== -1 ||
                keyLower.indexOf("checkboxnative") !== -1
            );
        } else if (this.workSpace === Workspaces.Kvp) {
            const keyLower = key.toLowerCase();
            return (
                keyLower.startsWith("kvpnativeperf") ||
                keyLower.startsWith("kvpnative")
            );
        } else {
            return [
                "_ocrNativePerfNativeMetrics.DetectedLineCount",
                "_ocrNativePerfNativeMetrics.LineCount",
                "_ocrNativePerfNativeMetrics.TotalMs",
                "_ocrNativePerfNativeMetrics.NativeImgDecodeMs",
                "_ocrNativePerfNativeMetrics.UnaccountedMs",
                "_ocrNativePerfTotalMS",
                "_ocrNativePerfPreprocessMS",
                "_ocrNativePerfOverheadMS",
                "_ocrNativePerfPoolWaitingMS",
            ].includes(key);
        }
    };

    public _renderChartHeader(): JSX.Element {
        const {
            selectedType,
            selectedXAxis,
            selectedYAxis,
            selectLanguage,
            languages,
            xAxisList = [],
        } = this.state;

        let chartConfigurations: ITableConfigurations = [];
        const options = Array.from(new Set(xAxisList));

        if (selectedType === ChartType.LineChart) {
            chartConfigurations = [
                {
                    key: "chartType",
                    text: "Chart Type:",
                    options: CHARTOPTIONS,
                    selectedKey: selectedType ?? CHARTOPTIONS[0],
                    onChange: (selectedType: any) => {
                        this.chooseChartType!(selectedType.key);
                    },
                },
                {
                    key: "xAxis",
                    text: "XAxis:",
                    options: options.sort(),
                    shortName: true,
                    selectedKey: selectedXAxis ?? _.last(options),
                    onChange: (selectedXAxis: any) => {
                        this.chooseXAxis!(selectedXAxis.key);
                    },
                },
            ];
        }
        if (selectedType === ChartType.PieChart) {
            chartConfigurations = [
                {
                    key: "chartType",
                    text: "Chart Type:",
                    options: CHARTOPTIONS,
                    selectedKey: selectedType ?? CHARTOPTIONS[0],
                    onChange: (selectedType: any) => {
                        this.chooseChartType!(selectedType.key);
                    },
                },
            ];
        }
        if (selectedType === ChartType.ScatterChart) {
            chartConfigurations = [
                {
                    key: "chartType",
                    text: "Chart Type:",
                    options: CHARTOPTIONS,
                    selectedKey: selectedType ?? CHARTOPTIONS[0],
                    onChange: (selectedType: any) => {
                        this.chooseChartType!(selectedType.key);
                    },
                },
                {
                    key: "datasets",
                    text: "Datasets:",
                    options: languages,
                    selectedKey: selectLanguage ?? languages[0],
                    onChange: (selectedXAxis: any) => {
                        this._onOptionsChanged!(selectedXAxis.key);
                    },
                },
                {
                    key: "xAxis",
                    text: "XAxis:",
                    options: options,
                    shortName: true,
                    selectedKey: selectedXAxis ?? options[0],
                    onChange: (selectedXAxis: any) => {
                        this.chooseXAxis!(selectedXAxis.key);
                    },
                },
                {
                    key: "yAxis",
                    text: "YAxis:",
                    options: options,
                    shortName: true,
                    selectedKey: selectedYAxis ?? options[1],
                    onChange: (selectedYAxis: any) => {
                        this.chooseYAxis!(selectedYAxis.key);
                    },
                },
            ];
        }

        return <TableHeader options={chartConfigurations} />;
    }
    private chooseChartType(selectedType: any) {
        // const { dataItems } = this.props;
        // let xAxisList: any[] = [];

        // if ([ChartType.LineChart, ChartType.PieChart].includes(selectedType)) {
        //     const sampleItemFromPorps =
        //         this._findSampleLatencyDataItem(dataItems);
        //     if (
        //         selectedType === ChartType.LineChart &&
        //         sampleItemFromPorps &&
        //         sampleItemFromPorps.metrics?.percentile_latency?.length > 0
        //     ) {
        //         sampleItemFromPorps.metrics.percentile_latency.forEach(
        //             (metric) => {
        //                 xAxisList.push(ShortenMetricKeyName(metric.chartname));
        //             }
        //         );
        //     } else if (
        //         selectedType === ChartType.PieChart &&
        //         sampleItemFromPorps &&
        //         sampleItemFromPorps.metrics?.category_latency
        //     ) {
        //         if (
        //             sampleItemFromPorps.metrics.category_latency instanceof
        //             Array
        //         ) {
        //             xAxisList =
        //                 sampleItemFromPorps.metrics.category_latency.map(
        //                     (element) => element["category"]
        //                 );
        //         } else {
        //             xAxisList = (
        //                 sampleItemFromPorps.metrics.category_latency as any
        //             )["category"];
        //         }
        //     }
        // } else if (selectedType === ChartType.ScatterChart) {
        //     const sampleItemFromState = this._findSampleLatencyDataItem(
        //         this.state.dataItems
        //     );
        //     if (sampleItemFromState) {
        //         xAxisList = Object.keys(
        //             Object.values(sampleItemFromState.metrics).find(
        //                 (val) => val
        //             )
        //         );
        //     }
        // }
        // if (
        //     selectedType === ChartType.LineChart ||
        //     selectedType === ChartType.PieChart
        // ) {
        //     this.setState({
        //         selectedXAxis: xAxisList[0],
        //     });
        // } else {
        //     this.setState({
        //         selectedXAxis: xAxisList[0],
        //         selectedYAxis: xAxisList[1],
        //     });
        // }
        this.setState({
            selectedType: selectedType,
            selectedXAxis: undefined,
            selectedYAxis: undefined,
        });
    }
    private chooseXAxis(value: string) {
        this.setState({
            selectedXAxis: value,
        });
    }
    private chooseYAxis(value: string) {
        this.setState({
            selectedYAxis: value,
        });
    }
    protected getLanguageList(matchDatasetVersion: boolean): string[] {
        const { records } = this.props;

        if (records.length === 0) {
            return [];
        }
        let languageList = records[0]
            .getDetails()
            .map((detail) =>
                matchDatasetVersion
                    ? detail.dataset.displayFullName
                    : detail.dataset.displayName
            );
        records.forEach((recordItem) => {
            const compareLanguageList = recordItem
                .getDetails()
                .map((detail) =>
                    matchDatasetVersion
                        ? detail.dataset.displayFullName
                        : detail.dataset.displayName
                );

            languageList = languageList?.filter(
                (v) => compareLanguageList!.indexOf(v) > -1
            );
        });
        return Array.from(new Set(languageList));
    }
    private _onOptionsChanged(selectLanguage: string) {
        this._queryMetricsResult(
            "overall_latency_image_metrics.json",
            selectLanguage
        );
        this.setState({
            selectLanguage: selectLanguage,
        });
    }

    private _findSampleLatencyDataItem(
        dataItems: ILatencyDataItem[]
    ): ILatencyDataItem | undefined {
        return dataItems.find(
            (item) =>
                item && item.metrics && Object.entries(item.metrics).length > 0
        );
    }

    private _findSamplePercentileLatencyDataItem(
        dataItems: ILatencyDataItem[]
    ): ILatencyDataItem | undefined {
        return dataItems.find(
            (item) =>
                item &&
                item.metrics &&
                Object.entries(item.metrics).length > 0 &&
                item.metrics?.percentile_latency?.length > 0
        );
    }
}
