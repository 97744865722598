import { msalInstance } from "../..";
import { hideLoading, showLoading } from "./LoadingUtil";

const getToken = async () => {
    const account = msalInstance.getActiveAccount();
    if (!account) {
        throw Error(
            "No active account! Verify a user has been signed in and setActiveAccount has been called."
        );
    }
    /**
     * Create a TokenRequest object to retrieve an access token silently from the cache via acquireTokenSilent
     */
    const tokenRequest = {
        account: account,
        scopes: ["api://7cc85b49-e3d0-4033-804f-6bc27714828c/access_as_user"],
        redirectUri: "/redirect",
    };
    const response = await msalInstance.acquireTokenSilent(tokenRequest);
    return response.accessToken;
};

let requestCount = 0;

export const vdiRequest = (
    url: string,
    init: any = {},
    loading = true
): Promise<any> => {
    if (loading) {
        showLoading();
    }

    return getToken()
        .then((accessToken) => {
            const authHeaders = {
                Authorization: `Bearer ${accessToken}`,
            };
            if ("headers" in init) {
                init["headers"] = { ...init["headers"], ...authHeaders };
            } else {
                init["headers"] = { ...authHeaders };
            }

            return fetch(url, init).finally(() => {
                requestCount--;

                if (requestCount <= 0 && loading) {
                    hideLoading();
                }
            });
        })
        .catch((error: any) => {
            console.log(error);
        });
};
